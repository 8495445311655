import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Row, Col } from "reactstrap";
import PrintHeader from "components/Common/printHeader";
import PrintFooter from "components/Common/printFooter";



const AdvanceShiftRoomReceiptPrint = forwardRef(
  ({ GuestDetails, advanceNo, totalAdvanceAmount, currentAdvanceAmount,hoteldetails, dateTime }, ref) => {
    const { guestName, advance, paymentType, roomNo, tariff, checkInDate } = GuestDetails || {};
  
    return (
      <div ref={ref} className="receipt-container">
        
        <div className="receipt-content">
            <h3 className="receipt-title" style={{ textAlign: "center" }}>ROOM ADVANCE RECEIPT</h3>
        </div>
        <PrintHeader />
       

        {/* Body Content */}
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <Col style={{ flexGrow: 1 }}>
            <p className="receipt-field" style={{ whiteSpace: "nowrap", margin: 0 }}>
              <strong>No:</strong> {advanceNo.GRCNo || "35"}
            </p>
          </Col>
          <Col style={{ flexGrow: 2, textAlign: "center" }}>
            <p className="receipt-field" style={{ whiteSpace: "nowrap", margin: 0 }}>
              <strong>Date & Time:</strong> {dateTime}
            </p>
          </Col>
          <Col style={{ flexGrow: 1, textAlign: "right" }}>
            <p className="receipt-field" style={{ whiteSpace: "nowrap", margin: 0 }}>
              <strong>GRC No:</strong> {advanceNo.GRCNo || "15"}
            </p>
          </Col>
        </Row>

        <p>
          Received with thanks from Mr. {guestName || "Guest"} the sum of Rupees {currentAdvanceAmount || "N/A"} as advance by{" "}
          {paymentType || "CASH"} for shifted Standard Room No.<strong>{roomNo || "N/A"}</strong> . Room Rent: {tariff || "N/A"}, and 
          total amount paid:{" "}<strong>{totalAdvanceAmount || "N/A"}.</strong>
        </p>
        <p>
          <strong>Date and Time of Arrival:</strong> {checkInDate || "N/A"}
        </p>

        {/* Amount Paid Section */}
        <div style={{ textAlign: "right" }}>
          <p
            style={{
              border: "2px solid black",
              padding: "10px",
              display: "inline-block",
              marginTop: "10px",
              fontWeight: "bold",
            }}
          >
            <strong>{hoteldetails.currency}:</strong> {currentAdvanceAmount || "N/A"}
          </p>
        </div>

        {/* Rules and Regulations */}
        <h5>RULES & REGULATIONS</h5>
        <ul>
          <li>The room rent and amenities are for 24 hours and part of a day will be reckoned as a full day rent will be charged accordingly.</li>
          <li>Tariff for extra persons if found in the guest's room will be levied from the guest's.</li>
          <li>Bills are to be settled at the time of departure of the occupant. Cheques are not accepted.</li>
          <li>The management is not responsible for any loss of goods or valuables belonging to the occupants in the room.</li>
          <li>Lodgers will be responsible for the furniture & fittings and they will have to reimburse the market value of the articles that might be damaged during their stay.</li>
          <li>The management's valuation shall be final.</li>
          <li>The management reserves the right of admission.</li>
          <li>This cash receipt is to be surrundered at the time of departure and the balance if any is to be settled immediately.</li>
          <li><strong>The Room key should be left at Reception</strong></li>
        </ul>

        {/* Footer with Signature and Company Info */}
        <PrintFooter/>
       </div>
    );
  }
);

AdvanceShiftRoomReceiptPrint.propTypes = {
  GuestDetails: PropTypes.object.isRequired,
  advanceNo: PropTypes.object.isRequired,
  dateTime: PropTypes.string.isRequired,
  totalAdvanceAmount: PropTypes.number.isRequired,
  currentAdvanceAmount: PropTypes.number.isRequired,
};

export default AdvanceShiftRoomReceiptPrint;









