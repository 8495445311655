import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import * as Yup from "yup";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Form,
  Label,
  Input,
  Button,
  FormGroup, FormFeedback
} from "reactstrap";
import Select from "react-select";
import { getCountries, getTimeZones, updateSettings, getHotelById } from "../../middleware/backent_helper";
import messages from '../../utils/constants/messages';
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import AlertModal from "components/Common/alertModal";
import ErrorAlertModal from "components/Common/errorAlertModal";
import HotelForm from "../../components/hotel/hotel_form";
import { useFormik } from 'formik';
import $, { data } from 'jquery';
import 'summernote/dist/summernote-bs4.css';
import 'summernote';



const GenralSettings = props => {

  document.title = "General | Setting";

  const navigate = useNavigate();
  const [alertModal, setAlertModal] = useState(false);
  const [errorAlertModal, setErrorAlertModal] = useState(false);
  const [response, setResponse] = useState(false);
  const [hid, setHid] = useState(() => {
    const storedHotelId = localStorage.getItem('hotelId')
    return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
  });
  const [dbName, setDbName] = useState(
    localStorage.getItem('dbName')
  );
  const [masterName, setMasterName] = useState("Setting");
  const [hotel, setHotel] = useState([]);
  const [currencyList, setCurrency] = useState([]);
  const [timeZoneList, setTimeZones] = useState([]);
  const [selectedTimeZone, setselectedTimeZone] = useState(null);
  const [selectedCurrency, setselectedCurrency] = useState(null);

  const [selectedImage, setSelectedImage] = useState('');
  const [images, setImages] = useState([]);

 
  useEffect(() => {
    const fetchImages = () => {
      const imageList = [
        { label: 'Room Advance Print (A4 & A5)', image: 'RoomAdvancePrint.png' },
        { label: 'ShiftRoom Advance Print (A4 & A5)', image: 'ShiftRoomAdvancePrint.png' }
       

      ];

      const formattedImages = imageList.map(({ label, image }) => ({
        label: label,
        // Dynamically require the image
        value: require(`assets/images/printformats/${image}`),
      }));

      setImages(formattedImages);
    };

    fetchImages();
  }, []);


  useEffect(() => {
    getHotel()
  }, []);

  const getHotel = () => {
    const hotelId = localStorage.getItem('hotelId');
    if (hotelId) {
      getHotelById(hotelId)
        .then(response => {
          
          const hotelData = response.data.data;
          setHotel(hotelData);
          formik.setFieldValue('declaration', hotelData.declaration);
          $('.declaration_summernote').summernote('code', hotelData.declaration || '');
        })
    }
  };

  useEffect(() => {
    getCountries().then((res) => {
      const currencyData = res.data.data.map((currency) => ({
        label: `${currency.currency} (${currency.currency_symbol})`,
        value: currency.currency,
      }));
      setCurrency(currencyData);

      // Set default currency if hotel data is available
      if (hotel && hotel.currency) {
        const currencyCode = hotel.currency.split(/[\s\(\)-]+/)[0];
        const defaultCurrency = currencyData.find((cur) => cur.value === currencyCode);
        if (defaultCurrency) {
          formik.setFieldValue('currency', defaultCurrency);
        }
      }
    });

    getTimeZones().then((res) => {
      const timeZoneData = res.data.data.map((timezone) => ({
        label: `${timezone.tzName} (${timezone.gmtOffsetName}) ${timezone.zoneName}`,
        value: timezone.zoneName,
      }));
      setTimeZones(timeZoneData);

      if (hotel && hotel.timeZone) {
        const timezone = hotel.timeZone.split(' ').pop();
        const defaultTimeZone = timeZoneData.find((cur) => cur.value === timezone);
        if (defaultTimeZone) {
          formik.setFieldValue('timezone', defaultTimeZone);
        }
      }
    })

  }, [hotel]);

  useEffect(() => {
    $('.declaration_summernote').summernote({
      height: 200,
      tabsize: 2,
      toolbar: [
        ['style', ['bold', 'italic', 'underline']],
        ['para', ['ul', 'ol']],
      ],
      tooltip: false,
      callbacks: {
        onChange: function (contents) {
          formik.setFieldValue('declaration', contents);
        }
      }
    });

    // Cleanup Summernote on component unmount
    return () => {
      $('.declaration_summernote').summernote('destroy');
    };
  }, []);

  useEffect(() => {
    if (!hotel.billFormat) {
      const defaultImage = images.find(img => img.label === 'Room Advance Print (A4 & A5)');
      if (defaultImage) {
        setSelectedImage(defaultImage.value);
        formik.setFieldValue('billFormat', defaultImage.value);
      }
    } else {
      const selectedImage = images.find(img => img.value.includes(hotel.billFormat));
      if (selectedImage) {
        setSelectedImage(selectedImage.value);
        formik.setFieldValue('billFormat', hotel.billFormat);
      }
    }

  },[hotel, images]);

 // Initialize Formik
  const formik = useFormik({
    initialValues: {
      timezone: "",
      currency: "",
      graceTime: hotel.graceTime || "0",
      billFormat: "",
      declaration: hotel.declaration || ""

    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      graceTime: Yup.string().required("Please Enter Grace Time hours"),
    }),
    onSubmit: (values) => {

      updateSettings(dbName, hid, values)
        .then((res) => {
          setResponse(messages.success.updatedDetails(masterName))
          setAlertModal(true)
        })
        .catch((err) => {
          if (err.response.status === 401) {
            setResponse(messages.failed.alreadyInUse(masterName))
          } else {
            setResponse(messages.failed.updateFailed(masterName))
          }
          setErrorAlertModal(true)
        });
    },
  });



  return (
    <React.Fragment>
      <AlertModal
        show={alertModal}
        onOkClick={() => { getHotel(), setAlertModal(false) }}
        onCloseClick={() => { getHotel(), setAlertModal(false) }}
        res={response}
      />
      <ErrorAlertModal
        show={errorAlertModal}
        onOkClick={() => { getHotel(), setErrorAlertModal(false) }}
        onCloseClick={() => { getHotel(), setErrorAlertModal(false) }}
        res={response}
      />

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Property" breadcrumbItem="General Settings" />
          {/* <Breadcrumbs title="General Settings" /> */}
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>

                  <Form onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                    return false;
                  }}>
                    <Row>
                      <Col sm="6">
                        <FormGroup>
                          <label htmlFor="declaration">Declaration</label>
                          <textarea
                            id="declaration"
                            name="declaration"
                            className="form-control declaration_summernote"
                            value={formik.values.declaration}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            rows="4"
                            placeholder="Enter your declaration text here"
                          ></textarea>


                          {formik.touched.declaration && formik.errors.declaration ? (
                            <div className="error">{formik.errors.declaration}</div>
                          ) : null}

                        </FormGroup>
                      </Col>

                      <Col>
                        <div className="form-row">
                          <div className="form-group col-lg-5">
                            <Label className="form-label">Select Advance Receipt Format</Label>
                          </div>
                          <div className="form-group col-lg-8">
                            <select
                              name="billFormat"
                              id="billFormat"
                              className="form-control"
                              value={selectedImage || formik.values.billFormat }
                             
                              onChange={(e) => {
                                const selectedValue = e.target.value;
                                const selectedImageData = images.find((img) => img.value === selectedValue);
                                
                                if (selectedImageData) {
                                  formik.setFieldValue('billFormat', selectedImageData.value);
                                  setSelectedImage(selectedImageData.value);  
                                }
                              }}
                            >
                              {/* <option value="">Select Bill Format</option> */}
                              {images.map((image, index) => (
                                <option key={index} value={image.value}>
                                  {image.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        {/* Display the selected image */}
                        {selectedImage && (
                          <div className="mt-3">
                            <img
                              src={selectedImage}
                              alt="Selected Bill Format"
                              style={{ width: '100%', maxWidth: '420px', height: 'auto' }}
                            />
                            {/* <p>{selectedImage.split('/').pop()}</p> Display image name */}
                          </div>
                        )}
                      </Col>
                    </Row>


                    <Row>
                      <Col sm="12">
                        <div className="mb-3">
                          <FormGroup>
                            <Label className="form-label">Time Zone</Label>
                            <Select
                              name="timezone"
                              placeholder="Select Time Zone"
                              classNamePrefix="custom-select"
                              options={timeZoneList}
                              value={formik.values.timezone}
                              onChange={(selectedOption) => formik.setFieldValue('timezone', selectedOption)}
                              isClearable
                              isSearchable
                              styles={{
                                menu: (provided) => ({
                                  ...provided,
                                  top: '100%',
                                  position: 'absolute',
                                  width: '100%',
                                  zIndex: 999,
                                }),
                              }}
                            />
                          </FormGroup>
                        </div>
                      </Col>

                      <Col sm="6">
                        <div className="mb-3">
                          <Label className="control-label">Currency</Label>
                          <Select
                            name="timezone"
                            placeholder="Select Time Zone"
                            classNamePrefix="custom-select"
                            options={currencyList}
                            value={formik.values.currency}
                            onChange={(selectedOption) => formik.setFieldValue('currency', selectedOption)}
                            isClearable
                            isSearchable
                            styles={{
                              menu: (provided) => ({
                                ...provided,
                                top: '100%',
                                position: 'absolute',
                                width: '100%',
                                zIndex: 999,
                              }),
                            }}
                          />
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label className="control-label">Grace Time</Label>
                          <Input
                            name="graceTime"
                            type="number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.graceTime || ""}
                            invalid={
                              formik.touched.graceTime &&
                                formik.errors.graceTime
                                ? true
                                : false
                            }
                          />
                          {formik.touched.graceTime &&
                            formik.errors.graceTime ? (
                            <FormFeedback type="invalid">
                              {formik.errors.graceTime}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary" className="btn ">
                        Save Changes
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
export default withRouter(GenralSettings);
