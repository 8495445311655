import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import withRouter from "components/Common/withRouter";
// import TableContainer from "../../../components/Common/TableContainer";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Button,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { hotelRegister, getAllHotels, updateHotel, deleteHotel, getHotelUser } from "../../../middleware/backent_helper";
import { HotelName, HotelEmail, MobileNo, WebAddress, Logo } from "./HotelListCol.js";
import messages from '../../../utils/constants/messages';
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import AlertModal from "components/Common/alertModal";
import ErrorAlertModal from "components/Common/errorAlertModal";
import HotelForm from "../../../components/hotel/hotel_form"
//redux
import { useDispatch } from "react-redux";
import { map } from "lodash";
import sampleImage from "../../../assets/images/logo.png";

const HotelList = props => {
  //meta title
  document.title = "Hotel | Masters";

  const location = useLocation();
  const { hotel, HotelUser } = location.state || {};
  const [isDisabled, setIsDisabled] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [Hotels, setHotels] = useState();
  const [hotelList, setHotelList] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isCreate, setCreate] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [errorAlertModal, setErrorAlertModal] = useState(false);
  const [response, setResponse] = useState(false);
  const [masterName, setMasterName] = useState("Hotel");
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [currencyPlan, setCurrencyPlan] = useState(null);
  const [hid, setHid] = useState(() => {
    const storedHotelId = localStorage.getItem('hotelId')
    return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
  });
  const [dbName, setDbName] = useState(
    localStorage.getItem('dbName')
  )
  const [role, setRole] = useState('2'); //Role has to be passed like this: 2 is Hotel
  const [page, setPage] = useState(1);
  const itemsPerPage = 6;
  const totalPage = Math.ceil(hotelList.length / itemsPerPage);

  useEffect(() => {
    if (hotel) {
      setHotels(hotel)
    }
  }, [hotel, HotelUser]);


  window.onpopstate = () => {
    navigate("/hotel");
    setIsDisabled(false);
    setIsEdit(false);
  }

  // const columns = useMemo(
  //   () => [
  //     {
  //       Header: "S.No",
  //       Cell: ({ row, rows }) => {
  //         return (
  //           <div className="table-cell text-center">
  //             {rows.length - row.index}
  //           </div>
  //         );
  //       },
  //     },

  //     {
  //       Header: "Hotel Name",
  //       accessor: "hotelName",
  //       Cell: cellProps => {
  //         return (
  //           <div className="table-cell text-center">
  //             <HotelName {...cellProps} />
  //           </div>
  //         );

  //       },
  //     },

  //     {
  //       Header: "Hotel Logo",
  //       accessor: "logo",
  //       Cell: cellProps => {
  //         return <Logo {...cellProps} />;
  //       },
  //     },

  //     {
  //       Header: "Hotel Email",
  //       accessor: "hotelEmail",
  //       filterable: true,
  //       Cell: cellProps => {
  //         return (
  //           <div className="table-cell text-center">
  //             <HotelEmail {...cellProps} />
  //           </div>
  //         );

  //       },
  //     },

  //     {
  //       Header: "Contact No.",
  //       accessor: "contactNo1",

  //       filterable: true,
  //       Cell: cellProps => {
  //         return (
  //           <div className="table-cell text-center">
  //             {" "}
  //             <MobileNo {...cellProps} />{" "}
  //           </div>
  //         );

  //       },
  //     },

  //     {
  //       Header: "Web Address",
  //       accessor: "website",

  //       filterable: true,
  //       Cell: cellProps => {
  //         return (
  //           <div className="table-cell text-center">
  //             {" "}
  //             <WebAddress {...cellProps} />{" "}
  //           </div>
  //         );
  //       },
  //     },

  //     {
  //       Header: "Action",
  //       Cell: cellProps => {
  //         return (
  //           <div className="table-body actions">
  //             <div className="actions">
  //               <Link
  //                 to="#"
  //                 className="text-success"
  //                 onClick={(event) => {
  //                   const userData = cellProps.row.original;
  //                   handleUserClick(userData, event);
  //                 }}
  //               >
  //                 <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
  //                 <UncontrolledTooltip placement="top" target="edittooltip">
  //                   Edit
  //                 </UncontrolledTooltip>
  //               </Link>
  //               <Link
  //                 to="#"
  //                 className="text-danger"
  //                 onClick={() => {
  //                   const userData = cellProps.row.original;
  //                   onClickDelete(userData);
  //                 }}
  //               >
  //                 <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
  //                 <UncontrolledTooltip placement="top" target="deletetooltip">
  //                   Delete
  //                 </UncontrolledTooltip>
  //               </Link>
  //             </div>
  //           </div>
  //         );
  //       },
  //     },
  //   ],
  //   []
  // );

  useEffect(() => {
    finalList()
  }, [])

  const finalList = () => {
    getAllHotels()
    .then((res) => {
      setHotelList(res.data.data);
    });
  };

  const toggle = () => {
    if (modal) {
      setModal(false);
      setHotels(null);
    } else {
      setModal(true);
    }
  };

  const handleUserClick = async (arg, event) => {
    setCurrentStep(0);
    if (event) {
      event.preventDefault();
    }
    if (arg) {
      try {
        const res = await getHotelUser(arg.id);
        const HotelUser = res.data.data[0];
        const hotel = arg;
        setHotels({
          id: hotel.id,
          hotelName: hotel.hotelName,
          hotelEmail: hotel.hotelEmail,
          address1: hotel.address1,
          address2: hotel.address2,
          address3: hotel.address3,
          postalCode: hotel.postalCode,
          contactNo1: hotel.contactNo1,
          contactNo2: hotel.contactNo2,
          website: hotel.website,
          country: hotel.country,
          state: hotel.state,
          city: hotel.city,
          taxId: hotel.taxId,
          planId: hotel.planId,
          name: HotelUser.name,
          email: HotelUser.email,
          password: HotelUser.password,
          mobileNo1: HotelUser.mobileNo1,
          mobileNo2: HotelUser.mobileNo2,

        });
        setCurrencyPlan(hotel.currency)
        setIsEdit(true);
        toggle();
      } catch (error) {
        console.error("Error fetching hotel user data:", error);
      }
    }
  };

  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setHotels(users);
    setDeleteModal(true);
  };

  const handleDeleteHotel = () => {
    deleteHotel(Hotels).then(getAllHotels(), finalList())
    setDeleteModal(false);
  };

  const handleHotelClicks = () => {
    setCurrentStep(0);
    setCreate(true);
    setIsEdit(false);
    toggle();
    setIsDisabled(true);
    // navigate('/add-hotel');
  };

  const POST_REGISTER = async (values) => {
    const formData = createFormData(values);

    hotelRegister(formData, role).then((res) => {
      if (res.data.success) {
        setResponse(messages.success.createdMessage(masterName))
        setAlertModal(true)
        // navigate('/hotel');
      }
    }).catch(err => {
      if (err.response.status === 404) {
        setResponse(messages.failed.AlreadyExists(masterName))
      } else {
        setResponse(messages.failed.createFailed(masterName))
      }
      setErrorAlertModal(true)
    });
    getAllHotels()
    toggle();
  };

  const handleBackButtonClick = () => {
    history.push('/hotel');
  }

  const handleNext = () => {
    if (currentStep === 2) {
      handleSubmit();
      // setIsDisabled(false);
    } else {
      setCurrentStep((prevStep) => Math.min(prevStep + 1, 2));
    }
  };

  const handlePrevious = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  // Reset the count CurrentStep
  const onClosed = () => {
    setCurrentStep(0)
  }

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleSubmit = async (values) => {
    let updatedHotelData = { ...Hotels, ...values };
    if (isEdit) {
      // Create FormData from updated hotel data
      const formData = createFormData(updatedHotelData);
      updateHotel(formData)
        .then((res) => {
          setResponse(messages.success.updatedDetails(masterName));
          setAlertModal(true);
        })
        .catch((err) => {
          setResponse(messages.failed.updateFailed(masterName));
          setErrorAlertModal(true);
        });
      toggle();
    } else {
      dispatch(POST_REGISTER(values));
    }
    handleClose();
  };

  const createFormData = (values) => {
    const formData = new FormData();
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        const value = values[key];
        if (value instanceof File) {
          formData.append('logo', value);
        } else {
          formData.append(key, value);
        }
      }
    }
    return formData;
  };

  const redirectpage = () => {
    finalList();
    navigate(-1);
  }

  const handlePageClick = (newPage) => {
    setPage(newPage);
  };

  const currentPageItems = hotelList.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteHotel}
        onCloseClick={() => setDeleteModal(false)}
      />
      <AlertModal
        show={alertModal}
        onOkClick={() => { redirectpage(), setAlertModal(false) }}
        onCloseClick={() => { redirectpage(), setAlertModal(false) }}
        res={response}
      />
      <ErrorAlertModal
        show={errorAlertModal}
        onOkClick={() => { finalList(), setErrorAlertModal(false) }}
        onCloseClick={() => setErrorAlertModal(false)}
        res={response}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Masters" breadcrumbItem={!isDisabled ? (isEdit ? "Edit Hotel" : "Hotel List") : "Add Hotel"}/>
          <Row>
            <div className="d-flex justify-content-between align-items-right mb-3 flex-wrap">
              <div className="form-check form-switch mb-3"></div>
              <div className="ml-auto d-flex" style={{ gap: '10px' }}>
                <button type="submit" className="btn-label btn btn-primary save-user w-md" size="lg" onClick={handleHotelClicks}>
                <i className="bx bx-hotel label-icon"></i>
                  Add New Hotel
                </button>
              </div>
            </div>
          </Row>
          <Row>
            <Col lg="12">
              {(!!isDisabled || isEdit) ?
                <HotelForm
                  currentStep={currentStep}
                  handleNext={handleNext}
                  handlePrevious={handlePrevious}
                  parentHandleSubmit={handleSubmit}
                  hotels={Hotels}
                  HotelUser={HotelUser}
                  isEdit={isEdit}
                  currencyPlan={currencyPlan}
                />
                :
                <Row>
                  {map(currentPageItems, (hotelList, key) => (
                    <Col xl="4" sm="4" className="mb-2" key={key}>
                      <Card>
                        <CardBody>
                          <div className="d-flex">
                            <div className="avatar-md me-4">
                              <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
                                <img src={sampleImage} alt="" height="30" />
                              </span>
                            </div>

                            <div className="flex-grow-1 overflow-hidden">
                              <h5 className="text-truncate font-size-15">
                                <Link
                                  to={`/hotel-detail/${hotelList.id}`}
                                  state={{ hotelList }}
                                  className="text-dark"
                                >
                                  {hotelList.hotelName}
                                </Link>
                              </h5>
                              <p className="text-muted mb-4">
                                {hotelList.hotelEmail}
                              </p>
                            </div>
                          </div>
                        </CardBody>
                        <Row className="border-top m-0">
                          <Col>
                          <div className="d-flex justify-content-around p-3 border-top">
                            <Link
                              color="primary"
                              onClick={() => handleUserClick(hotelList)}
                            >
                              Edit Details
                            </Link>
                            <Link
                              to={`/hotel-detail/${hotelList.id}`}
                              state={{ hotelList }}
                              style={{color: '#8585F3'}}
                            >
                              View Details
                            </Link>
                          </div>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  ))}
                  <Row>
                    <Col lg="12">
                      <ul className="pagination pagination-rounded justify-content-center mt-2 mb-5">
                        <PaginationItem disabled={page === 1}>
                          <PaginationLink
                            previous
                            href="#"
                            onClick={() => handlePageClick(page - 1)}
                          />
                        </PaginationItem>
                        {map(Array(totalPage), (item, i) => (
                          <PaginationItem active={i + 1 === page} key={i}>
                            <PaginationLink
                              onClick={() => handlePageClick(i + 1)}
                              href="#"
                            >
                              {i + 1}
                            </PaginationLink>
                          </PaginationItem>
                        ))}
                        <PaginationItem disabled={page === totalPage}>
                          <PaginationLink
                            next
                            href="#"
                            onClick={() => handlePageClick(page + 1)}
                          />
                        </PaginationItem>
                      </ul>
                    </Col>
                  </Row>
                </Row>
                // OLD table
                // <TableContainer
                //   columns={columns}
                //   data={hotelList}
                //   isGlobalFilter={true}
                //   isAddHotelList={true}
                //   handleHotelClicks={handleHotelClicks}
                //   // handleNext={handleNext}
                //   // handlePrevious={handlePrevious}
                //   customPageSize={10}
                //   className="custom-header-css"
                // />
              }
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(HotelList);
