import React from 'react';
import { Row, Col } from "reactstrap";

const PrintFooter = () => {
  return (
    <Row
      className="signature-section"
      style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "8rem" }}
    >
      <Col lg="4" xs="4" style={{ textAlign: "center" }}>
        <p>Guest's Signature</p>
      </Col>
      <Col lg="4" xs="4" style={{ textAlign: "center" }}>
        <p>For StinSoft</p>
      </Col>
      <Col lg="4" xs="4" style={{ textAlign: "center" }}>
        <p>www.soft.com</p>
      </Col>
    </Row>
  );
};

export default PrintFooter;
