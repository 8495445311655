import React, { useEffect, useMemo, useState , useRef } from "react";
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import withRouter from "../../../components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import * as Yup from "yup";
import { useFormik } from "formik";
// import { jobs } from "../../../common/data";

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';
import { addPrintStyles , getPrintDetails } from "../../../middleware/backent_helper";


import {
    Content_title, Font_color, Font_size, Font_style, Alignment_horizontal, Alignment_vertical, Type, Font_family
}
    from "./JobListCol";

//redux
import { useSelector, useDispatch } from "react-redux";

import {
    Col,
    Row,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

const JobList = props => {
// function JobList() {

    //meta title
    document.title = "Print Template| Skote - React Admin & Dashboard Template";

    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const [jobsList, setJobsList] = useState([]);
    const [jobs, setJobs] = useState([]);


    // const jobs = [
    //     {
    //         id: 4,
    //         type: "Sign Upload",
    //         content_title: "",
    //         position_top: "2",
    //         position_right: "2",
    //         font_color: "#000000",
    //         font_size: "3",
    //         alignment_horizontal: "right",
    //         alignment_vertical: "bottom",
    //         font_style: "bold",
    //         font_family: "Times New Roman",
    //         sign_upload: "[]", // Assuming this is a placeholder for an actual file or file path
    //         footer_title: null,
    //         footer_slogan: null
    //     }
    // ];
    

    const [dbName, setDbName] = useState(
        localStorage.getItem('dbName')
    )

  

    useEffect(() => {
        finalList()
      }, [dbName])
    
    const finalList = () => {
        getPrintDetails(dbName)
          .then((res) => {
            setJobs(res.data.data);
           
           
          });
      };



    const [color, setColor] = useState('#000000'); // Default color
    const [touched, setTouched] = useState(false);
    const [error, setError] = useState('');

    const handleChange = (event) => {
        const selectedColor = event.target.value;
        setColor(selectedColor);
        setTouched(true);
        validation.setFieldValue('font_color', selectedColor); // Update F

        // Simple validation example
        if (selectedColor === '') {
            setError('Please select a color');
        } else {
            setError('');
        }
    };

    const [selectedType, setSelectedType] = useState("");
    const handleTypeChange = (event) => {
        const value = event.target.value;
        setSelectedType(value); // Step 2: Update selectedType
        validation.handleChange(event); // Ensure formik handles the change as well
    };


   
    const fileInputRef = useRef(null);
   
    const resetFormValues = () => {
        validation.setFieldValue('type', null);
        validation.setFieldValue('content_title', null);
        validation.setFieldValue('position_top', null);
        validation.setFieldValue('position_right', null);
        validation.setFieldValue('font_size', null);
        validation.setFieldValue('alignment_horizontal', null);
        validation.setFieldValue('alignment_vertical', null);
        validation.setFieldValue('font_style', null);
        validation.setFieldValue('font_family', null);
        validation.setFieldValue('font_color', "#000000");
        validation.setFieldValue('sign_upload', '');
        setSelectedFiles([]); // Clear any selected files
       
        // Reset the file input value
    if (fileInputRef.current) {
        fileInputRef.current.value = '';
    }

    };


    const validation = useFormik({


        initialValues: {
            type: '',
            content_title: '',
            position_top: '',
            position_right: '',
            font_size: '',
            alignment_horizontal: '',
            alignment_vertical: '',
            font_style: '',
            font_family:  '',
            font_color: color,
            sign_upload:  '',

        },

        onSubmit: (values) => {
            console.log("datavale", values)
            // Create FormData from the values
            const formData = createFormData(values);

            if (isEdit) {
                // If editing, call the update function
                // updateJob(formData)
                //     .then((res) => {
                //         setResponse(messages.success.updatedDetails("Job"));
                //         setAlertModal(true);
                //     })
                //     .catch((err) => {
                //         setResponse(messages.failed.updateFailed("Job"));
                //         setErrorAlertModal(true);
                //     });
            } else {


               
                addPrintStyles(formData, dbName)
                .then(() => {
                    // Call finalList to refresh the jobs data
                    finalList();
                })
                .catch((error) => {
                    console.error("Error adding print styles:", error);
                });
        }
            

        fetchUpdatedJobs();

        resetFormValues();


        }

    })

    const createFormData = (values) => {
        const formData = new FormData();
        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                const value = values[key];

                // If the value is a File (for the sign_upload), append it specifically
                if (key === 'sign_upload' && value instanceof File) {

                    formData.append("sign_upload", value);

                } else {
                    formData.append(key, value);


                }
            }
        }
        return formData;
    };

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [filePreviews, setFilePreviews] = useState([]);

    const handleFileChange = (event) => {
        const selectedFiles = event.target.files;
        if (selectedFiles.length > 0) {
            setSelectedFiles(selectedFiles); // Save selected files in state
            validation.setFieldValue('sign_upload', selectedFiles[0]); // Save the first file as the sign_upload
        }
    };

    const fetchUpdatedJobs = async () => {
        try {
            const res = await getPrintDetails(dbName);
            setJobs(res.data.data); // Update the jobs state with the fetched data
        } catch (error) {
            console.error("Error fetching updated jobs:", error);
        }
    };


    // // validation
    // const validation = useFormik({
    //     // enableReinitialize : use this flag when initial values needs to be changed
    //     enableReinitialize: true,

    //     initialValues: {
    //         jobId: (job && job.jobId) || '',
    //         jobTitle: (job && job.jobTitle) || '',
    //         companyName: (job && job.companyName) || '',
    //         location: (job && job.location) || '',
    //         experience: (job && job.experience) || '',
    //         position: (job && job.position) || '',
    //         type: (job && job.type) || '',
    //         status: (job && job.status) || '',

    //     },
    //     validationSchema: Yup.object({
    //         jobId: Yup.string().matches(
    //             /[0-9\.\-\s+\/()]+/,
    //             "Please Enter Valid Job Id"
    //         ).required("Please Enter Your Job Id"),
    //         jobTitle: Yup.string().required("Please Enter Your Job Title"),
    //         companyName: Yup.string().required("Please Enter Your Company Name"),
    //         location: Yup.string().required("Please Enter Your Location"),
    //         experience: Yup.string().required("Please Enter Your Experience"),
    //         position: Yup.string().required("Please Enter Your Position"),
    //         type: Yup.string().required("Please Enter Your Type"),
    //         status: Yup.string().required("Please Enter Your Status"),
    //     }),
    //     onSubmit: (values) => {
    //         if (isEdit) {
    //             const updateJobList = {
    //                 id: job ? job.id : 0,
    //                 jobId: values.jobId,
    //                 jobTitle: values.jobTitle,
    //                 companyName: values.companyName,
    //                 location: values.location,
    //                 experience: values.experience,
    //                 position: values.position,
    //                 type: values.type,
    //                 postedDate: "02 June 2021",
    //                 lastDate: "25 June 2021",
    //                 status: values.status,
    //             };
    //             // update Job
    //             dispatch(onUpdateJobList(updateJobList));
    //             validation.resetForm();
    //         } else {
    //             const newJobList = {
    //                 id: Math.floor(Math.random() * (30 - 20)) + 20,
    //                 jobId: values["jobId"],
    //                 jobTitle: values["jobTitle"],
    //                 companyName: values["companyName"],
    //                 location: values["location"],
    //                 experience: values["experience"],
    //                 position: values["position"],
    //                 type: values["type"],
    //                 postedDate: "02 June 2021",
    //                 lastDate: "25 June 2021",
    //                 status: values["status"],
    //             };
    //             // save new Job
    //             dispatch(onAddNewJobList(newJobList));
    //             validation.resetForm();
    //         }
    //         toggle();
    //     },
    // });

    // const dispatch = useDispatch();
    // const { jobs } = useSelector(state => ({
    //     jobs: state.JobReducer.jobs,
    // }));

    // useEffect(() => {
    //     if (jobs && !jobs.length) {
    //         dispatch(onGetJobList());
    //     }
    // }, [dispatch, jobs]);

    // useEffect(() => {
    //     setJobsList(jobs);
    // }, [jobs]);

    // useEffect(() => {
    //     if (!isEmpty(jobs) && !!isEdit) {
    //         setJobsList(jobs);
    //         setIsEdit(false);
    //     }
    // }, [jobs]);

    // const jobs = [];

    const toggle = () => {
        if (modal) {
            setModal(false);
            // setJob(null);
        } else {
            setModal(true);
        }
    };

    // const handleJobClick = arg => {
    //     const job = arg;
    //     setJob({
    //         id: job.id,
    //         jobId: job.jobId,
    //         jobTitle: job.jobTitle,
    //         companyName: job.companyName,
    //         location: job.location,
    //         experience: job.experience,
    //         position: job.position,
    //         type: job.type,
    //         status: job.status,
    //     });

    //     setIsEdit(true);

    //     toggle();
    // };

    //delete Job
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = (job) => {
        setJob(job);
        setDeleteModal(true);
    };

    const handleDeletejob = () => {
        if (job && job.id) {
            dispatch(onDeleteJobList(job.id));
            setDeleteModal(false);
        }
    };
    const handleJobClicks = () => {
        setJobsList("");
        setIsEdit(false);
        toggle();
    };

    

    const columns = useMemo(
        () => [

            {
                Header: "Type",
                accessor: "type",
                filterable: true,
                Cell: (cellProps) => {
                    return <Type {...cellProps} />;
                }
            },
            {
                Header: "Title",
                accessor: "content_title",
                filterable: true,
                Cell: (cellProps) => {
                    return <Content_title {...cellProps} />;
                }
            },
            {
                Header: 'Color',
                accessor: 'font_color',
                filterable: true,
                Cell: (cellProps) => {
                    return <Font_color {...cellProps} />;
                }
            },
            {
                Header: 'Size',
                accessor: 'font_size',
                filterable: true,
                Cell: (cellProps) => {
                    return <Font_size {...cellProps} />;
                }
            },
            {
                Header: 'Style',
                accessor: 'font_style',
                Cell: (cellProps) => {
                    return <Font_style {...cellProps} />;
                }
            },
            {
                Header: 'Alignment Horizondal',
                accessor: 'alignment_horizontal',
                Cell: (cellProps) => {
                    return <Alignment_horizontal {...cellProps} />;
                }
            },
            {
                Header: 'Alignment Vertical',
                accessor: 'alignment_vertical',
                Cell: (cellProps) => {
                    return <Alignment_vertical {...cellProps} />;
                }
            },
            {
                Header: 'Family',
                accessor: 'font_family',
                Cell: (cellProps) => {
                    return <Font_family {...cellProps} />;
                }
            },
            // {
            //     Header: 'Status',
            //     accessor: 'status',
            //     disableFilters: true,
            //     Cell: (cellProps) => {
            //         return <Status {...cellProps} />;
            //     }
            // },
            {
                Header: 'Action',
                accessor: 'action',
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0">
                           

                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-info"
                                    // onClick={() => {
                                    //     const jobData = cellProps.row.original;
                                    //     handleJobClicks(jobData);
                                    // }}
                                >
                                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                    <UncontrolledTooltip placement="top" target="edittooltip">
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li>

                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-danger"
                                    // onClick={() => {
                                    //     const jobData = cellProps.row.original;
                                    //     onClickDelete(jobData);
                                    // }}
                                >
                                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                    <UncontrolledTooltip placement="top" target="deletetooltip">
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                        </ul>
                    );
                }
            },
        ],
        []
    );



    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeletejob}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Print" breadcrumbItem="Print Design" />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody className="border-bottom">
                                    <div className="d-flex align-items-center">
                                        <h5 className="mb-0 card-title flex-grow-1">Print Design</h5>

                                    </div>
                                </CardBody>
                                <Row className="mt-3 mx-3">
                                    <Col xs={12} sm={6} md={3}>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="autoSizingSelect">Type</label>
                                            <Input
                                                type="select"
                                                name="type"
                                                onChange={handleTypeChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.type || ""}
                                                invalid={
                                                    validation.touched.type && validation.errors.type
                                                }
                                            >
                                                <option value="">Select an option</option>
                                                <option value="Header Title">Header Title</option>
                                                <option value="Footer Title">Footer Title</option>
                                                <option value="Sign Upload">Sign Upload</option>
                                                <option value="Footer Slogan">Footer Slogan</option>

                                            </Input>
                                            {/* {validation.touched.type && validation.errors.type ? (
                                                <FormFeedback type="invalid">{validation.errors.type}</FormFeedback>
                                            ) : null} */}
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={3}>

                                        {selectedType === "Sign Upload" ? (
                                            <div className="mb-3">
                                                <Label className="form-label">Upload</Label>
                                                <Input
                                                    id="sign_upload"
                                                    name="sign_upload"
                                                    type="file"
                                                    accept="image/*"
                                                    ref={fileInputRef}
                                                    className={`form-control ${validation.touched.sign_upload && validation.errors.sign_upload ? 'is-invalid' : ''
                                                        }`}
                                                    onChange={handleFileChange}
                                                    onBlur={validation.handleBlur}
                                                />

                                                {/* {validation.touched.sign_upload && validation.errors.sign_upload ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.sign_upload}
                                                    </FormFeedback>
                                                ) : null} */}
                                            </div>
                                        ) : (
                                            <div className="mb-3">
                                                <Label className="form-label">Content</Label>
                                                <Input
                                                    name="content_title"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    
                                                    value={validation.values.content_title || ""}
                                                    invalid={
                                                        validation.touched.content_title && validation.errors.content_title
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {validation.touched.content_title && validation.errors.content_title ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.content_title}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                        )}
                                    </Col>
                                    <Col xs={12} sm={6} md={3}>
                                        <div className="mb-3">
                                            <Label className="form-label">Top</Label>
                                        
                                            <Input
                                                name="position_top"
                                                type="text"

                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.position_top || ""}
                                                invalid={
                                                    validation.touched.position_top &&
                                                        validation.errors.position_top
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {validation.touched.position_top &&
                                                validation.errors.position_top ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.position_top}
                                                </FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={3}>
                                        <div className="mb-3">
                                            <Label className="form-label">Right</Label>
                                            <Input
                                                name="position_right"
                                                type="text"

                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.position_right || ""}
                                                invalid={
                                                    validation.touched.position_right &&
                                                        validation.errors.position_right
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {/* {validation.touched.position_right &&
                                                validation.errors.position_right ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.position_right}
                                                </FormFeedback>
                                            ) : null} */}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mt-3 mx-3">
                                    <Col xs={12} sm={6} md={3}>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="autoSizingSelect">Font Size</label>
                                            <Input
                                                type="select"
                                                name="font_size"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.font_size || ""}
                                                invalid={
                                                    validation.touched.font_size && validation.errors.font_size
                                                }
                                            >
                                                <option value="">Select an option</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>

                                            </Input>
                                            {/* {validation.touched.font_size && validation.errors.font_size ? (
                                                <FormFeedback type="invalid">{validation.errors.font_size}</FormFeedback>
                                            ) : null} */}
                                        </div>
                                    </Col>

                                    <Col xs={12} sm={6} md={3}>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="autoSizingSelect">Alignment Horizondal</label>
                                            <Input
                                                type="select"
                                                name="alignment_horizontal"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.alignment_horizontal || ""}
                                                invalid={
                                                    validation.touched.alignment_horizontal && validation.errors.alignment_horizontal
                                                }
                                            >
                                                <option value="">Select an option</option>
                                                <option value="LEFT">LEFT</option>
                                                <option value="RIGHT">RIGHT</option>
                                                <option value="CENTER">CENTER</option>

                                            </Input>
                                            {/* {validation.touched.alignment_horizontal && validation.errors.alignment_horizontal ? (
                                                <FormFeedback type="invalid">{validation.errors.alignment_horizontal}</FormFeedback>
                                            ) : null} */}
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={3}>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="autoSizingSelect">Alignment Vertical</label>
                                            <Input
                                                type="select"
                                                name="alignment_vertical"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.alignment_vertical || ""}
                                                invalid={
                                                    validation.touched.alignment_vertical && validation.errors.alignment_vertical
                                                }
                                            >
                                                <option value="">Select an option</option>
                                                <option value="TOP">TOP</option>
                                                <option value="BOTTOM">BOTTOM</option>
                                                <option value="CENTER">CENTER</option>

                                            </Input>
                                            {/* {validation.touched.alignment_vertical && validation.errors.alignment_vertical ? (
                                                <FormFeedback type="invalid">{validation.errors.alignment_vertical}</FormFeedback>
                                            ) : null} */}
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={3}>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="autoSizingSelect">Font Style</label>
                                            <Input
                                                type="select"
                                                name="font_style"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.font_style || ""}
                                                invalid={
                                                    validation.touched.font_style && validation.errors.font_style
                                                }
                                            >
                                                <option value="">Select an option</option>
                                                <option value="Normal">Normal</option>
                                                <option value="Bold">Bold</option>
                                                <option value="Italic">Italic</option>

                                            </Input>
                                            {validation.touched.font_style && validation.errors.font_style ? (
                                                <FormFeedback type="invalid">{validation.errors.font_style}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>

                                </Row>
                                <Row className="mt-3 mx-3">
                                    <Col xs={12} sm={6} md={3}>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="autoSizingSelect">Font Family</label>
                                            <Input
                                                type="select"
                                                name="font_family"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.font_family || ""}
                                                invalid={
                                                    validation.touched.font_family && validation.errors.font_family
                                                }
                                            >
                                                <option value="">Select an option</option>
                                                <option value="Times New Roman">Times New Roman</option>
                                                <option value="Arial">Arial</option>

                                            </Input>
                                            {validation.touched.font_family && validation.errors.font_family ? (
                                                <FormFeedback type="invalid">{validation.errors.font_family}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={3}>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="colorPicker">Font Color</label>
                                            <Input
                                                type="color"
                                                name="font_color"
                                                id="colorPicker"
                                                value={validation.values.font_color || color}
                                                onChange={handleChange}
                                                onBlur={() => setTouched(true)}
                                                invalid={touched && error}
                                            />

                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={3}>
                                        <div style={{ alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>

                                            <button
                                                type="button"
                                                className="btn btn-secondary w-md "
                                                onClick={() => {
                                                    validation.handleSubmit();
                                                    handleSubmit(validation.values);
                                                   
                                                }}

                                            >
                                                Save
                                            </button>
                                        </div>
                                    </Col>
                                </Row>


                                <CardBody>
                                    <TableContainer
                                        columns={columns}
                                        data={jobs}
                                        // isGlobalFilter={true}
                                        // isAddOptions={false}
                                        // handleJobClicks={handleJobClicks}
                                    //  isJobListGlobalFilter={true}
                                    customPageSize={10}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Modal isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle} tag="h4">
                            {!!isEdit ? "Edit Job" : "Add Job"}
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    // validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <Row>
                                    <Col className="col-12">
                                        <div className="mb-3">
                                            <Label className="form-label"> Job Id</Label>
                                            <Input
                                                name="jobId"
                                                type="text"
                                                placeholder="Insert Job Id"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.jobId || ""}
                                                invalid={
                                                    validation.touched.jobId && validation.errors.jobId ? true : false
                                                }
                                            />
                                            {validation.touched.jobId && validation.errors.jobId ? (
                                                <FormFeedback type="invalid">{validation.errors.jobId}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Job Title</Label>
                                            <Input
                                                name="jobTitle"
                                                type="text"
                                                placeholder="Insert Job Title"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.jobTitle || ""}
                                                invalid={
                                                    validation.touched.jobTitle && validation.errors.jobTitle ? true : false
                                                }
                                            />
                                            {validation.touched.jobTitle && validation.errors.jobTitle ? (
                                                <FormFeedback type="invalid">{validation.errors.jobTitle}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Company Name</Label>
                                            <Input
                                                name="companyName"
                                                type="text"
                                                placeholder="Insert Company Name"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.companyName || ""}
                                                invalid={
                                                    validation.touched.companyName && validation.errors.companyName ? true : false
                                                }
                                            />
                                            {validation.touched.companyName && validation.errors.companyName ? (
                                                <FormFeedback type="invalid">{validation.errors.companyName}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Location</Label>
                                            <Input
                                                name="location"
                                                placeholder="Insert Location"
                                                type="text"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.location || ""}
                                                invalid={
                                                    validation.touched.location && validation.errors.location ? true : false
                                                }
                                            />
                                            {validation.touched.location && validation.errors.location ? (
                                                <FormFeedback type="invalid">{validation.errors.location}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Experience</Label>
                                            <Input
                                                name="experience"
                                                type="text"
                                                placeholder="Insert Experience"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={
                                                    validation.values.experience || ""
                                                }
                                                invalid={
                                                    validation.touched.experience && validation.errors.experience ? true : false
                                                }
                                            />
                                            {validation.touched.experience && validation.errors.experience ? (
                                                <FormFeedback type="invalid">{validation.errors.experience}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Position</Label>
                                            <Input
                                                name="position"
                                                type="text"
                                                placeholder="Insert Position"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.position || ""}
                                                invalid={
                                                    validation.touched.position && validation.errors.position ? true : false
                                                }
                                            />
                                            {validation.touched.position && validation.errors.position ? (
                                                <FormFeedback type="invalid">{validation.errors.position}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Type</Label>
                                            <Input
                                                name="type"
                                                type="select"
                                                className="form-select"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={
                                                    validation.values.type || ""
                                                }
                                                invalid={
                                                    validation.touched.type && validation.errors.type
                                                        ? true
                                                        : false
                                                }
                                            >
                                                <option>Full Time</option>
                                                <option>Part Time</option>
                                                <option>Freelance</option>
                                                <option>Internship</option>

                                            </Input>
                                            {validation.touched.type && validation.errors.type ? (
                                                <FormFeedback type="invalid">{validation.errors.type}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Status</Label>
                                            <Input
                                                name="status"
                                                type="select"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={
                                                    validation.values.status || ""
                                                }
                                                invalid={
                                                    validation.touched.status && validation.errors.status ? true : false
                                                }
                                            >
                                                <option>Active</option>
                                                <option>New</option>
                                                <option>Close</option>
                                            </Input>
                                            {validation.touched.status && validation.errors.status ? (
                                                <FormFeedback status="invalid">{validation.errors.status}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="text-end">
                                            <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withRouter(JobList);
// export default JobList;