import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import { getHotelById } from "../../middleware/backent_helper";

const PrintHeader = () => {
  const [hoteldetails, setHoteldetails] = useState({});
  const logoUrl = window.location.origin + '/icon.png';
  // Fetch hotel details when component mounts
  useEffect(() => {
    const hid = localStorage.getItem("hotelId")?.replace(/"/g, "");
    const dbName = localStorage.getItem("dbName");
    

    // Fetch hotel details
    getHotelById(hid, dbName)
      .then((res) => {
        setHoteldetails(res.data.data);
      })
      .catch((error) => {
        console.error("Error fetching hotel details:", error);
      });
  }, []);

  return (
    <Row className="header-section" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <Col xs="4" style={{ textAlign: "left" }}>
        <img src={logoUrl} alt="Logo" style={{ width: "100px", height: "auto" }} />
      </Col>
      <Col xs="4" style={{ textAlign: "right" }}>
        <p className="receipt-address">
          {hoteldetails?.address1} {hoteldetails?.address2}
          <br />
          {hoteldetails?.address3}
          <br />
          {hoteldetails?.city} - {hoteldetails?.postalCode}
          <br />
          {hoteldetails?.state}, {hoteldetails?.country}
          <br />
          Ph: {hoteldetails?.contactNo1}
        </p>
      </Col>
    </Row>
  );
};

PrintHeader.propTypes = {
  logoUrl: PropTypes.string.isRequired,
};

export default PrintHeader;
