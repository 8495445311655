import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody, FormGroup, Label, Input, FormFeedback, InputGroup, Form, Table
} from "reactstrap";
import * as Yup from 'yup';
import Select from "react-select";
import { useFormik } from 'formik';
import { getPaymentTypes, getGuestDetils, getAdvanceById, post_Advance_Details, getGRCNoById ,getHotelById} from "../../../middleware/backent_helper";
import AlertModal from "../../../components/Common/alertModal";
import ErrorAlertModal from "../../../components/Common/errorAlertModal";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import messages from '../../../utils/constants/messages';
//i18n
import { withTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import AdvanceReceiptPrint from "../../../pages/PrintFormats/AdvanceReceiptPrint";
import AdvanceShiftRoomReceiptPrint from "../../../pages/PrintFormats/AdvanceShiftRoomReceiptPrint";
import logo from "../../../assets/images/logo.png";

const AdvancePay = props => {

  document.title = "StayPro | Advance"
  const [hid, setHid] = useState(() => {
    const storedHotelId = localStorage.getItem('hotelId')
    return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
  });
  const [dbName, setDbName] = useState(
    localStorage.getItem('dbName')
  );
  const [paymentTypeList, setPaymentTypes] = useState([]);
  const [selectedPayment, setselectedPayment] = useState('');
  const [GuestDetails, setGuestDetails] = useState([]);
  const [advanceDetails, setAdvanceDetails] = useState([]);
  const [dateTime, setDateTime] = useState(new Date());
  const [alertModal, setAlertModal] = useState(false);
  const [response, setResponse] = useState(false);
  const [masterName, setMasterName] = useState("Advance");
  const [errorAlertModal, setErrorAlertModal] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const bookingId = queryParams.get('bookingId');
  const [advanceNo, setadvanceNo] = useState([]);
  const [totalAdvanceAmounts, setTotalAdvanceAmount] = useState([]);
  const [currentAdvanceAmount, setCurrentAdvanceAmount] = useState(0);
  const [hoteldetails, setHotel] = useState([]);
  
 
  const receiptRef = useRef();
  const printReceipt = () => {
    const printWindow = window.open('', '_blank'); // Open a new window
    printWindow.document.write(`
        <html>
            <head>
                <title>Print Receipt</title>
                <style>
                    @media print {
                        @page {
                           /* Set page size to A5 */
                            margin: 10mm; /* Set margins */
                        }
                        body {
                            margin: 0; 
                            padding: 10mm;
                            font-family: Arial, sans-serif; 
                            width: 170mm; 
                            height: 210mm; 
                        }
                    }
                    /* Additional styles for the content */
                    .receipt-container {
                        width: 100%; 
                        box-sizing: border-box;
                    }
                </style>
            </head>
            <body>
                <div class="receipt-container">
                    ${document.querySelector('.receipt-container').innerHTML}
                </div>
            </body>
        </html>
    `);
    printWindow.print();
    // printWindow.document.close(); // Close the document to finish writing
   // Trigger the print dialog
};

// Map bill format names to respective imported components
const billFormatComponents = {
  "/static/media/ShiftRoomAdvancePrint.6f93543ec9598b8c8812.png": AdvanceShiftRoomReceiptPrint,
  "/static/media/RoomAdvancePrint.6f93543ec9598b8c8812.png": AdvanceReceiptPrint
};

const SelectedBillFormat = billFormatComponents[hoteldetails.billFormat] || AdvanceReceiptPrint;

  useEffect(() => {
    getPaymentTypes(hid, dbName)
      .then((res) => {
        setPaymentTypes(res.data.data);
      });
    getGRCNoById(hid, dbName, bookingId)
    .then((res) => {
      setadvanceNo(res.data.data);
    });
  }, [])

  useEffect(() => {
    getHotelById(hid, dbName)
  .then((res) => {
    
    setHotel(res.data.data);
  });
}, [])

 
  const handlePaymentTypeChange = selectedOption => {
    setselectedPayment(selectedOption)
    validation.setFieldValue('payment', selectedOption ? selectedOption.value : '');

  }

  const GetAdvanceList = () => {
    getAdvanceById(hid, dbName, bookingId)
      .then((res) => {
        setAdvanceDetails(res.data.data);
      });
  };


  useEffect(() => {
    getGuestDetils(hid, dbName, bookingId)
      .then((res) => {

        setGuestDetails(res.data.data);
      });
  }, [])

  useEffect(() => {
    GetAdvanceList();
    setDateTime(formatDate(dateTime));
  }, [])


  const validation = useFormik({
    initialValues: {
      payment: "",
      advance: ""
    },
    enableReinitialize: true,
    validationSchema: Yup.object({

      payment: Yup.string().required('payment type is required'),
      advance: Yup.string().required("Please Enter Your Advance Amount"),
    }),
    onSubmit: (values) => {
      post_Advance(values)
    }
  });


  const formatDate = (dateTime) => {
    const date = new Date(dateTime);
    if (isNaN(date)) {
      throw new Error("Invalid Date");
    }

    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const post_Advance = (values) => {

    const data = JSON.stringify({
      ArrivalDateTime: dateTime,
      advanceAmount: values.advance,
      PaymentTypeId: values.payment,
      bookingId: bookingId,
      roomId: GuestDetails.roomId,
      hotelId: hid,
      db: dbName,
      description: "Room Advance for " + GuestDetails.roomNo,
      remarks: "for " + GuestDetails.guestName
    });
    post_Advance_Details(data).then((res) => {
      if (res.data.success) {
        setResponse(messages.success.createdMessage(masterName))
        setAlertModal(true);
       
        setCurrentAdvanceAmount(values.advance);
        const totalAdvanceAmount = advanceDetails.reduce((total, item) => {
        return total + (item.AdvanceAmount || 0); 
      }, 0) + parseFloat(values.advance); 
      setTotalAdvanceAmount(totalAdvanceAmount);


      }
     
    
    }).catch(err => {
      if (err.response.status === 500) {
        setResponse(messages.failed.createFailed())
      } setErrorAlertModal(true)
    });
    validation.resetForm();
    setselectedPayment('');
  }

  return (
    <React.Fragment>
      {
        alertModal && (
      <AlertModal
        show={alertModal}
        onOkClick={() => {
          GetAdvanceList(), setAlertModal(false);
          printReceipt();
        }}
        onCloseClick={() => setAlertModal(false)}
        res={response}
      />
    )
}
      
      <ErrorAlertModal
        show={errorAlertModal}
        onOkClick={() => { GetAdvanceList(), setErrorAlertModal(false) }}
        onCloseClick={() => setErrorAlertModal(false)}
        res={response}
      />



<SelectedBillFormat
  ref={receiptRef}
  hoteldetails={hoteldetails}
  GuestDetails={GuestDetails}
  advanceNo={advanceNo}
  totalAdvanceAmount={totalAdvanceAmounts}
  currentAdvanceAmount={currentAdvanceAmount}
  dateTime={formatDate(dateTime)}
/>


 {/* </div> */}

    <div className="page-content">
        <Container fluid>
          <Breadcrumbs breadcrumbItem={props.t("Manage Advance")} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="mb-2">
                    <Row className="align-items-center">
                      <Col lg={4} md={4} sm={4}>
                        <strong>Guest Name :</strong>
                        <span> {GuestDetails.guestName}</span>
                        <span style={{ display: 'none' }}> {GuestDetails.guestId}</span>
                      </Col>
                      <Col lg={3} md={3} sm={3}>
                        <strong>Mobile No :</strong>
                        <span> {GuestDetails.mobileNo}</span>
                      </Col>

                      <Col lg={3} md={3} sm={3}>
                        <strong>Checkin Date :</strong><span> {GuestDetails.checkInDate ? GuestDetails.checkInDate.slice(0, 16) : ''}</span>
                      </Col>

                      <Col lg={2} md={2} sm={2}>
                        <strong>Room No :</strong><span> {GuestDetails.roomNo}</span>
                        <span style={{ display: 'none' }}> {GuestDetails.roomId}</span>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <Card style={{ border: '1px solid rgb(210 212 218)', borderRadius: '8px' }}>
                <CardHeader>
                  <h5 className="card-title">{props.t("Add Advance Payment")}</h5>
                </CardHeader>
                <CardBody>

                  <Row>
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                      }}
                    >
                      <Row>
                        <Col lg={4}>
                          <div className="mb-3">
                            <FormGroup>
                              <Label className="form-label">GRC No.</Label>
                              <Input
                                name="grcNo"
                                type="text"
                                placeholder="GRC No"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={advanceNo.GRCNo ||"1"}
                                disabled={true}
                                invalid={
                                  validation.touched.grcNo &&
                                    validation.errors.grcNo
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.grcNo &&
                                validation.errors.grcNo ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.grcNo}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                            <FormGroup>
                              <Label className="form-label">Date Time</Label>
                              <Input
                                name="dateTime"
                                type="text"
                                placeholder="Date Time"
                                onBlur={validation.handleBlur}
                                onChange={(date) => setDateTime(date)}
                                value={dateTime}
                                disabled={true}
                                invalid={
                                  validation.touched.dateTime &&
                                    validation.errors.dateTime
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.dateTime &&
                                validation.errors.dateTime ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.dateTime}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                            <FormGroup>
                              <Label className="form-label">Room No.</Label>
                              <Input
                                name="roomNo"
                                type="text"
                                placeholder="Room No"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={GuestDetails.roomNo}
                                disabled={true}
                                invalid={
                                  validation.touched.roomNo &&
                                    validation.errors.roomNo
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.roomNo &&
                                validation.errors.roomNo ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.roomNo}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <div className="mb-3">
                            <FormGroup>
                              <Label className="form-label">Room Rent</Label>
                              <Input
                                name="room_rent"
                                type="text"
                                placeholder="Room Rent"
                                onBlur={validation.handleBlur}
                                value={GuestDetails.tariff}
                                disabled={true}
                                invalid={
                                  validation.touched.room_rent &&
                                    validation.errors.room_rent
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.room_rent &&
                                validation.errors.room_rent ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.room_rent}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                            <FormGroup>
                              <Label className="form-label">Advance Amount</Label>
                              <Input
                                name="advance"
                                type="number"
                                placeholder="0.00"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.advance || ""}
                                invalid={
                                  validation.touched.advance &&
                                    validation.errors.advance
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.advance &&
                                validation.errors.advance ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.advance}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                            <FormGroup>
                              <Label className="form-label">Payment Type</Label>
                              <Select
                                name="payment"
                                placeholder="Select Payment Type"
                                className={`react-select-containe ${validation.touched.payment && validation.errors.payment
                                  ? 'is-invalid'
                                  : ''
                                  }`}
                                value={selectedPayment}
                                onChange={handlePaymentTypeChange}
                                options={paymentTypeList.map((payment) => ({
                                  label: payment.paymentType,
                                  value: payment.id
                                }))}
                                isClearable
                                isSearchable
                                styles={{
                                  menu: (provided) => ({
                                    ...provided,
                                    top: "100%",
                                    position: "absolute",
                                    width: "100%",
                                    zIndex: 999,
                                  }),
                                }}
                              />
                              {validation.touched.payment &&
                                validation.errors.payment ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.payment}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </div>

                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="text-end">
                            <button type="submit" className="btn btn-primary w-md me-3" >
                              Save
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary w-md "
                            // onClick={handleCancelButton}
                            >
                              Cancel
                            </button>

                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <Card style={{ border: '1px solid rgb(210 212 218)', borderRadius: '8px' }}>
                <CardHeader>
                  <h5 className="card-title">{props.t("Advance List")}</h5>
                </CardHeader>
                <CardBody>
                  <Table bordered responsive>
                    <thead>
                      <tr>
                        <th className="text-center">S.No.</th>
                        <th className="text-center">Date Time</th>
                        <th className="text-center">Type</th>
                        <th className="text-center">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {advanceDetails && advanceDetails.length > 0 ? (
                        advanceDetails.map((detail, index) => (
                          <tr key={index}>
                            <td className="text-center">{index + 1}</td>
                            <td className="text-center">{detail.ArrivalDateTime}</td>
                            <td className="text-center">{detail.PaymentType}</td>
                            <td className="text-center">{detail.AdvanceAmount}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td className="text-center" colSpan="4">
                            No Data
                          </td>
                        </tr>
                      )}
                    </tbody>

                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  );
}

export default withTranslation()(AdvancePay);