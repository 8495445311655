import React, { useState, useEffect } from "react";
import {
    Modal,
    ModalBody,
    Form,
    Row,
    Col,
    Label,
    Card,
    CardBody,
    CardHeader,
    FormGroup,
    Table,
    Input
} from "reactstrap";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getReservationRecord, getRoomServiceData, getGuestChargeData, getAdvanceData } from '../../middleware/backent_helper';
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import '../../assets/scss/plan.scss'
import viewReservation from "./viewReservation";

const ViewReservationModal = ({ show, onCloseClick, viewReservationData }) => {
    const [hid, setHid] = useState(() => {
        const storedHotelId = localStorage.getItem('hotelId');
        return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
    });
    const [dbName, setDbName] = useState(localStorage.getItem('dbName'));
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [guestDetails, setGuestDetails] = useState();
    const [employeeList, setEmployeeList] = useState([]);
    const [roomServiceData, setRoomServiceData] = useState({});
    const [advanceData, setAdvanceData] = useState({});
    const [isRefund, setIsRefund] = useState(false);
    const [totalData, setTotalData] = useState(0);
    const [totalLessAdvance, setTotalLessAdvance] = useState(0);
    const [GuestCharge, setGuestCharges] = useState({});
    const [reservationDetails, setReservationDetails] = useState();

    const navigate = useNavigate();

    useEffect(() => {
        if (viewReservationData?.reservation_id) {
            getReservationRecord(dbName, viewReservationData?.reservation_id)
                .then((res) => {
                    setReservationDetails(res.data);


                });
        }
    }, [show])

    const validation = useFormik({
        initialValues: {
            servicePersonId: "",
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            servicePersonId: Yup.string().required("Please Choose service person"),
        }),
        onSubmit: (values) => {
            Get_record(values);
            validation.resetForm();
        }
    });

    return (
        <React.Fragment>
            <Modal size="lg" isOpen={show} toggle={onCloseClick} centered={true}>
                <ModalBody className="px-4 py-5">
                    <button type="button" onClick={onCloseClick} className="btn-close position-absolute end-0 top-0 m-3"></button>
                    <h5 className="mb-4">Reservation History</h5>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >

                        {/* Guest Details Card */}
                        <Card className="mb-4">
                            <CardHeader className="grey-background text-black fw-bold">
                                Guest Details
                            </CardHeader>
                            <CardBody>
                                <Row className="mb-3">
                                    <Col md={6}>
                                        <div><strong>Check-In:</strong>{moment(reservationDetails?.checkInDate).format('YYYY-MM-DD')}</div>
                                    </Col>
                                    <Col md={6}>
                                        <div><strong>Check-Out:</strong> {moment(reservationDetails?.checkOutDate).format('YYYY-MM-DD')}</div>
                                    </Col>

                                </Row>
                                <Row className="mb-3">
                                    <Col md={6}>
                                        <div><strong>Booking Date</strong>{moment(reservationDetails?.bookingDate).format('YYYY-MM-DD')}</div>
                                    </Col>
                                    <Col md={6}>
                                        <div><strong>Name:</strong> {reservationDetails?.guest.GuestName}</div>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md={6}>
                                        <div><strong>Mobile:</strong> {reservationDetails?.guest.mobileNo}</div>
                                    </Col>
                                    <Col md={6}>
                                        <div><strong>Address:</strong> {reservationDetails?.guest.address}</div>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md={6}>
                                        <div><strong>Paid Advance:</strong> {reservationDetails?.advanceAmount}</div>
                                    </Col>

                                    <Col md={6}>
                                        <div><strong>Payment</strong> {reservationDetails?.paymentType}
                                        </div>
                                    </Col>


                                </Row>

                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader className="grey-background text-black fw-bold">
                                Room Details
                            </CardHeader>
                            <CardBody>
                                <Row className="mb-2">
                                    <Col md={6}>
                                        {reservationDetails?.roomType} ({reservationDetails?.roomCount}) - <strong> {reservationDetails?.tariff}</strong>
                                    </Col>



                                </Row>
                            </CardBody>
                        </Card>

                        {/* Button Row */}
                        <Row className="mt-3">

                            <Col className="col-1 mx-4">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                >
                                    Print
                                </button>
                            </Col>
                        </Row>

                    </Form>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default ViewReservationModal;
