import TableContainer from "../../../components/Common/TableContainer";
import withRouter from "../../../components/Common/withRouter";
import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup"
import { useFormik } from "formik"
import Select from "react-select"
import { Description, InDate, CreditCard, Online, Cash, Expenses, Remarks } from "./indexCol";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";
import AlertModal from "../../../components/Common/alertModal";
import ErrorAlertModal from "../../../components/Common/errorAlertModal";
import { DateRangePicker } from "react-date-range";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { getHotelById, getCategory } from "../../../middleware/backent_helper";
import DatePicker from "react-datepicker";

const IncomeAndExpense = props => {
  //meta title
  document.title = "Income & Expenses | Income & Expenses ";

  const [transactionDate, setTransactionDate] = useState(new Date());
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [errorAlertModal, setErrorAlertModal] = useState(false);
  const [hid, setHid] = useState(() => {
    const storedHotelId = localStorage.getItem('hotelId')
    return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
  });
  const [dbName, setDbName] = useState(
    localStorage.getItem('dbName')
  );
  const [IncomeExpenseList, setIncomeExpenseList] = useState()
  const [CategoryList, setCategoryList] = useState([]);
  const [incomeOrExpense, setIncomeOrExpense] = useState();
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [hotelUser, setHotel] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [response, setResponse] = useState(false);
  const [IncomeExpensesData, setIncomeExpensesData] = useState([]);
  const datePickerRef = useRef(null);
  const today = new Date();
  const [selectedDates, setSelectedDates] = useState([
    {
      startDate: today,
      endDate: today,
      key: "selection",
    },
  ]);
  const formattedDateRange = `${format(selectedDates[0].startDate, "dd-MM-yyyy")} / ${format(selectedDates[0].endDate, "dd-MM-yyyy")}`;

  const columns = useMemo(() => [
    {
      Header: "S.No",
      Cell: ({ row, rows }) => {
        return (
          <div className="table-body">
            {rows.length - row.index}
          </div>
        );
      },
    },
    {
      Header: "Description",
      accessor: "description",
      filterable: true,
      Cell: cellProps => {
        return (
          <div className="table-cell text-center">
            <Description {...cellProps} />
          </div>
        );
      },
    },
    {
      Header: "Date",
      accessor: "date",
      filterable: true,
      Cell: cellProps => {
        return (
          <div className="table-cell text-center">
            <InDate {...cellProps} />
          </div>
        );
      },
    },
    {
      Header: "Card",
      accessor: "card",
      filterable: true,
      Cell: cellProps => {
        return (
          <div className="table-cell text-center">
            <CreditCard {...cellProps} />
          </div>
        );
      },
    },
    {
      Header: "Online",
      accessor: "online",
      filterable: true,
      Cell: cellProps => {
        return (
          <div className="table-cell text-center">
            <Online {...cellProps} />
          </div>
        );
      },
    },
    {
      Header: "Cash",
      accessor: "cash",
      filterable: true,
      Cell: cellProps => {
        return (
          <div className="table-cell text-center">
            <Cash {...cellProps} />
          </div>
        );
      },
    },
    {
      Header: "Expenses",
      accessor: "expense",
      filterable: true,
      Cell: cellProps => {
        return (
          <div className="table-cell text-center">
            <Expenses {...cellProps} />
          </div>
        );
      },
    },
    {
      Header: "Remarks",
      accessor: "remarks",
      filterable: true,
      Cell: cellProps => {
        return (
          <div className="table-cell text-center">
            <Remarks {...cellProps} />
          </div>
        );
      },
    },
  ]);

  useEffect(() => {
    getHotelById(hid)
      .then(response => {
        const hotelData = response.data.data;
        setHotel(hotelData)
      })
  }, [])

  useEffect(() => {
    GetCategoryList()
  }, [])

  const GetCategoryList = () => {
    getCategory(hid, dbName)
      .then((res) => {
        setCategoryList(res.data.data);
      });
  }

  const handleDateRangeChange = (ranges) => {
    setSelectedDates([ranges.selection]);
    setDatePickerOpen(false);
  };

  const handleInputClick = () => {
    setDatePickerOpen(true);
  };

  const handleIncomeAndExpneseClick = () => {
    toggle();
  };

  const handleTypeChange = (selectedOption) => {
    setIncomeOrExpense(selectedOption);
    if (incomeOrExpense?.value !== selectedOption?.value) {
      validation.setFieldValue('categoryId', selectedOption?.label);
    }
  };

  // Determine which radio button should be disabled
  const disabledRadio = (type) => {
    return incomeOrExpense?.type && incomeOrExpense?.type !== type;
  };

  const formatToTwoDecimalPlaces = value => {
    const number = parseFloat(value)
    return isNaN(number) ? "" : number.toFixed(2)
  }

  const validation = useFormik({
    initialValues: {
      transactionDate: transactionDate || new Date(),
      categoryId: "",
      cash: "",
      remarks: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      categoryId: Yup.string().required("Please Select Category Type"),
      cash: Yup.string().required("Please Enter Cash"),
      remarks: Yup.string().required("Please Enter Remark"),
    }),
    onSubmit: values => {
      dispatch(createIncomeExpense(values))
    },
  });

  const createIncomeExpense = values => {
    const data = JSON.stringify({
      transactionDate: values.transactionDate,
      categoryId: values.categoryId,
      remarks: values.remarks,
      cash: values.cash,
      hotelId: hid,
      dbName: dbName
    })
    createInEx(data)
      .then(res => {
        if (res.data.success) {
          setResponse(messages.success.createdMessage(masterName))
          setAlertModal(true)
        }
      }).catch(err => {
        if (err.response.status === 404) {
          setResponse(messages.failed.AlreadyExists(masterName))
        } else {
          setResponse(messages.failed.createFailed(masterName))
        }
        setErrorAlertModal(true)
      });
    toggle()
  }

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const toggle = () => {
    if (modal) {
      setModal(false)
      setIncomeOrExpense(null)
      clearFormValues();
    } else {
      setModal(true)
    }
  }

  const clearFormValues = () => {
    validation.resetForm()
  }

  const handleBlur = event => {
    validation.handleBlur(event)
    const formattedValue = formatToTwoDecimalPlaces(event.target.value)
    validation.setFieldValue(event.target.name, formattedValue)
  }

  const handleDeleteUser = () => {
    // deleteEbAccount(GensetAccount).then( finalList())
    onPaginationPageChange(1);
    setDeleteModal(false);
  };


  const handleClickOutside = (event) => {
    if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
      setDatePickerOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <AlertModal
        show={alertModal}
        onOkClick={() => {
          finalList();
          setAlertModal(false);
        }}
        onCloseClick={() => setAlertModal(false)}
        res={response}
      />
      <ErrorAlertModal
        show={errorAlertModal}
        onOkClick={() => { finalList(), setErrorAlertModal(false) }}
        onCloseClick={() => setErrorAlertModal(false)}
        res={response}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Accounts" breadcrumbItem="Manage Inventory Sales" />
          <div className="mb-3">
            <Row>
              <Col lg="6" xs="12" md="6">
                <div className="input-group">
                  <div className="input-group-text">
                    <span className="input-group-prepand">From - To</span>
                  </div>
                  <input
                    type="text"
                    value={formattedDateRange}
                    readOnly
                    onClick={handleInputClick}
                    placeholder="Select Date Range"
                    className="form-control"
                  />
                  <label className="input-group-text" htmlFor="inputGroupFile01">
                    <FontAwesomeIcon icon={faCalendarAlt} />
                  </label>
                </div>

              </Col>
              <Col lg="6" xs="6" md="4" className="mt-3 mt-md-0  text-md-left">
                <button
                  className="btn btn-success"
                  onClick={() => handleSearch(selectedDates[0].startDate, selectedDates[0].endDate)}
                >
                  <span className="fas fa-search" /> Search
                </button>
              </Col>
            </Row>
            {datePickerOpen && (
              <div ref={datePickerRef} className="date-range-picker-dropdown mt-1">
                <DateRangePicker
                  onChange={handleDateRangeChange}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={selectedDates}
                  direction="horizontal"
                  className="date-range-picker-dropdown"

                />
              </div>
            )}
          </div>
          <Row>
            <Col lg="12">
              <TableContainer
                columns={columns}
                data={IncomeExpensesData}
                hotel={hotelUser}
                isGlobalFilter={true}
                isAddIncomeAndExpenses={true}
                handleIncomeAndExpneseClick={handleIncomeAndExpneseClick}
                customPageSize={10}
                className="custom-header-css"
              />
            </Col>

            <Modal className="modal-lg" isOpen={modal} toggle={toggle} backdrop="static" keyboard={false}>
              <ModalHeader toggle={toggle} tag="h4">
                {!!isEdit ? "Edit Income And Expenses Details" : "Add Income And Expenses Details"}
              </ModalHeader>
              <ModalBody>
                <Form
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                >
                  <Row>
                    <Col xs={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Transaction Date
                        </Label>
                        <DatePicker
                          name="transactionDate"
                          selected={transactionDate}
                          onChange={(date) => setTransactionDate(date)}
                          className="form-control"
                          dateFormat="d-MM-yyyy"
                          placeholderText="Select date"
                          value={validation.values.transactionDate || ""}
                          invalid={
                            validation.touched.transactionDate &&
                              validation.errors.transactionDate
                              ? true
                              : false
                          }
                        />
                        {validation.touched.transactionDate &&
                          validation.errors.transactionDate ? (
                          <FormFeedback type="invalid">
                            {validation.errors.transactionDate}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Select Category Type
                        </Label>
                        <Select
                          id="categoryId"
                          name="transactionDate"
                          options={CategoryList.map(category => ({ value: category.id, label: category.name, type: category.type }))}
                          placeholder="Select a Category"
                          value={incomeOrExpense || null}
                          onChange={handleTypeChange}
                          isClearable
                          isSearchable
                          classNamePrefix="react-select"
                          className={`react-select-containers ${validation.touched.categoryId && validation.errors.categoryId ? "is-invalid" : ""}`}
                          styles={{
                            menu: provided => ({ ...provided, top: '100%', position: 'absolute', width: '100%', zIndex: 999 }),
                          }}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.categoryId && validation.errors.categoryId
                          }
                        />
                        {validation.touched.categoryId && validation.errors.categoryId ? (
                          <FormFeedback type="invalid">
                            {validation.errors.categoryId}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="mb-3">
                        <br></br>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '10px' }}>
                        {['Income', 'Expense'].map((type) => (
                          <div
                            className="form-check mb-3"
                            style={{ marginRight: type === 'income' ? '15px' : '0' }}
                            key={type}
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              name="incomeOrExpense"
                              id={`${type}Radio`}
                              value={type}
                              checked={incomeOrExpense?.type === type}
                              disabled={disabledRadio(type)}
                              onChange={() => {
                                const selectedCategory = CategoryList.find(
                                  (category) => category.type === type
                                );
                                if (selectedCategory) {
                                  setSelectedOption(selectedCategory);
                                }
                              }}
                            />
                            <label className="form-check-label" htmlFor={`${type}Radio`}>
                              {type.charAt(0).toUpperCase() + type.slice(1)}
                            </label>
                          </div>
                        ))}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <div className="mb-3">
                        <Label className="form-label">Cash</Label>
                        <Input
                          name="cash"
                          type="number"
                          placeholder="Cash"
                          onChange={validation.handleChange}
                          onBlur={handleBlur}
                          value={validation.values.cash || ""}
                          invalid={
                            validation.touched.cash &&
                              validation.errors.cash
                              ? true
                              : false
                          }
                          min="0"
                        />
                        {validation.touched.cash &&
                          validation.errors.cash ? (
                          <FormFeedback type="invalid">
                            {validation.errors.cash}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col>
                      <div className="mb-3">
                        <Label className="form-label">Remarks</Label>
                        <Input
                          name="remarks"
                          type="text"
                          placeholder="Remarks"
                          onChange={validation.handleChange}
                          value={validation.values.remarks || ""}
                          invalid={
                            validation.touched.remarks &&
                              validation.errors.remarks
                              ? true
                              : false
                          }
                          min="0"
                        />
                        {validation.touched.remarks &&
                          validation.errors.remarks ? (
                          <FormFeedback type="invalid">
                            {validation.errors.remarks}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <button type="submit" className="btn btn-primary w-md me-3">
                          {isEdit ? 'Update' : 'Save'}
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary w-md "
                          onClick={toggle}
                        >
                          Cancel
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </ModalBody>
            </Modal>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default withRouter(IncomeAndExpense);