import React, { useEffect, useState, useRef, useMemo } from "react";
import withRouter from "../../../components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import Select from "react-select";
import { useFormik } from "formik";
import { getInventorySales, createInventorySale, updateInventorySale, deleteInventorySale, getInventory  } from "../../../middleware/backent_helper";
import { Description, InventoryDates, InventoryCash, Remarks } from "./inventoryCol";
import messages from '../../../utils/constants/messages';
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";
import AlertModal from "../../../components/Common/alertModal";
import ErrorAlertModal from "../../../components/Common/errorAlertModal";
//Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "assets/scss/datatables.scss";
//New
import { DateRangePicker } from "react-date-range";
import { format } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
//redux
import { useSelector, useDispatch } from "react-redux";

const InventorySales = props => {
  //meta title
  document.title = "Inventory Sales | Accounts ";

  const dispatch = useDispatch();
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [transactionDate, setStartDate] = useState(new Date());
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [inventorySaleList, setInventorySaleList] = useState([]);
  const [inventoryStock, setInventoryStock] = useState([]);
  const [selectedStock, setSelectedStock] = useState([]);
  const [availableStock, setAvailableStock]  = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [errorAlertModal, setErrorAlertModal] = useState(false);
  const [masterName, setMasterName] = useState("Inventory Sales");
  const [response, setResponse] = useState(false);
  const [hid, setHid] = useState(() => {
    const storedHotelId = localStorage.getItem('hotelId')
    return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
  });
  const [dbName, setDbName] = useState(
    localStorage.getItem('dbName')
  )

  const today = new Date();
  const [selectedDates, setSelectedDates] = useState([
    {
      startDate: today,
      endDate: today,
      key: "selection",
    },
  ]);
  const formattedDateRange = `${format(selectedDates[0].startDate, "dd-MM-yyyy")} / ${format(selectedDates[0].endDate, "dd-MM-yyyy")}`;


  const handleDateRangeChange = (ranges) => {
    setSelectedDates([ranges.selection]);
    setDatePickerOpen(false);
  };

  const handleInputClick = () => {
    setDatePickerOpen(true);
  };

  useEffect(() => {
    if (fromDate && toDate) {
      handleSearch(fromDate, toDate);
    }
  }, []);

  const handleClickOutside = (event) => {
    if (node.current && !node.current.contains(event.target)) {
      setDatePickerOpen(false);
    }
  };

  useEffect(() => {
    if (datePickerOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [datePickerOpen]);

  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        Cell: ({ row, rows }) => {
          return (
            <div className="table-body">
              {rows.length - row.index}
            </div>
          );
        },
      },

      {
        Header: "Description",
        accessor: "Description",
        filterable: true,
        Cell: cellProps => {
          return (
            <div  className="table-cell text-center">
              <Description {...cellProps} />

            </div>
          );

        },
      },

      {
        Header: "Inventory Date",
        accessor: "transactionDate",
        filterable: true,
        Cell: cellProps => {
          return (
            <div  className="table-cell text-center">
              <InventoryDates {...cellProps} />
            </div>
          );

        },
      },

      {
        Header: "Inventory Cash",
        accessor: "inventoryCash",
        filterable: true,
        Cell: cellProps => {
          return (
            <div  className="table-cell text-center">
              {cellProps.value}
              {/* <InventoryCash {...cellProps.value} /> */}
            </div>
          );
        },
      },

      {
        Header: "Remarks",
        accessor: "remarks",
        filterable: true,
        Cell: cellProps => {
          return (
            <div  className="table-cell text-center">
                <Remarks {...cellProps} />
            </div>
          );
        },
      },
    ],
  []);

  useEffect(() => {
    finalList();
  }, [])

  const finalList = () => {
    handleSearch(fromDate, toDate)
    getInventory(hid, dbName)
    .then((res) => {
      setInventoryStock(res.data.data);
    });
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleSearch = (startDate, endDate) => {
    if (!startDate || !endDate) return;
    const hotelId = hid.replace(/"/g, '');
    const formattedFromDate = formatDate(startDate);
    const formattedToDate = formatDate(endDate);
    getInventorySales(hotelId, formattedFromDate, formattedToDate , dbName)
        .then((res) => {
          setInventorySaleList(res.data.data);
      })
      .catch((err) => {
        console.error("Error fetching Inventory Sales data:", err);
      });
  };

  // const finalList = () => {
    // getInventorySales(hid, dbName)
    // .then((res) => {
    //   setInventorySaleList(res.data.data);
    // });
    // Get all the inventory stocks
    // getInventory(hid, dbName)
    // .then((res) => {
    //   setInventoryStock(res.data.data);
    // });
    // handleSearch(fromDate, toDate)
  // };

  const handleStockSelection = (selectedOption) => {
    setSelectedStock(selectedOption);
    if (selectedOption && selectedOption.label) {
      validation.setFieldValue('inventoryType', selectedOption.label);
      setAvailableStock(selectedOption.value);
    } else {
      validation.setFieldValue('inventoryType', '');
      setSelectedStock([]);
    }
  };

  const clearFormValues = () => {
    validation.resetForm();
  };

  const toggle = () => {
    if (modal) {
      setModal(false);
      setSelectedStock(null);
      clearFormValues();
    } else {
      setModal(true);
    }
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const [deleteModal, setDeleteModal] = useState(false);

  const handleDeleteUser = () => {
    deleteEbAccount(GensetAccount).then( finalList())
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleInventorySaleClick = () => {
    getInventory(hid, dbName)
    .then((res) => {
      setInventoryStock(res.data.data);
    });
    setIsEdit(false);
    toggle();
  };

  const validation = useFormik({
    initialValues: {

      transactionDate: transactionDate || new Date(),
      inventoryType: "",
      requiredQuantity: "",
      remarks: ""
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
        transactionDate: Yup.date().required("Please Select Date"),
        inventoryType: Yup.string().required("Please Select Inventory Type"),
        requiredQuantity: Yup.string().required("Please Enter Quantity"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        // const updateGensetAccountData = { ...GensetAccount, ...values };
        // updateGensetAccount(updateGensetAccountData)
        //   .then((res) => {
        //     setResponse("GensetAccount Details Updated Sucessfully")
        //     setAlertModal(true)
        //   })
        //   .catch((err) => {
        //     setResponse("Failed to Update GensetAccount Details")
        //     setErrorAlertModal(true)
        //   });
        // toggle();
      } else {
        dispatch(post_Inventry_Sale(values));
      }
    }
  });

  const post_Inventry_Sale = (values) => {
    const data = JSON.stringify({
      transactionDate: values.transactionDate,
      inventoryType: values.inventoryType,
      requiredQuantity: values.requiredQuantity,
      remarks:values.remarks,
      hotelId: hid,
      dbName: dbName
    });
    createInventorySale(data).then((res) => {
      if (res.data.success) {
        setResponse(messages.success.createdMessage(masterName))
        setAlertModal(true)
      }
    }).catch(err => {
      setResponse(messages.failed.createFailed(masterName))
      setErrorAlertModal(true)
    });
    toggle();
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <AlertModal
        show={alertModal}
        onOkClick={() => {
          finalList();
          setAlertModal(false);
        }}
        onCloseClick={() => setAlertModal(false)}
        res={response}
      />
      <ErrorAlertModal
       show={errorAlertModal}
       onOkClick={() =>{ finalList() ,setErrorAlertModal(false) }}
       onCloseClick={() => setErrorAlertModal(false)}
       res={response}
      />

      <div className="page-content">
      <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Accounts" breadcrumbItem="Manage Inventory Sales" />
          <div className="mb-3">
            <Row>
              <Col lg="6" xs="12" md="6">
                <div className="input-group">
                  <div className="input-group-text">
                    <span className="input-group-prepand">From - To</span>
                  </div>
                  <input
                    type="text"
                    value={formattedDateRange}
                    readOnly
                    onClick={handleInputClick}
                    placeholder="Select Date Range"
                    className="form-control"
                  />
                  <label className="input-group-text" htmlFor="inputGroupFile01">
                    <FontAwesomeIcon icon={faCalendarAlt} />
                  </label>
                </div>

              </Col>
              <Col lg="6" xs="6" md="4" className="mt-3 mt-md-0  text-md-left">
                <button
                  className="btn btn-success"
                  onClick={() => handleSearch(selectedDates[0].startDate, selectedDates[0].endDate)}
                >
                  <span className="fas fa-search" /> Search
                </button>
              </Col>
            </Row>
            {datePickerOpen && (
              <div ref={node} className="date-range-picker-dropdown mt-1">
                <DateRangePicker
                  onChange={handleDateRangeChange}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={selectedDates}
                  direction="horizontal"
                  className="date-range-picker-dropdown"

                />
              </div>
            )}
          </div>
          <Row className="mt-3">
            <Col lg="12">
                <TableContainer
                    columns={columns}
                    data={inventorySaleList}
                    isGlobalFilter={true}
                    isAddInventorySales={true}
                    handleInventorySaleClick={handleInventorySaleClick}
                    customPageSize={10}
                    className="custom-header-css"
                />

                  <Modal  isOpen={modal} toggle={toggle} backdrop="static"  keyboard={false}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Inventory Details" : "Add Inventory Details"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">Transaction Date</Label>
                              <DatePicker
                                name="transactionDate"
                                selected={transactionDate}
                                onChange={(date) => setStartDate(date)}
                                className="form-control"
                                dateFormat="d-MM-yyyy h:mm aa"
                                placeholderText="Select date and time"
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={1}
                                value={validation.values.transactionDate || ""}
                                invalid={
                                  validation.touched.transactionDate &&
                                  validation.errors.transactionDate
                                  ? true
                                  : false
                                }
                              />
                              {validation.touched.transactionDate &&
                                validation.errors.transactionDate ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.transactionDate}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">Select Inventory Type</Label>
                                <Select
                                  name="inventoryType"
                                  placeholder="Select Inventory"
                                  classNamePrefix="react-select"
                                  className={`react-select-containers ${validation.touched.inventoryType && validation.errors.inventoryType
                                    ? 'is-invalid'
                                    : ''
                                  }`}
                                  value={selectedStock || ""}
                                  onChange={handleStockSelection}
                                  options={inventoryStock.map((stocks) => ({
                                    label: stocks.name,
                                    value: stocks,
                                  }))}
                                  isClearable
                                  isSearchable
                                  styles={{
                                    menu: (provided) => ({
                                      ...provided,
                                      top: "100%",
                                      position: "absolute",
                                      width: "100%",
                                      zIndex: 999,
                                    }),
                                  }}
                                />
                                {validation.touched.inventoryType && validation.errors.inventoryType && (
                                  <FormFeedback type="invalid">{validation.errors.inventoryType}</FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col xs={6}>
                            <div className="mb-3">
                              <Label className="form-label">Available Quantity</Label>
                              <h4>{availableStock.availableQuantity || 0}</h4>
                            </div>
                          </Col>
                          <Col xs={6}>
                            <div className="mb-3">
                              <Label className="form-label">Cost</Label>
                              <h4>{availableStock.cost || 0}</h4>
                            </div>
                          </Col>

                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Required Quantity
                              </Label>
                              <Input
                                name="requiredQuantity"
                                type="number"
                                placeholder="Available Quantity"
                                onChange={validation.handleChange}
                                min="0"
                                // onBlur={handleBlur}
                                value={
                                  validation.values.requiredQuantity || ""
                                }
                                invalid={
                                  validation.touched.requiredQuantity &&
                                  validation.errors.requiredQuantity
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.requiredQuantity &&
                              validation.errors.requiredQuantity ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.requiredQuantity}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Remarks
                              </Label>
                              <Input
                                name="remarks"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.remarks || ""}
                                invalid={
                                  validation.touched.remarks &&
                                  validation.errors.remarks
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.remarks &&
                              validation.errors.remarks ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.remarks}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                          <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-primary save-user w-md me-3"
                              >
                                {!!isEdit ? "Update" : "Save"}
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary w-md "
                                onClick={toggle}
                              >
                                Cancel
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
export default withRouter(InventorySales);