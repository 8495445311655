import React, { useEffect, useRef, useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
// //Import Scrollbar
import SimpleBar from "simplebar-react";
import { getAllMenus } from "middleware/backent_helper"
// MetisMenu
import MetisMenu from "metismenujs";
import withRouter from "components/Common/withRouter";
import { Link } from "react-router-dom";
//i18n
import { withTranslation } from "react-i18next";

const SidebarContent = props => {
  const ref = useRef();
  const [menuDataList, setMenuDataList] = useState([]);

  const [role, setRole] = useState(
    localStorage.getItem('role')
  );
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const path = useLocation();
  const activeMenu = useCallback(() => {
    const pathName = path.pathname;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
    // GET_ALL_MENULIST();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
    activeMenu();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  // const GET_ALL_MENULIST = () => {
  //   getAllMenus()
  //     .then((res) => {
  //       setMenuDataList(res.data.data);
  //     });
  // }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {role == 1 && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-briefcase"></i>
                  <span>{props.t("Masters")}</span>
                </Link>
                <ul className="sub-menu">

                  <li>
                    <Link to="/modules">{props.t("Modules")}</Link>
                  </li>
                  <li>
                    <Link to="/menus_list">{props.t("Menus")}</Link>
                  </li>
                  <li>
                    <Link to="/plan">{props.t("Plan")}</Link>
                  </li>

                  <li>
                    <Link to="/hotel">{props.t("Hotel")}</Link>
                  </li>
                </ul>

              </li>
            )}

            {role == 1 && (
              <li>
                <Link to="/activity_log" className="has-arrow">
                  <i className="bx bx-history"></i>
                  <span>{props.t("Activity Log")}</span>
                </Link>
              </li>
            )}
            {role == 2 && (
              <>
                {/* DASHBOARD */}
                <li>
                  <Link to="/dashboard">
                    <i className="bx bx-home-alt text-primary"></i>
                    <span>{props.t("Dashboard")}</span>
                  </Link>
                </li>
                {/* BOOKINGS */}
                <li>
                  <Link to="/booking">
                    <i className="bx bx-calendar text-primary"></i>
                    <span>{props.t("Check In/ Out")}</span>
                  </Link>
                </li>
                {/* RESERVATIONS */}
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-calendar-plus text-primary"></i>
                    <span>{props.t("Reservations")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/add-reservation">{props.t("Reservation")}</Link>
                    </li>
                    {/* <li>
                      <Link to="/reservation-view">{props.t("Reservation List")}</Link>
                    </li> */}
                    <li>
                      <Link to="/reservation-view">{props.t("View Reservation")}</Link>
                    </li>
                  </ul>
                </li>
                {/* ACCOUNTS */}
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-wallet-alt text-primary"></i>
                    <span>{props.t("Accounts")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/inventorySalesMaster">{props.t("Inventory Sales")}</Link>
                    </li>
                    <li>
                      <Link to="/inventoryStock">{props.t("Inventory Stock")}</Link>
                    </li>
                    <li>
                      <Link to="/purchase_master">{props.t("Purchase")}</Link>
                    </li>
                  </ul>
                </li>
                {/* INCOME & EXPENSES */}
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-credit-card text-primary"></i>
                    <span>{props.t("Income and Expenses")}</span>
                  </Link>
                  <ul className="sub-menu">
                    {/* <li>
                      <Link to="/amountToOwner">{props.t("Amount To Owner")}</Link>
                    </li> */}
                    <li>
                      <Link to="/balanceReport">{props.t("Balance Report")}</Link>
                    </li>
                    <li>
                      <Link to="/incomeAndExpense">{props.t("Income And Expense")}</Link>
                    </li>
                    <li>
                      <Link to="/pettyCash">{props.t("Petty Cash")}</Link>
                    </li>
                    <li>
                      <Link to="/pettyCashOverallReport">{props.t("Petty Cash Overall Report")}</Link>
                    </li>
                    <li>
                      <Link to="/allRoomsSales">{props.t("All Rooms Sales")}</Link>
                    </li>
                  </ul>
                </li>
                {/* Laundry */}
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-basket text-primary"></i>
                    <span>{props.t("Laundry")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/laundry_out">{props.t("Laundry Out")}</Link>
                    </li>
                    <li>
                      <Link to="/laundry_in">{props.t("Laundry In")}</Link>
                    </li>
                  </ul>
                </li>
                {/* Property Settings */}
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-building-house text-primary"></i>
                    <span>{props.t("Property Settings")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/general-settings">{props.t("General Settings")}</Link>
                    </li>
                    <li>
                      <Link to="/edit-hotel">{props.t("Settings")}</Link>
                    </li>
                    <li>
                      <Link to="/print-design">{props.t("Print Design")}</Link>
                    </li>
                  </ul>
                </li>

                {/* Group checkin */}
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bxs-group text-primary"></i>
                    <span>{props.t("Group Booking")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/group-checkin">{props.t("Check In")}</Link>
                    </li>
                    <li>
                      <Link to="/group-checkout">{props.t("Check Out")}</Link>
                    </li>
                  </ul>
                </li>
                
                {/* SETTINGS */}
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bxs-cog text-primary"></i>
                    <span>{props.t("Settings")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/room_types">{props.t("Room Type")}</Link>
                    </li>
                    <li>
                      <Link to="/rooms">{props.t("Rooms")}</Link>
                    </li>
                    <li>
                      <Link to="/room-mapping">{props.t("Room Map")}</Link>
                    </li>
                    <li>
                      <Link to="/tax_master">{props.t("Taxes")}</Link>
                    </li>
                    <li>
                      <Link to="/room-service">{props.t("Room Service")}</Link>
                    </li>
                    <li>
                      <Link to="/assets_master">{props.t("Assets")}</Link>
                    </li>
                    <li>
                      <Link to="/category_master">{props.t("Category")}</Link>
                    </li>
                    <li>
                      <Link to="/identity_master">{props.t("Identity Details")}</Link>
                    </li>
                    <li>
                      <Link to="/payment_type">{props.t("Payment Type")}</Link>
                    </li>
                    <li>
                      <Link to="/online_portal_type_master">{props.t("Online Portal Types")}</Link>
                    </li>
                    {/* <li>
                      <Link to="/customer_master">{props.t("Customer")}</Link>
                    </li> */}
                  </ul>
                </li>
                {/* UTILITY */}
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-gas-pump text-primary"></i>
                    <span>{props.t("Utility")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/electricity_account_master">{props.t("Electricity Account ")}</Link>
                    </li>
                    <li>
                      <Link to="/genset_master">{props.t("Genset Details")}</Link>
                    </li>
                  </ul>
                </li>
                {/* Reports */}
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-file-blank text-primary"></i>
                    <span>{props.t("Reports")}</span>
                  </Link>
                  <ul className="sub-menu">
                    {/* Sales Related Reports */}
                    <li>
                      <Link to="/#" className="has-arrow">
                        <span>{props.t("Sales Reports")}</span>
                      </Link>
                      <ul className="sub-menu">
                        <li>
                          <Link to="/inventorySalesReport">
                            {props.t("Inventory Sales Report")}
                          </Link>
                        </li>
                      </ul>
                    </li>
                    {/* Guest Related Reports */}
                    <li>
                      <Link to="/#" className="has-arrow">
                        <span>{props.t("Guest Reports")}</span>
                      </Link>
                      <ul className="sub-menu">
                        <li>
                          <Link to="/guestArrival">{props.t("Guest Arrival Summary")}</Link>
                        </li>
                        <li>
                          <Link to="/guestReport">{props.t("Guest")}</Link>
                        </li>
                        <li>
                          <Link to="/roomWiseGuest">{props.t("Room Wise Guest")}</Link>
                        </li>
                      </ul>
                    </li>
                    {/* Income Related Reports */}
                    <li>
                      <Link to="/#" className="has-arrow">
                        <span>{props.t("Income Reports")}</span>
                      </Link>
                      <ul className="sub-menu">
                        <li>
                          <Link to="/incomeAndExpenseReport">
                            {props.t("Income & Expense Report")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/amountToOwnerReport">
                            {props.t("Amount To Owner")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/incomeAndExpenseRoom">
                            {props.t("Income & Expense Room")}
                          </Link>
                        </li>
                      </ul>
                    </li>
                    {/* Bookings Report */}
                    {/* Other Reports */}
                    <li>
                      <Link to="/#" className="has-arrow">
                        <span>{props.t("Bookings Reports")}</span>
                      </Link>
                      <ul className="sub-menu">
                        <li>
                          <Link to="/checkInReport">{props.t("CheckIn Report")}</Link>
                        </li>
                        <li>
                          <Link to="/checkOutReport">{props.t("CheckOut Report")}</Link>
                        </li>
                        <li>
                          <Link to="/advanceBooking">{props.t("Advance Booking")}</Link>
                        </li>
                        <li>
                          <Link to="/cancelBooking">{props.t("Cancelled Booking")}</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/#" className="has-arrow">
                        <span>{props.t("Other Reports")}</span>
                      </Link>
                      <ul className="sub-menu">
                        <li>
                          <Link to="/houseKeeping">{props.t("House Keeping")}</Link>
                        </li>
                        <li>
                          <Link to="/gstReport">{props.t("GST Report")}</Link>
                        </li>
                        <li>
                          <Link to="/misCategoryReport">
                            {props.t("MisCategory Report")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/policeReport">{props.t("Manage Police Report")}</Link>
                        </li>
                        <li>
                          <Link to="/overAllAdmin">
                            {props.t("Over All Admin Report")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/inventoryPurchase">
                            {props.t("Inventory Purchase")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/nightAudit">{props.t("Night Audit")}</Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
