import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom"
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody, FormGroup, Label, Input, FormFeedback, InputGroup, Form, CardTitle, Alert, Button
} from "reactstrap";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from "react-select";
import { getCountries, getStates, getGuestDetils, getCities, getIdentity, getPaymentTypes, post_ExtraGuest_Details, getRoomsDetils, roomTypeRead, getWithoutCheckinRooms } from "../../../middleware/backent_helper";
import AlertModal from "../../../components/Common/alertModal";
import ErrorAlertModal from "../../../components/Common/errorAlertModal";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import messages from '../../../utils/constants/messages';
//i18n
import { withTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { addDays } from 'date-fns';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

const Create_Group_Checkin = props => {
    document.title = "StayPro | Group Checkin"

    const [hid, setHid] = useState(() => {
        const storedHotelId = localStorage.getItem('hotelId')
        return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
    });
    const [dbName, setDbName] = useState(
        localStorage.getItem('dbName')
    );

    const [checkInDate, setCheckInDate] = useState(new Date());
    const [checkOutDate, setCheckOutDate] = useState(addDays(new Date(), 1));
    const [customers, setCustomers] = useState([]);
    const [showSideBar, setShowSideBar] = useState(false);
    const [subscribemodal, setSubscribemodal] = useState(false);
    const [countriesList, setCountries] = useState([]);
    const [proofTypes, setProofTypes] = useState([]);
    const [proofImages, setproofImages] = useState([]);
    const [selectedProofType, setselectedProofType] = useState('');
    const [selectedPayment, setselectedPayment] = useState('');
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [statesList, setStatesList] = useState([]);
    const [selectedState, setSelectedState] = useState('');
    const [getstateKey, setstateKey] = useState([])
    const [citiesList, setCitiesList] = useState([]);
    const [selectedCities, setSelectedCities] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [imageDeletion, setImageDeletion] = useState([]);
    const [alertModal, setAlertModal] = useState(false);
    const [errorAlertModal, setErrorAlertModal] = useState(false);
    const [response, setResponse] = useState(false);
    const [roomTypeList, setroomTypeList] = useState([]);
    const [selectedRoomType, setSelectedRoomType] = useState(null);
    const [paymentTypeList, setPaymentTypes] = useState([]);
    const [selectedGender, setSelectedGender] = useState('');
    const [masterName, setMasterName] = useState("Extra Guest");
    const [GuestDetails, setGuestDetails] = useState([]);
    const [selectedNationality, setSelectedNationality] = useState('Indian');
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const bookingId = queryParams.get('bookingId');
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [rooms, setRooms] = useState('');
    const [Guest_Tariff, setGuest_Tariff] = useState('');
    //Available Room list
    const [RoomsTypeList, setRoomsTypeList] = useState([]);
    const [RoomsList, setRoomsList] = useState([]);

    //Available RoomTypes &rooms
    useEffect(() => {
        // Get Room-Types
        roomTypeRead(hid, dbName)
            .then((res) => {

                setRoomsTypeList(res.data.data);
            });
        // Get Rooms
        getWithoutCheckinRooms(hid, dbName)
            .then((res) => {

                setRoomsList(res.data.data);
            });
    }, []);

    const handleCountryChange = (selectedOption) => {
        setSelectedCountry(selectedOption);
        if (selectedOption && selectedOption.label) {
            validation.setFieldValue('country', selectedOption.label);
        } else {
            validation.setFieldValue('country', '');
        }
    };

    const Get_Guest_Details = () => {
        getGuestDetils(hid, dbName, bookingId)
            .then((res) => {
                setGuestDetails(res.data.data);
                Get_Room_Details(res.data.data.roomId);
            });
    };

    const Get_Room_Details = (roomId) => {
        if (roomId != null) {
            getRoomsDetils(hid, dbName, roomId)
                .then((res) => {
                    setRooms(res.data.data);
                    console.log(res.data.data);
                    handleTariffFunction(res.data.data); // Pass the room details to handleTariffFunction
                });
        }
    };

    const extractMinTariff = (tariffRange) => {
        if (!tariffRange) return 0;
        const rangeParts = tariffRange.split('-').map(part => parseInt(part.replace(/[^0-9]/g, ""), 10)); // Extract numbers from the string
        return rangeParts[0]; // Return the first value, which is the minimum
    };

    const handleTariffFunction = (roomDetails) => {
        const extraPersonTariff = extractMinTariff(roomDetails?.extraPersonTariff);
        setGuest_Tariff(extraPersonTariff);
        console.log(extraPersonTariff);
    };

    useEffect(() => {
        Get_Guest_Details();
    }, []);

    const formatDate = (dateTime) => {
        const date = new Date(dateTime);
        if (isNaN(date)) {
            throw new Error("Invalid Date");
        }

        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        const hours = ('0' + date.getHours()).slice(-2);
        const minutes = ('0' + date.getMinutes()).slice(-2);
        const seconds = ('0' + date.getSeconds()).slice(-2);
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    // Fetch city when a state is selected
    useEffect(() => {
        if (selectedState && selectedState.label) {
            const fetchCities = async () => {
                try {
                    const stateKey = getStateKey(selectedState);
                    setstateKey(stateKey)

                    getCities(stateKey)
                        .then((res) => {
                            setCitiesList(res.data.data);
                            if (customers && customers.city) {
                                const foundCity = res.data.data.find(city => city.name === customers.city);
                                if (foundCity) {
                                    setSelectedCities({ label: foundCity.name, value: foundCity });
                                    validation.setFieldValue('city', foundCity.name);
                                }
                            }
                        })
                } catch (error) {
                    console.error('Error fetching city:', error);
                }
            };
            fetchCities();
        }
    }, [selectedState]);

    useEffect(() => {
        if (customers) {
            setproofImages(customers.proof_photo)
        }
        getIdentity(hid, dbName)
            .then((res) => {
                setProofTypes(res.data.data)
            })

        getPaymentTypes(hid, dbName)
            .then((res) => {
                setPaymentTypes(res.data.data);
            });

        if (selectedCountry != null) {
            const fetchStates = async () => {
                try {
                    const stateKey = getCountryKey(selectedCountry);
                    getStates(stateKey).then((res) => {
                        setStatesList(res.data.data);
                        if (customers && customers.state) {
                            const foundState = res.data.data.find(state => state.name === customers.state);
                            if (foundState) {
                                setSelectedState({ label: foundState.name, value: foundState });
                                validation.setFieldValue('state', foundState.name);
                            }
                        }
                    });
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
            fetchStates();
        }
    }, [selectedCountry, customers])

    const getStateKey = (stateName) => {
        const state = statesList.find(s => s.name === stateName.label);
        return state ? state.name : null;
    };

    const getCountryKey = (CountryKey) => {
        const state = countriesList.find(s => s.name === CountryKey.label);
        return state ? state.iso2 : null;
    }


    const handleStateChange = (selectedOption) => {
        setSelectedState(selectedOption)
        if (selectedOption && selectedOption.label) {
            validation.setFieldValue('state', selectedOption.label);
        } else {
            validation.setFieldValue('state', '');
        }
    }

    const handleCityChange = (selectedOption) => {
        setSelectedCities(selectedOption)
        if (selectedOption && selectedOption.label) {
            validation.setFieldValue('city', selectedOption.label);
        } else {
            validation.setFieldValue('city', '');
        }
    }

    const genderOptions = [
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' }
    ];

    const NationalityOptions = [
        { value: 'Indian', label: 'Indian' },
        { value: 'Others', label: 'Others' }
    ];

    const handleProofTypeChange = selectedOption => {
        setselectedProofType(selectedOption)
        validation.setFieldValue('proofType', selectedOption ? selectedOption.value : '');

    }

    const handlePaymentTypeChange = selectedOption => {
        setselectedPayment(selectedOption)
        validation.setFieldValue('payment', selectedOption ? selectedOption.value : '');

    }


    useEffect(() => {
        getCountries()
            .then((res) => {
                setCountries(res.data.data)

                if (customers && customers.country) {
                    const foundCountry = res.data.data.find(country => country.name === customers.country);
                    if (foundCountry) {
                        setSelectedCountry({ label: foundCountry.name, value: foundCountry });
                        validation.setFieldValue('country', foundCountry.name);
                    }
                }
            })
    }, [])


    const handleGenderChange = selectedOption => {
        setSelectedGender(selectedOption);
        validation.setFieldValue('gender', selectedOption ? selectedOption.value : '');
    };

    const handleNationality = selectedOption => {
        setSelectedNationality(selectedOption);
        validation.setFieldValue('nationality', selectedOption ? selectedOption.value : '');
    };

    const handleCancelButton = () => {
        setSelectedRoomTypeId(null);
        setSubmittedCount(0);
        setShowForm(false)
    };

    const clearFormValues = () => {
        validation.resetForm()
    }

    const validation = useFormik({
        initialValues: {
            guest_name: "",
            mobile_no: "",
            gender: "",
            nationality: "Indian",
            proof_type: "",
            country: "",
            state: "",
            city: "",
            proofType: "",
            gender: "",
            vehicle_no: "",
            address: "",
            email: "",
            company_name: "",
            pincode: "",
            age: "",
            payment: "",
            advance: ""
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            guest_name: Yup.string().required("Please Enter Your Name"),
            age: Yup.string().required("Please Enter Your Age"),
            mobile_no: Yup.string().required("Please Enter Your Mobile No"),
            proofType: Yup.string().required('proof Type type is required'),
            payment: Yup.string().required('payment type is required'),
            advance: Yup.string().required("Please Enter Your Advance Amount"),
            gender: Yup.string().required("Gender is required"),
        }),
        onSubmit: (values) => {
            post_Extra_Guest_Information(values)
            validation.resetForm();
        }
    });

    const post_Extra_Guest_Information = (values) => {

        const data = JSON.stringify({

            GuestName: values.guest_name,
            mobileNo: values.mobile_no,
            gender: values.gender,
            address: values.address,
            country: values.country,
            state: values.state,
            city: values.city,
            pincode: values.pincode,
            nationality: values.selectedNationality,
            age: values.age,
            email: values.email,
            IdentitiesId: values.proofType,
            identityNo: values.proof_no,
            vehicleNo: values.vehicle_no,
            companyName: values.company_name,
            checkInDate: formatDate(fromDate),
            checkOutDate: formatDate(toDate),
            advanceAmount: values.advance,
            PaymentTypeId: values.payment,
            remarks: values.remarks,
            hotelId: hid,
            dbName: dbName,
            bookingId: bookingId,
            tariff: Guest_Tariff,
            roomId: GuestDetails.roomId,
            description: "Room Advance for " + GuestDetails.roomNo,
        });

        post_ExtraGuest_Details(data).then((res) => {
            if (res.data.success) {
                setResponse(messages.success.createdMessage(masterName))
                setAlertModal(true);
            }
        }).catch(err => {
            if (err.response.status === 500) {
                setResponse(messages.failed.createFailed())
            } setErrorAlertModal(true)
        });
        clearFields();
    }

    const clearFields = () => {
        validation.resetForm();
        setselectedPayment('');
        setselectedProofType('');
        setSelectedCountry('');
        setSelectedCities('');
        setSelectedState('');
        setSelectedGender('');
    };

    const handleRoomTypeClick = (roomType) => {
        setSelectedRoomType(roomType);
        console.log(roomType);
    };

    const filteredRooms = selectedRoomType
        ? RoomsList.filter((room) => room.title === selectedRoomType)
        : RoomsList;

    const [formRows, setFormRows] = useState([]);

    // Add new row with the selected room number
    const onAddFormRow = (roomNo) => {
        if (!roomNo) {
            console.error('Room number is null or undefined');
            return; // Do not add a row if room number is invalid
        }

        const newId = formRows.length > 0 ? formRows[formRows.length - 1].id + 1 : 1;
        const modifiedRows = [...formRows, { id: newId, roomNo, children: 0 }]; // Push roomNo for each row
        setFormRows(modifiedRows);
    };

    // Delete row by id
    const onDeleteFormRow = (id) => {
        if (id !== 1) {
            const modifiedRows = formRows.filter(row => row.id !== id);
            setFormRows(modifiedRows);
        }
    };

    // Handle change for child input count
    const onChildInputChange = (id, childCount) => {
        const modifiedRows = formRows.map(row =>
            row.id === id ? { ...row, children: childCount } : row
        );
        setFormRows(modifiedRows);
    };

    return (
        <React.Fragment>
            <AlertModal
                show={alertModal}
                onOkClick={() => {
                    Get_Guest_Details(), setAlertModal(false);
                }}
                onCloseClick={() => setAlertModal(false)}
                res={response}
            />
            <ErrorAlertModal
                show={errorAlertModal}
                onOkClick={() => { Get_Guest_Details(), setErrorAlertModal(false) }}
                onCloseClick={() => setErrorAlertModal(false)}
                res={response}
            />

            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs breadcrumbItem={props.t("Group-CheckIn")} />
                    <Row>
                        <Col lg="6">
                            <Card style={{ border: '1px solid rgb(210 212 218)', borderRadius: '8px' }}>
                                <CardHeader>
                                    <h5 className="card-title">{props.t("Available Room Type Details")}</h5>
                                </CardHeader>
                                <CardBody>
                                    {RoomsTypeList && RoomsTypeList.map((roomType, typeIndex) => (

                                        <Alert color="primary">
                                            <i className="bx bx-check-circle"></i>
                                            <a
                                                color="link"
                                                className="btn btn-link waves-effect"
                                                onClick={() => handleRoomTypeClick(roomType.title)}
                                            >

                                                {roomType.title.toUpperCase()} - <span style={{ color: "black" }}>{roomType.normalTariff}</span>
                                            </a>
                                        </Alert>
                                    ))}

                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="6">
                            <Card style={{ border: '1px solid rgb(210 212 218)', borderRadius: '8px' }}>
                                <CardHeader>
                                    <h5 className="card-title">{props.t("Room No Details")}</h5>
                                </CardHeader>
                                <CardBody>
                                    <div className="d-flex flex-wrap gap-2">
                                        {filteredRooms.map((room, key) => (
                                            <Button
                                                key={key}
                                                type="button"
                                                color="info"
                                                className="btn btn-primary waves-effect waves-light"
                                                onClick={() => onAddFormRow(room.roomNo)}
                                            >
                                                <i className="bx bx-home-circle font-size-15 align-middle me-2"></i>{" "}
                                                {room.roomNo}
                                            </Button>

                                        ))}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row >
                        <Col xs={12}>
                            <Card style={{ border: '1px solid rgb(210 212 218)', borderRadius: '8px' }}>
                                <CardHeader>
                                    <h5 className="card-title">{props.t("Assigned Rooms")}</h5>
                                </CardHeader>
                                <CardBody>
                                    <Form className="repeater" encType="multipart/form-data">
                                        <div>
                                            {formRows.length === 0 && (
                                                <p style={{ color: '#f46a6a' }}>No rooms selected yet. Please select a room number to add inputs.</p>
                                            )}
                                            {formRows.map((formRow, key) => (
                                                <Row key={key}>
                                                    {/* Display Room Number */}
                                                    <Col lg={1}>
                                                        <div className="d-grid">
                                                            <Button
                                                                type="button"
                                                                className="btn btn-info"
                                                            >
                                                                {formRow.roomNo ? formRow.roomNo : 'Room No'}
                                                            </Button>
                                                        </div>
                                                    </Col>

                                                    {/* Male Input */}
                                                    <Col lg={2} className="mb-3">
                                                        <div className="col-sm-auto">
                                                            <Label className="visually-hidden" htmlFor="maleInput">Male</Label>
                                                            <InputGroup>
                                                                <div className="input-group-text"><i className="bx bx-male"></i></div>
                                                                <input type="number" className="form-control" id="maleInput" placeholder="Male" />
                                                            </InputGroup>
                                                        </div>
                                                    </Col>

                                                    {/* Female Input */}
                                                    <Col lg={2} className="mb-3">
                                                        <div className="col-sm-auto">
                                                            <Label className="visually-hidden" htmlFor="femaleInput">Female</Label>
                                                            <InputGroup>
                                                                <div className="input-group-text"><i className="bx bx-female"></i></div>
                                                                <input type="number" className="form-control" id="femaleInput" placeholder="Female" />
                                                            </InputGroup>
                                                        </div>
                                                    </Col>

                                                    {/* Child Input */}
                                                    <Col lg={2} className="mb-3">
                                                        <div className="col-sm-auto">
                                                            <Label className="visually-hidden" htmlFor="childInput">Child</Label>
                                                            <InputGroup>
                                                                <div className="input-group-text"><i className="bx bxs-baby-carriage"></i></div>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    id="childInput"
                                                                    placeholder="Child"
                                                                    value={formRow.children}
                                                                    onChange={(e) => onChildInputChange(formRow.id, parseInt(e.target.value) || 0)}
                                                                />
                                                            </InputGroup>
                                                        </div>
                                                    </Col>

                                                    {/* Dynamic Child Age Inputs */}
                                                    {Array.from({ length: formRow.children }).map((_, childIndex) => (
                                                        <Col lg={1} key={childIndex} className="mb-3">
                                                            <div className="col-sm-auto">
                                                                <Label className="visually-hidden" htmlFor={`childAgeInput${formRow.id}_${childIndex}`}>Child Age</Label>
                                                                <InputGroup>
                                                                    <div className="input-group-text"><i className="bx bxs-face"></i></div>
                                                                    <input
                                                                        type="number"
                                                                        className="form-control"
                                                                        id={`childAgeInput${formRow.id}_${childIndex}`}
                                                                        placeholder={`${childIndex + 1}`}
                                                                    />
                                                                </InputGroup>
                                                            </div>
                                                        </Col>
                                                    ))}

                                                    {/* Delete Button */}
                                                    <Col lg={1} className="mb-3">
                                                        <div className="d-grid">
                                                            <input
                                                                type="button"
                                                                className="btn btn-primary"
                                                                value="Delete"
                                                                onClick={() => onDeleteFormRow(formRow.id)}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            ))}
                                        </div>
                                    </Form>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}
                        >

                            <Row>
                                <Col xl="12">
                                    <Card style={{ border: '1px solid rgb(210 212 218)', borderRadius: '8px' }}>
                                        <CardHeader>
                                            <h5 className="card-title">{props.t("Guest Registration Form")}</h5>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <FormGroup>
                                                            <Label className="form-label">Mobile No</Label>
                                                            <Input
                                                                name="mobile_no"
                                                                type="text"
                                                                placeholder="MobileNo"
                                                                onChange={(e) => {
                                                                    const re = /^[0-9\b]+$/;
                                                                    if (e.target.value === '' || re.test(e.target.value)) {
                                                                        validation.handleChange(e);
                                                                    }
                                                                }}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.mobile_no || ""}
                                                                invalid={
                                                                    validation.touched.mobile_no &&
                                                                        validation.errors.mobile_no
                                                                        ? true
                                                                        : false
                                                                }
                                                                maxLength={10}
                                                            />
                                                            {validation.touched.mobile_no &&
                                                                validation.errors.mobile_no ? (
                                                                <FormFeedback type="invalid">
                                                                    {validation.errors.mobile_no}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <FormGroup>
                                                            <Label className="form-label">Guest Name</Label>
                                                            <Input
                                                                name="guest_name"
                                                                type="text"
                                                                placeholder="Name"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.guest_name || ""}
                                                                invalid={
                                                                    validation.touched.guest_name &&
                                                                        validation.errors.guest_name
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            {validation.touched.guest_name &&
                                                                validation.errors.guest_name ? (
                                                                <FormFeedback type="invalid">
                                                                    {validation.errors.guest_name}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <FormGroup>
                                                            <Label className="form-label">Email</Label>
                                                            <Input
                                                                name="email"
                                                                type="text"
                                                                placeholder="Email"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.email || ""}
                                                                invalid={
                                                                    validation.touched.email &&
                                                                        validation.errors.email
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            {validation.touched.email &&
                                                                validation.errors.email ? (
                                                                <FormFeedback type="invalid">
                                                                    {validation.errors.email}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </FormGroup>
                                                    </div>

                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <FormGroup>
                                                            <Label className="form-label">Gender</Label>
                                                            <Select
                                                                name="gender"
                                                                // value={selectedGender}
                                                                value={genderOptions.find(option => option.value === selectedGender)}
                                                                onChange={handleGenderChange}
                                                                placeholder="Select Gender"
                                                                classNamePrefix="react-select"
                                                                className={`react-select-containers ${validation.touched.gender && validation.errors.gender
                                                                    ? 'is-invalid'
                                                                    : ''
                                                                    }`}
                                                                options={genderOptions.map((gender) => ({
                                                                    label: gender.label,
                                                                    value: gender.value,
                                                                }))}

                                                                styles={{
                                                                    menu: (provided) => ({
                                                                        ...provided,
                                                                        top: "100%",
                                                                        position: "absolute",
                                                                        width: "100%",
                                                                        zIndex: 999,
                                                                    }),
                                                                }}
                                                            />
                                                            {validation.touched.gender && validation.errors.gender ? (
                                                                <FormFeedback type="invalid">
                                                                    {validation.errors.gender}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <FormGroup>
                                                            <Label className="form-label">Age</Label>
                                                            <Input
                                                                name="age"
                                                                type="text"
                                                                placeholder="Age"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.age || ""}
                                                                invalid={
                                                                    validation.touched.age &&
                                                                        validation.errors.age
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            {validation.touched.age &&
                                                                validation.errors.age ? (
                                                                <FormFeedback type="invalid">
                                                                    {validation.errors.age}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </FormGroup>
                                                    </div>

                                                </Col>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <FormGroup>
                                                            <Label className="form-label">Address</Label>
                                                            <Input
                                                                name="address"
                                                                type="text"
                                                                placeholder="Address"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.address || ""}
                                                                invalid={
                                                                    validation.touched.address &&
                                                                        validation.errors.address
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            {validation.touched.address &&
                                                                validation.errors.address ? (
                                                                <FormFeedback type="invalid">
                                                                    {validation.errors.address}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <FormGroup>
                                                            <Label className="form-label">Country</Label>
                                                            <Select
                                                                name="country"
                                                                placeholder="Select Country"
                                                                classNamePrefix="react-select"
                                                                className={`react-select-container ${validation.touched.country && validation.errors.country
                                                                    ? 'is-invalid'
                                                                    : ''
                                                                    }`}
                                                                options={countriesList.map((country) => ({
                                                                    label: country.name,
                                                                    value: country,
                                                                }))}
                                                                value={selectedCountry}
                                                                onChange={handleCountryChange}
                                                                isClearable
                                                                isSearchable
                                                                styles={{
                                                                    menu: (provided) => ({
                                                                        ...provided,
                                                                        top: "100%",
                                                                        position: "absolute",
                                                                        width: "100%",
                                                                        zIndex: 999,
                                                                    }),
                                                                }}
                                                            />
                                                            {validation.touched.country &&
                                                                validation.errors.country ? (
                                                                <FormFeedback type="invalid">
                                                                    {validation.errors.country}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <FormGroup>
                                                            <Label className="form-label">State</Label>
                                                            <Select
                                                                name="state"
                                                                placeholder="Select State"
                                                                classNamePrefix="react-select"
                                                                className={`react-select-container ${validation.touched.state && validation.errors.state
                                                                    ? 'is-invalid'
                                                                    : ''
                                                                    }`}
                                                                value={selectedState}
                                                                onChange={handleStateChange}
                                                                options={statesList.map((state) => ({
                                                                    label: state.name,
                                                                    value: state
                                                                }))}
                                                                isClearable
                                                                isSearchable
                                                                styles={{
                                                                    menu: (provided) => ({
                                                                        ...provided,
                                                                        top: "100%",
                                                                        position: "absolute",
                                                                        width: "100%",
                                                                        zIndex: 999,
                                                                    }),
                                                                }}
                                                            />
                                                            {validation.touched.state &&
                                                                validation.errors.state ? (
                                                                <FormFeedback type="invalid">
                                                                    {validation.errors.state}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </FormGroup>
                                                    </div>

                                                </Col>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <FormGroup>
                                                            <Label className="form-label">City</Label>
                                                            <Select
                                                                name="city"
                                                                placeholder="Select City"
                                                                className={`react-select-containe ${validation.touched.city && validation.errors.city
                                                                    ? 'is-invalid'
                                                                    : ''
                                                                    }`}
                                                                value={selectedCities}
                                                                onChange={handleCityChange}
                                                                options={citiesList.map((city) => ({
                                                                    label: city.name,
                                                                    value: city
                                                                }))}
                                                                isClearable
                                                                isSearchable
                                                                styles={{
                                                                    menu: (provided) => ({
                                                                        ...provided,
                                                                        top: "100%",
                                                                        position: "absolute",
                                                                        width: "100%",
                                                                        zIndex: 999,
                                                                    }),
                                                                }}
                                                            />
                                                            {validation.touched.city &&
                                                                validation.errors.city ? (
                                                                <FormFeedback type="invalid">
                                                                    {validation.errors.city}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <FormGroup>
                                                            <Label className="form-label">Nationality</Label>
                                                            <Select
                                                                name="nationality"
                                                                // value={selectedNationality}
                                                                value={NationalityOptions.find(option => option.value === selectedNationality)}
                                                                onChange={handleNationality}
                                                                placeholder="Select Nationality"
                                                                classNamePrefix="react-select"
                                                                className={`react-select-container ${validation.touched.nationality && validation.errors.nationality
                                                                    ? 'is-invalid'
                                                                    : ''
                                                                    }`}
                                                                options={NationalityOptions.map((nationality) => ({
                                                                    label: nationality.label,
                                                                    value: nationality.value,
                                                                }))}

                                                                styles={{
                                                                    menu: (provided) => ({
                                                                        ...provided,
                                                                        top: "100%",
                                                                        position: "absolute",
                                                                        width: "100%",
                                                                        zIndex: 999,
                                                                    }),
                                                                }}
                                                            />
                                                            {validation.touched.nationality && validation.errors.nationality ? (
                                                                <FormFeedback type="invalid">
                                                                    {validation.errors.nationality}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <FormGroup>
                                                            <Label className="form-label">Pin Code</Label>
                                                            <Input
                                                                name="pincode"
                                                                type="text"
                                                                placeholder="Pin Code"
                                                                onChange={(e) => {
                                                                    const re = /^[0-9\b]+$/;
                                                                    if (e.target.value === '' || re.test(e.target.value)) {
                                                                        validation.handleChange(e);
                                                                    }
                                                                }}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.pincode || ""}
                                                                invalid={
                                                                    validation.touched.pincode &&
                                                                        validation.errors.pincode
                                                                        ? true
                                                                        : false
                                                                }
                                                                maxLength={6}
                                                            />
                                                            {validation.touched.pincode &&
                                                                validation.errors.pincode ? (
                                                                <FormFeedback type="invalid">
                                                                    {validation.errors.pincode}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <FormGroup>
                                                            <Label className="form-label">Company Name</Label>
                                                            <Input
                                                                name="company_name"
                                                                type="text"
                                                                placeholder="Company Name"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.company_name || ""}
                                                                invalid={
                                                                    validation.touched.company_name &&
                                                                        validation.errors.company_name
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            {validation.touched.company_name &&
                                                                validation.errors.company_name ? (
                                                                <FormFeedback type="invalid">
                                                                    {validation.errors.company_name}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <FormGroup>
                                                            <Label className="form-label">Vehicle No.</Label>
                                                            <Input
                                                                name="vehicle_no"
                                                                type="text"
                                                                placeholder="Vehicle No"
                                                                onChange={(e) => {
                                                                    e.target.value = e.target.value.toUpperCase(); // Convert to uppercase
                                                                    validation.handleChange(e); // Handle the change with Formik
                                                                }}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.vehicle_no || ""}
                                                                invalid={
                                                                    validation.touched.vehicle_no &&
                                                                        validation.errors.vehicle_no
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            {validation.touched.vehicle_no &&
                                                                validation.errors.vehicle_no ? (
                                                                <FormFeedback type="invalid">
                                                                    {validation.errors.vehicle_no}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <FormGroup>
                                                            <Label className="form-label">Proof Type</Label>
                                                            <Select
                                                                name="proofType"
                                                                placeholder="Select Proof Type"
                                                                classNamePrefix="react-select"
                                                                className={`react-select-containers ${validation.touched.proofType && validation.errors.proofType
                                                                    ? 'is-invalid'
                                                                    : ''
                                                                    }`}
                                                                options={proofTypes.map((proof) => ({
                                                                    label: proof.identityName,
                                                                    value: proof.id,
                                                                }))}
                                                                value={selectedProofType}
                                                                onChange={handleProofTypeChange}

                                                                styles={{
                                                                    menu: (provided) => ({
                                                                        ...provided,
                                                                        top: "100%",
                                                                        position: "absolute",
                                                                        width: "100%",
                                                                        zIndex: 999,
                                                                    }),
                                                                }}
                                                            />
                                                            {validation.touched.proofType &&
                                                                validation.errors.proofType ? (
                                                                <FormFeedback type="invalid">
                                                                    {validation.errors.proofType}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <FormGroup>
                                                            <Label className="form-label">Proof No.</Label>
                                                            <Input
                                                                name="proof_no"
                                                                type="text"
                                                                placeholder={"Proof Number"}
                                                                onChange={(e) => {
                                                                    e.target.value = e.target.value.toUpperCase(); // Convert to uppercase
                                                                    validation.handleChange(e); // Handle the change with Formik
                                                                }}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.proof_no || ""}
                                                                invalid={
                                                                    validation.touched.proof_no &&
                                                                        validation.errors.proof_no
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            {validation.touched.proof_no &&
                                                                validation.errors.proof_no ? (
                                                                <FormFeedback type="invalid">
                                                                    {validation.errors.proof_no}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12}>
                                                    <div className="mb-3">
                                                        <FormGroup>
                                                            <Label className="form-label">Upload Image</Label>
                                                            <Input
                                                                name="proof_photo"
                                                                type="file"
                                                                accept="image/*"
                                                                className={`form-control`}
                                                                //   onChange={handleFileChange}
                                                                multiple
                                                            />
                                                            {validation.touched.proof_photo &&
                                                                validation.errors.proof_photo ? (
                                                                <FormFeedback type="invalid">
                                                                    {validation.errors.proof_photo}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl="12">
                                    <Card style={{ border: '1px solid rgb(210 212 218)', borderRadius: '8px' }}>
                                        <CardHeader>
                                            <h5 className="card-title">{props.t("Check In Info")}</h5>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col lg={4}>
                                                    <div className="mb-3 docs-datepicker">
                                                        <Label className="form-label">
                                                            Check-In Date
                                                        </Label>
                                                        <InputGroup>
                                                            <Flatpickr
                                                                name="checkInDate"
                                                                selected={checkInDate}
                                                                onChange={(date) => setCheckInDate(date)}
                                                                className="form-control d-block"
                                                                dateFormat="d-MM-yyyy h:mm aa"
                                                                placeholderText="Select date and time"
                                                                value={validation.values.checkInDate || ""}
                                                                invalid={
                                                                    validation.touched.checkInDate &&
                                                                        validation.errors.checkInDate
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            <label className="input-group-text" htmlFor="inputGroupFile01">
                                                                <FontAwesomeIcon icon={faCalendarAlt} />
                                                            </label>
                                                        </InputGroup>
                                                        {validation.touched.checkInDate &&
                                                            validation.errors.checkInDate ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.checkInDate}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="mb-3 docs-datepicker">
                                                        <Label className="form-label">
                                                            Expected Check-Out Date
                                                        </Label>
                                                        <InputGroup>
                                                            <Flatpickr
                                                                name="checkOutDate"
                                                                selected={checkOutDate}
                                                                onChange={(date) => setCheckOutDate(date)}
                                                                className="form-control d-block"
                                                                dateFormat="d-MM-yyyy h:mm aa"
                                                                placeholderText="Select date and time"
                                                                value={validation.values.checkOutDate || ""}
                                                                invalid={
                                                                    validation.touched.checkOutDate &&
                                                                        validation.errors.checkOutDate
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            <label className="input-group-text" htmlFor="inputGroupFile01">
                                                                <FontAwesomeIcon icon={faCalendarAlt} />
                                                            </label>
                                                        </InputGroup>
                                                        {validation.touched.checkOutDate &&
                                                            validation.errors.checkOutDate ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.checkOutDate}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <FormGroup>
                                                            <Label className="form-label">Advance Amount</Label>
                                                            <Input
                                                                name="advance"
                                                                type="number"
                                                                placeholder="0.00"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.advance || ""}
                                                                invalid={
                                                                    validation.touched.advance &&
                                                                        validation.errors.advance
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            {validation.touched.advance &&
                                                                validation.errors.advance ? (
                                                                <FormFeedback type="invalid">
                                                                    {validation.errors.advance}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>

                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <FormGroup>
                                                            <Label className="form-label">Payment Type</Label>
                                                            <Select
                                                                name="payment"
                                                                placeholder="Select Payment Type"
                                                                classNamePrefix="react-select"
                                                                className={`react-select-containers ${validation.touched.payment && validation.errors.payment
                                                                    ? 'is-invalid'
                                                                    : ''
                                                                    }`}
                                                                value={selectedPayment}
                                                                onChange={handlePaymentTypeChange}
                                                                options={paymentTypeList.map((payment) => ({
                                                                    label: payment.paymentType,
                                                                    value: payment.id
                                                                }))}
                                                                isClearable
                                                                isSearchable
                                                                styles={{
                                                                    menu: (provided) => ({
                                                                        ...provided,
                                                                        top: "100%",
                                                                        position: "absolute",
                                                                        width: "100%",
                                                                        zIndex: 999,
                                                                    }),
                                                                }}
                                                            />
                                                            {validation.touched.payment &&
                                                                validation.errors.payment ? (
                                                                <FormFeedback type="invalid">
                                                                    {validation.errors.payment}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </FormGroup>
                                                    </div>

                                                </Col>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <FormGroup>
                                                            <Label className="form-label">Remarks</Label>
                                                            <Input
                                                                name="remarks"
                                                                type="text"
                                                                placeholder="Remarks"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.remarks || ""}
                                                                invalid={
                                                                    validation.touched.remarks &&
                                                                        validation.errors.remarks
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            {validation.touched.remarks &&
                                                                validation.errors.remarks ? (
                                                                <FormFeedback type="invalid">
                                                                    {validation.errors.remarks}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col xs={4}>
                                                    <div className="mb-3">
                                                        <Label className="form-label">
                                                            Reference By
                                                        </Label>
                                                        <Input
                                                            name="reference_by"
                                                            type="text"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.reference_by || ""}
                                                            invalid={
                                                                validation.touched.reference_by &&
                                                                    validation.errors.reference_by
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validation.touched.reference_by &&
                                                            validation.errors.reference_by ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.reference_by}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="text-end">
                                                        <button type="submit" className="btn btn-primary w-md me-3">
                                                            Check In
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn btn-secondary w-md "
                                                            onClick={handleCancelButton}
                                                        >
                                                            Cancel
                                                        </button>

                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Form>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default withTranslation()(Create_Group_Checkin);