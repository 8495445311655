import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody, FormGroup, Label, Input, FormFeedback, InputGroup, Form
} from "reactstrap";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from "react-select";
import { getCountries, getStates, getGuestDetils, getCities, getIdentity, getPaymentTypes, post_ExtraGuest_Details, getRoomsDetils } from "../../../middleware/backent_helper";
import AlertModal from "../../../components/Common/alertModal";
import ErrorAlertModal from "../../../components/Common/errorAlertModal";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import messages from '../../../utils/constants/messages';
//i18n
import { withTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';


const Create_ExtraAdult = props => {
  document.title = "StayPro | Extra Guest"

  const [hid, setHid] = useState(() => {
    const storedHotelId = localStorage.getItem('hotelId')
    return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
  });
  const [dbName, setDbName] = useState(
    localStorage.getItem('dbName')
  );

  const [customers, setCustomers] = useState([]);
  const [showSideBar, setShowSideBar] = useState(false);
  const [subscribemodal, setSubscribemodal] = useState(false);
  const [countriesList, setCountries] = useState([]);
  const [proofTypes, setProofTypes] = useState([]);
  const [proofImages, setproofImages] = useState([]);
  const [selectedProofType, setselectedProofType] = useState('');
  const [selectedPayment, setselectedPayment] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [statesList, setStatesList] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [getstateKey, setstateKey] = useState([])
  const [citiesList, setCitiesList] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [imageDeletion, setImageDeletion] = useState([]);
  const [alertModal, setAlertModal] = useState(false);
  const [errorAlertModal, setErrorAlertModal] = useState(false);
  const [response, setResponse] = useState(false);
  const [roomTypeList, setroomTypeList] = useState([]);
  const [selectedRoomType, setSelectedRoomType] = useState(null);
  const [paymentTypeList, setPaymentTypes] = useState([]);
  const [selectedGender, setSelectedGender] = useState('');
  const [masterName, setMasterName] = useState("Extra Guest");
  const [GuestDetails, setGuestDetails] = useState([]);
  const [selectedNationality, setSelectedNationality] = useState('Indian');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const bookingId = queryParams.get('bookingId');
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [rooms, setRooms] = useState('');
  const [Guest_Tariff, setGuest_Tariff] = useState('');

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    if (selectedOption && selectedOption.label) {
      validation.setFieldValue('country', selectedOption.label);
    } else {
      validation.setFieldValue('country', '');
    }
  };

  const Get_Guest_Details = () => {
    getGuestDetils(hid, dbName, bookingId)
      .then((res) => {
        setGuestDetails(res.data.data);
        Get_Room_Details(res.data.data.roomId);
      });
  };
  
  const Get_Room_Details = (roomId) => {
    if (roomId != null) {
      getRoomsDetils(hid, dbName, roomId)
        .then((res) => {
          setRooms(res.data.data);
          console.log(res.data.data);
          handleTariffFunction(res.data.data); // Pass the room details to handleTariffFunction
        });
    }
  };
  
  const extractMinTariff = (tariffRange) => {
    if (!tariffRange) return 0;
    const rangeParts = tariffRange.split('-').map(part => parseInt(part.replace(/[^0-9]/g, ""), 10)); // Extract numbers from the string
    return rangeParts[0]; // Return the first value, which is the minimum
  };
  
  const handleTariffFunction = (roomDetails) => {
    const extraPersonTariff = extractMinTariff(roomDetails?.extraPersonTariff);
    setGuest_Tariff(extraPersonTariff);
    console.log(extraPersonTariff);
  };
  
  useEffect(() => {
    Get_Guest_Details();
  }, []); 
  
  const formatDate = (dateTime) => {
    const date = new Date(dateTime);
    if (isNaN(date)) {
      throw new Error("Invalid Date");
    }

    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  // Fetch city when a state is selected
  useEffect(() => {
    if (selectedState && selectedState.label) {
      const fetchCities = async () => {
        try {
          const stateKey = getStateKey(selectedState);
          setstateKey(stateKey)

          getCities(stateKey)
            .then((res) => {
              setCitiesList(res.data.data);
              if (customers && customers.city) {
                const foundCity = res.data.data.find(city => city.name === customers.city);
                if (foundCity) {
                  setSelectedCities({ label: foundCity.name, value: foundCity });
                  validation.setFieldValue('city', foundCity.name);
                }
              }
            })
        } catch (error) {
          console.error('Error fetching city:', error);
        }
      };
      fetchCities();
    }
  }, [selectedState]);

  useEffect(() => {
    if (customers) {
      setproofImages(customers.proof_photo)
    }
    getIdentity(hid, dbName)
      .then((res) => {
        setProofTypes(res.data.data)
      })

    getPaymentTypes(hid, dbName)
      .then((res) => {
        setPaymentTypes(res.data.data);
      });

    if (selectedCountry != null) {
      const fetchStates = async () => {
        try {
          const stateKey = getCountryKey(selectedCountry);
          getStates(stateKey).then((res) => {
            setStatesList(res.data.data);
            if (customers && customers.state) {
              const foundState = res.data.data.find(state => state.name === customers.state);
              if (foundState) {
                setSelectedState({ label: foundState.name, value: foundState });
                validation.setFieldValue('state', foundState.name);
              }
            }
          });
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
      fetchStates();
    }
  }, [selectedCountry, customers])

  const getStateKey = (stateName) => {
    const state = statesList.find(s => s.name === stateName.label);
    return state ? state.name : null;
  };

  const getCountryKey = (CountryKey) => {
    const state = countriesList.find(s => s.name === CountryKey.label);
    return state ? state.iso2 : null;
  }


  const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption)
    if (selectedOption && selectedOption.label) {
      validation.setFieldValue('state', selectedOption.label);
    } else {
      validation.setFieldValue('state', '');
    }
  }

  const handleCityChange = (selectedOption) => {
    setSelectedCities(selectedOption)
    if (selectedOption && selectedOption.label) {
      validation.setFieldValue('city', selectedOption.label);
    } else {
      validation.setFieldValue('city', '');
    }
  }

  const genderOptions = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' }
  ];

  const NationalityOptions = [
    { value: 'Indian', label: 'Indian' },
    { value: 'Others', label: 'Others' }
  ];

  const handleProofTypeChange = selectedOption => {
    setselectedProofType(selectedOption)
    validation.setFieldValue('proofType', selectedOption ? selectedOption.value : '');

  }

  const handlePaymentTypeChange = selectedOption => {
    setselectedPayment(selectedOption)
    validation.setFieldValue('payment', selectedOption ? selectedOption.value : '');

  }


  useEffect(() => {
    getCountries()
      .then((res) => {
        setCountries(res.data.data)

        if (customers && customers.country) {
          const foundCountry = res.data.data.find(country => country.name === customers.country);
          if (foundCountry) {
            setSelectedCountry({ label: foundCountry.name, value: foundCountry });
            validation.setFieldValue('country', foundCountry.name);
          }
        }
      })
  }, [])


  const handleGenderChange = selectedOption => {
    setSelectedGender(selectedOption);
    validation.setFieldValue('gender', selectedOption ? selectedOption.value : '');
  };

  const handleNationality = selectedOption => {
    setSelectedNationality(selectedOption);
    validation.setFieldValue('nationality', selectedOption ? selectedOption.value : '');
  };

  const handleCancelButton = () => {
    setSelectedRoomTypeId(null);
    setSubmittedCount(0);
    setShowForm(false)
  };

  const clearFormValues = () => {
    validation.resetForm()
  }

  const validation = useFormik({
    initialValues: {
      guest_name: "",
      mobile_no: "",
      gender: "",
      nationality: "Indian",
      proof_type: "",
      country: "",
      state: "",
      city: "",
      proofType: "",
      gender: "",
      vehicle_no: "",
      address: "",
      email: "",
      company_name: "",
      pincode: "",
      age: "",
      payment: "",
      advance: ""
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      guest_name: Yup.string().required("Please Enter Your Name"),
      age: Yup.string().required("Please Enter Your Age"),
      mobile_no: Yup.string().required("Please Enter Your Mobile No"),
      proofType: Yup.string().required('proof Type type is required'),
      payment: Yup.string().required('payment type is required'),
      advance: Yup.string().required("Please Enter Your Advance Amount"),
      gender: Yup.string().required("Gender is required"),
    }),
    onSubmit: (values) => {
      post_Extra_Guest_Information(values)
      validation.resetForm();
    }
  });

  const post_Extra_Guest_Information = (values) => {

    const data = JSON.stringify({

      GuestName: values.guest_name,
      mobileNo: values.mobile_no,
      gender: values.gender,
      address: values.address,
      country: values.country,
      state: values.state,
      city: values.city,
      pincode: values.pincode,
      nationality: values.selectedNationality,
      age: values.age,
      email: values.email,
      IdentitiesId: values.proofType,
      identityNo: values.proof_no,
      vehicleNo: values.vehicle_no,
      companyName: values.company_name,
      checkInDate: formatDate(fromDate),
      checkOutDate: formatDate(toDate),
      advanceAmount: values.advance,
      PaymentTypeId: values.payment,
      remarks: values.remarks,
      hotelId: hid,
      dbName: dbName,
      bookingId: bookingId,
      tariff: Guest_Tariff,
      roomId: GuestDetails.roomId,
      description: "Room Advance for " + GuestDetails.roomNo,
    });

    post_ExtraGuest_Details(data).then((res) => {
      if (res.data.success) {
        setResponse(messages.success.createdMessage(masterName))
        setAlertModal(true);
      }
    }).catch(err => {
      if (err.response.status === 500) {
        setResponse(messages.failed.createFailed())
      } setErrorAlertModal(true)
    });
    clearFields();
  }

  const clearFields = () => {
    validation.resetForm();
    setselectedPayment('');
    setselectedProofType('');
    setSelectedCountry('');
    setSelectedCities('');
    setSelectedState('');
    setSelectedGender('');
  };


  return (
    <React.Fragment>
      <AlertModal
        show={alertModal}
        onOkClick={() => {
          Get_Guest_Details(), setAlertModal(false);
        }}
        onCloseClick={() => setAlertModal(false)}
        res={response}
      />
      <ErrorAlertModal
        show={errorAlertModal}
        onOkClick={() => { Get_Guest_Details(), setErrorAlertModal(false) }}
        onCloseClick={() => setErrorAlertModal(false)}
        res={response}
      />

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs breadcrumbItem={props.t("Manage Extra Guest")} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="mb-2">
                    <Row className="align-items-center">
                      <Col lg={4} md={4} sm={4}>
                        <strong>Guest Name :</strong>
                        <span> {GuestDetails.guestName}</span>
                        <span style={{ display: 'none' }}> {GuestDetails.guestId}</span>
                      </Col>
                      <Col lg={3} md={3} sm={3}>
                        <strong>Mobile No :</strong>
                        <span> {GuestDetails.mobileNo}</span>
                      </Col>

                      <Col lg={3} md={3} sm={3}>
                        <strong>Checkin Date :</strong><span> {GuestDetails.checkInDate ? GuestDetails.checkInDate.slice(0, 16) : ''}</span>
                      </Col>

                      <Col lg={2} md={2} sm={2}>
                        <strong>Room No :</strong><span> {GuestDetails.roomNo}</span>
                        <span style={{ display: 'none' }}> {GuestDetails.roomId}</span>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >

              <Row>
                <Col xl="12">
                  <Card style={{ border: '1px solid rgb(210 212 218)', borderRadius: '8px' }}>
                    <CardHeader>
                      <h5 className="card-title">{props.t("Add Extra Guest Form")}</h5>
                    </CardHeader>
                    <CardBody>
                      {/* <h5 className="card-title mb-4">
                                        Guest Reservation Form
                                    </h5> */}
                      <Row>
                        <Col lg={4}>
                          <div className="mb-3">
                            <FormGroup>
                              <Label className="form-label">Guest Name</Label>
                              <Input
                                name="guest_name"
                                type="text"
                                placeholder="Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.guest_name || ""}
                                invalid={
                                  validation.touched.guest_name &&
                                    validation.errors.guest_name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.guest_name &&
                                validation.errors.guest_name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.guest_name}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                            <FormGroup>
                              <Label className="form-label">Mobile No</Label>
                              <Input
                                name="mobile_no"
                                type="text"
                                placeholder="MobileNo"
                                onChange={(e) => {
                                  const re = /^[0-9\b]+$/;
                                  if (e.target.value === '' || re.test(e.target.value)) {
                                    validation.handleChange(e);
                                  }
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.mobile_no || ""}
                                invalid={
                                  validation.touched.mobile_no &&
                                    validation.errors.mobile_no
                                    ? true
                                    : false
                                }
                                maxLength={10}
                              />
                              {validation.touched.mobile_no &&
                                validation.errors.mobile_no ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.mobile_no}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                            <FormGroup>
                              <Label className="form-label">Email</Label>
                              <Input
                                name="email"
                                type="text"
                                placeholder="Email"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email &&
                                    validation.errors.email
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.email &&
                                validation.errors.email ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.email}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </div>

                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <div className="mb-3">
                            <FormGroup>
                              <Label className="form-label">Gender</Label>
                              <Select
                                name="gender"
                                // value={selectedGender}
                                value={genderOptions.find(option => option.value === selectedGender)}
                                onChange={handleGenderChange}
                                placeholder="Select Gender"
                                classNamePrefix="react-select"
                                className={`react-select-container ${validation.touched.gender && validation.errors.gender
                                  ? 'is-invalid'
                                  : ''
                                  }`}
                                options={genderOptions.map((gender) => ({
                                  label: gender.label,
                                  value: gender.value,
                                }))}

                                styles={{
                                  menu: (provided) => ({
                                    ...provided,
                                    top: "100%",
                                    position: "absolute",
                                    width: "100%",
                                    zIndex: 999,
                                  }),
                                }}
                              />
                              {validation.touched.gender && validation.errors.gender ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.gender}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                            <FormGroup>
                              <Label className="form-label">Age</Label>
                              <Input
                                name="age"
                                type="text"
                                placeholder="Age"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.age || ""}
                                invalid={
                                  validation.touched.age &&
                                    validation.errors.age
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.age &&
                                validation.errors.age ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.age}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </div>

                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                            <FormGroup>
                              <Label className="form-label">Address</Label>
                              <Input
                                name="address"
                                type="text"
                                placeholder="Address"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.address || ""}
                                invalid={
                                  validation.touched.address &&
                                    validation.errors.address
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.address &&
                                validation.errors.address ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.address}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <div className="mb-3">
                            <FormGroup>
                              <Label className="form-label">Country</Label>
                              <Select
                                name="country"
                                placeholder="Select Country"
                                classNamePrefix="react-select"
                                className={`react-select-container ${validation.touched.country && validation.errors.country
                                  ? 'is-invalid'
                                  : ''
                                  }`}
                                options={countriesList.map((country) => ({
                                  label: country.name,
                                  value: country,
                                }))}
                                value={selectedCountry}
                                onChange={handleCountryChange}
                                isClearable
                                isSearchable
                                styles={{
                                  menu: (provided) => ({
                                    ...provided,
                                    top: "100%",
                                    position: "absolute",
                                    width: "100%",
                                    zIndex: 999,
                                  }),
                                }}
                              />
                              {validation.touched.country &&
                                validation.errors.country ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.country}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                            <FormGroup>
                              <Label className="form-label">State</Label>
                              <Select
                                name="state"
                                placeholder="Select State"
                                classNamePrefix="react-select"
                                className={`react-select-container ${validation.touched.state && validation.errors.state
                                  ? 'is-invalid'
                                  : ''
                                  }`}
                                value={selectedState}
                                onChange={handleStateChange}
                                options={statesList.map((state) => ({
                                  label: state.name,
                                  value: state
                                }))}
                                isClearable
                                isSearchable
                                styles={{
                                  menu: (provided) => ({
                                    ...provided,
                                    top: "100%",
                                    position: "absolute",
                                    width: "100%",
                                    zIndex: 999,
                                  }),
                                }}
                              />
                              {validation.touched.state &&
                                validation.errors.state ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.state}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </div>

                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                            <FormGroup>
                              <Label className="form-label">City</Label>
                              <Select
                                name="city"
                                placeholder="Select City"
                                className={`react-select-containe ${validation.touched.city && validation.errors.city
                                  ? 'is-invalid'
                                  : ''
                                  }`}
                                value={selectedCities}
                                onChange={handleCityChange}
                                options={citiesList.map((city) => ({
                                  label: city.name,
                                  value: city
                                }))}
                                isClearable
                                isSearchable
                                styles={{
                                  menu: (provided) => ({
                                    ...provided,
                                    top: "100%",
                                    position: "absolute",
                                    width: "100%",
                                    zIndex: 999,
                                  }),
                                }}
                              />
                              {validation.touched.city &&
                                validation.errors.city ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.city}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <div className="mb-3">
                            <FormGroup>
                              <Label className="form-label">Nationality</Label>
                              <Select
                                name="nationality"
                                // value={selectedNationality}
                                value={NationalityOptions.find(option => option.value === selectedNationality)}
                                onChange={handleNationality}
                                placeholder="Select Nationality"
                                classNamePrefix="react-select"
                                className={`react-select-container ${validation.touched.nationality && validation.errors.nationality
                                  ? 'is-invalid'
                                  : ''
                                  }`}
                                options={NationalityOptions.map((nationality) => ({
                                  label: nationality.label,
                                  value: nationality.value,
                                }))}

                                styles={{
                                  menu: (provided) => ({
                                    ...provided,
                                    top: "100%",
                                    position: "absolute",
                                    width: "100%",
                                    zIndex: 999,
                                  }),
                                }}
                              />
                              {validation.touched.nationality && validation.errors.nationality ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.nationality}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                            <FormGroup>
                              <Label className="form-label">Pin Code</Label>
                              <Input
                                name="pincode"
                                type="text"
                                placeholder="Pin Code"
                                onChange={(e) => {
                                  const re = /^[0-9\b]+$/;
                                  if (e.target.value === '' || re.test(e.target.value)) {
                                    validation.handleChange(e);
                                  }
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.pincode || ""}
                                invalid={
                                  validation.touched.pincode &&
                                    validation.errors.pincode
                                    ? true
                                    : false
                                }
                                maxLength={6}
                              />
                              {validation.touched.pincode &&
                                validation.errors.pincode ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.pincode}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                            <FormGroup>
                              <Label className="form-label">Company Name</Label>
                              <Input
                                name="company_name"
                                type="text"
                                placeholder="Company Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.company_name || ""}
                                invalid={
                                  validation.touched.company_name &&
                                    validation.errors.company_name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.company_name &&
                                validation.errors.company_name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.company_name}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <div className="mb-3">
                            <FormGroup>
                              <Label className="form-label">Vehicle No.</Label>
                              <Input
                                name="vehicle_no"
                                type="text"
                                placeholder="Vehicle No"
                                onChange={(e) => {
                                  e.target.value = e.target.value.toUpperCase(); // Convert to uppercase
                                  validation.handleChange(e); // Handle the change with Formik
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.vehicle_no || ""}
                                invalid={
                                  validation.touched.vehicle_no &&
                                    validation.errors.vehicle_no
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.vehicle_no &&
                                validation.errors.vehicle_no ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.vehicle_no}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                            <FormGroup>
                              <Label className="form-label">Proof Type</Label>
                              <Select
                                name="proofType"
                                placeholder="Select Proof Type"
                                classNamePrefix="react-select"
                                className={`react-select-container ${validation.touched.proofType && validation.errors.proofType
                                  ? 'is-invalid'
                                  : ''
                                  }`}
                                options={proofTypes.map((proof) => ({
                                  label: proof.identityName,
                                  value: proof.id,
                                }))}
                                value={selectedProofType}
                                onChange={handleProofTypeChange}

                                styles={{
                                  menu: (provided) => ({
                                    ...provided,
                                    top: "100%",
                                    position: "absolute",
                                    width: "100%",
                                    zIndex: 999,
                                  }),
                                }}
                              />
                              {validation.touched.proofType &&
                                validation.errors.proofType ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.proofType}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                            <FormGroup>
                              <Label className="form-label">Proof No.</Label>
                              <Input
                                name="proof_no"
                                type="text"
                                placeholder={"Proof Number"}
                                onChange={(e) => {
                                  e.target.value = e.target.value.toUpperCase(); // Convert to uppercase
                                  validation.handleChange(e); // Handle the change with Formik
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.proof_no || ""}
                                invalid={
                                  validation.touched.proof_no &&
                                    validation.errors.proof_no
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.proof_no &&
                                validation.errors.proof_no ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.proof_no}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xl="12">
                  <Card style={{ border: '1px solid rgb(210 212 218)', borderRadius: '8px' }}>
                    <CardHeader>
                      <h5 className="card-title">{props.t("Add Advance Payment")}</h5>
                    </CardHeader>
                    <CardBody>
                      <Row>

                        <Col lg={4}>
                          <div className="mb-3">
                            <FormGroup>
                              <Label className="form-label">Advance Amount</Label>
                              <Input
                                name="advance"
                                type="number"
                                placeholder="0.00"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.advance || ""}
                                invalid={
                                  validation.touched.advance &&
                                    validation.errors.advance
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.advance &&
                                validation.errors.advance ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.advance}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                            <FormGroup>
                              <Label className="form-label">Payment Type</Label>
                              <Select
                                name="payment"
                                placeholder="Select Payment Type"
                                className={`react-select-containe ${validation.touched.payment && validation.errors.payment
                                  ? 'is-invalid'
                                  : ''
                                  }`}
                                value={selectedPayment}
                                onChange={handlePaymentTypeChange}
                                options={paymentTypeList.map((payment) => ({
                                  label: payment.paymentType,
                                  value: payment.id
                                }))}
                                isClearable
                                isSearchable
                                styles={{
                                  menu: (provided) => ({
                                    ...provided,
                                    top: "100%",
                                    position: "absolute",
                                    width: "100%",
                                    zIndex: 999,
                                  }),
                                }}
                              />
                              {validation.touched.payment &&
                                validation.errors.payment ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.payment}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </div>

                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                            <FormGroup>
                              <Label className="form-label">Remarks</Label>
                              <Input
                                name="remarks"
                                type="text"
                                placeholder="Remarks"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.remarks || ""}
                                invalid={
                                  validation.touched.remarks &&
                                    validation.errors.remarks
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.remarks &&
                                validation.errors.remarks ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.remarks}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="text-end">
                            <button type="submit" className="btn btn-primary w-md me-3">
                              Save
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary w-md "
                              onClick={handleCancelButton}
                            >
                              Cancel
                            </button>

                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Form>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withTranslation()(Create_ExtraAdult);
