import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../../components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import Select from 'react-select';
import { useFormik } from "formik";
import { createRoom, getRooms, updateRoom, deleteRooms, roomTypeRead } from "../../../middleware/backent_helper";
import {
  RoomNumber, RoomType,
  FloorNumber,
  Wing,
  Wifi, RoomStatusname, ColorCode
} from "./RoomsMasterCol";
import messages from '../../../utils/constants/messages';
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";
import AlertModal from "../../../components/Common/alertModal";
import ErrorAlertModal from "../../../components/Common/errorAlertModal";
//redux
import { useSelector, useDispatch } from "react-redux";

const RoomsMaster = props => {
  //meta title
  document.title = "Rooms | Settings ";

  const dispatch = useDispatch();
  const [Rooms, setRooms] = useState();

  const [RoomsList, setRoomsList] = useState([]);
  const [roomTypeList, setroomTypeList] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [errorAlertModal, setErrorAlertModal] = useState(false);
  const [masterName, setMasterName] = useState("Room Master");
  const [selectedTest, setSelectedTest] = useState(null);
  const [response, setResponse] = useState(false);
  const [hid, setHid] = useState(() => {
    const storedHotelId = localStorage.getItem('hotelId')
    return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
  });
  const [dbName, setDbName] = useState(
    localStorage.getItem('dbName')
  )

  const handleRoomChange = selectedOption => {
    setSelectedTest(selectedOption);
    validation.setFieldValue('roomTypeId', selectedOption ? selectedOption.value : '');
  };

  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        Cell: ({ row, rows }) => {
          return (
            <div className="table-cell text-center">
              {rows.length - row.index}
            </div>
          );
        },
      },

      {
        Header: "Room No",
        accessor: "roomNo",
        filterable: true,
        Cell: cellProps => {
          return (
            <div className="table-cell text-center">
              <RoomNumber {...cellProps} />
            </div>
          );

        },
      },
      {
        Header: "Room Type",
        accessor: "title",
        filterable: true,
        Cell: cellProps => {
          return (
            <div className="table-cell text-center">
              <RoomType {...cellProps} />
            </div>
          );

        },
      },
      {
        Header: "Room Status",
        accessor: "RoomStatusname",
        filterable: true,
        Cell: cellProps => {
          const { ColorCode } = cellProps.row.original; // Access the ColorCode from the original row data
          return (
            <div className="table-cell text-center" style={{ backgroundColor: ColorCode, color: '#fff', borderRadius: '4px' }}>
              {cellProps.value} {/* Render the RoomStatusname */}
            </div>
          );
        },
      },

      // {
      //   Header: "Room Status",
      //   accessor: "RoomStatusname",
      //   filterable: true,
      //   Cell: cellProps => {
      //     const { ColorCode } = cellProps.row.original;
      //     return (
      //       <div 
      //         className="table-cell text-center" 
      //         style={{
      //           backgroundColor: ColorCode,
      //           color: '#fff',
      //           display: 'flex',
      //           justifyContent: 'center', // Center horizontally
      //           alignItems: 'center',     // Center vertically
      //           height: '100%',
      //           padding: '15px 0 25px 5px', // Padding: top, right, bottom, left
      //           borderRadius: '4px',      // Rounded corners to look like a badge
      //           whiteSpace: 'nowrap',     // Prevent text from wrapping
      //           overflow: 'hidden',       // Hide overflow if the content is too long
      //           textOverflow: 'ellipsis'  // Add ellipsis for overflow text
      //         }}
      //       >
      //         {cellProps.value}
      //       </div>
      //     );
      //   },
      // },


      // {
      //   Header: "Room Status",
      //   accessor: "RoomStatusname",
      //   filterable: true,
      //   Cell: ({ value }) => {
      //     return (

      //       <div className="blanchedalmond-bg text-center">
      //         {value}
      //       </div>
      //     );
      //   },
      // },
      {
        Header: "Floor No",
        accessor: "floorNo",
        filterable: true,
        Cell: cellProps => {
          return (
            <div className="table-cell text-center">
              <FloorNumber {...cellProps} />
            </div>
          );

        },
      },
      {
        Header: "Wing",
        accessor: "wing",
        filterable: true,
        Cell: cellProps => {
          return (
            <div className="table-cell text-center">
              <Wing {...cellProps} />
            </div>
          );

        },
      },
      {
        Header: "Wifi Details",
        accessor: "wifi",
        filterable: true,
        Cell: cellProps => {
          return (
            <div className="table-cell text-center">
              <Wifi {...cellProps} />
            </div>
          );

        },
      },

      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="table-body actions">
              <div className="actions">
                <Link
                  to="#"
                  className="text-success"
                  onClick={() => {
                    const RoomsData = cellProps.row.original;
                    handleRoomClick(RoomsData);
                  }}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const userData = cellProps.row.original;
                    onClickDelete(userData);
                  }}
                >
                  <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </div>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    roomTypeRead(hid, dbName)
      .then((res) => {
        setroomTypeList(res.data.data);
      });
  }, [])

  useEffect(() => {
    finalList()
  }, [])

  const finalList = () => {
    getRooms(hid, dbName)
      .then((res) => {
        setRoomsList(res.data.data);
      });
  };

  const clearFormValues = () => {
    validation.resetForm();
  };

  const toggle = () => {
    if (modal) {
      setModal(false);
      setRooms(null);
      clearFormValues();
      setSelectedTest(null)
    } else {
      setModal(true);
    }
  };

  const handleRoomClick = arg => {
    const room = arg;

    setSelectedTest({ value: room.id, label: room.title });
    setRooms({
      id: room.id,
      roomNo: room.roomNo,
      roomTypeId: room.roomTypeId,
      floorNo: room.floorNo,
      wing: room.wing,
      wifi: room.wifi,
      hotelId: hid,
      dbName: dbName,

    });
    setIsEdit(true);
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setRooms(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    deleteRooms(Rooms.id, dbName)
      .then((res) => {
        getRooms(hid, dbName), finalList()
        setResponse(messages.success.deleteDetails(masterName))
        setAlertModal(true)
      })
      .catch((err) => {
        if (err.response.status == 400) {
          setResponse(messages.failed.cannotbeDeleted(masterName))
        } else {
          setResponse(messages.failed.updateFailed(masterName))
        }
        setErrorAlertModal(true)
      });
    onPaginationPageChange(1);
    clearFormValues();
    setRooms(null);
    setDeleteModal(false);
  };

  const handleRoomsClick = () => {
    setIsEdit(false);
    toggle();
  };

  const validation = useFormik({

    initialValues: {
      roomNo: Rooms ? Rooms.roomNo : "",
      roomTypeId: Rooms ? Rooms.roomTypeId : "",
      floorNo: Rooms ? Rooms.floorNo : "",
      wing: Rooms ? Rooms.wing : "",
      wifi: Rooms ? Rooms.wifi : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      roomNo: Yup.string().required("Please Enter Room Number"),
      roomTypeId: Yup.string().required("Please Select Room Type"),
      floorNo: Yup.string().required("Please Enter Floor Number"),
      wing: Yup.string().required("Please Enter Wing Details"),
      wifi: Yup.string().required("Please Enter Wifi Details"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updatedRoomsData = { ...Rooms, ...values };
        updateRoom(updatedRoomsData)
          .then((res) => {
            setResponse(messages.success.updatedDetails(masterName))
            setAlertModal(true)
          })
          .catch((err) => {
            setResponse(messages.failed.updateFailed(masterName))
            setErrorAlertModal(true)
          });
        toggle();
      } else {
        dispatch(post_rooms(values));
      }
    }
  });
  const post_rooms = (values) => {
    const data = JSON.stringify({
      roomNo: values.roomNo,
      roomTypeId: values.roomTypeId,
      floorNo: values.floorNo,
      wing: values.wing,
      wifi: values.wifi,
      isActive: true,
      hotelId: hid,
      dbName: dbName
    });
    createRoom(data).then((res) => {
      if (res.data.success) {
        setResponse(messages.success.createdMessage(masterName))
        setAlertModal(true)
      }
    }).catch(err => {
      if (err.response.status === 404) {
        setResponse(messages.failed.AlreadyExists(masterName))
      } else {
        setResponse(messages.failed.createFailed(masterName))
      }
      setErrorAlertModal(true)
    });
    toggle();
  };



  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => { setRooms(null), setDeleteModal(false) }}
      />
      <AlertModal
        show={alertModal}
        onOkClick={() => {
          finalList();
          setAlertModal(false);
        }}
        onCloseClick={() => setAlertModal(false)}
        res={response}
      />
      <ErrorAlertModal
        show={errorAlertModal}
        onOkClick={() => { finalList(), setErrorAlertModal(false) }}
        onCloseClick={() => setErrorAlertModal(false)}
        res={response}
      />

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Settings" breadcrumbItem="Rooms" />
          <Row>
            <Col lg="12">

              <TableContainer
                columns={columns}
                data={RoomsList}
                isGlobalFilter={true}
                isAddRoomsList={true}
                handleRoomsClick={handleRoomsClick}
                customPageSize={10}
                className="custom-header-css"
              />

              <Modal className="modal-lg" isOpen={modal} toggle={toggle} backdrop="static" keyboard={false}>
                <ModalHeader toggle={toggle} tag="h4">
                  {!!isEdit ? "Edit Rooms" : "Add Rooms"}
                </ModalHeader>
                <ModalBody>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      <Col xs={12}>
                        <div className="mb-3" style={{ position: 'relative' }}>
                          <label htmlFor="testName" className="form-label">Room Type </label>
                          <Select
                            id="roomTypeId"
                            name="roomTypeId"
                            options={roomTypeList.map(type => ({ value: type.id, label: type.title }))}
                            placeholder="Select a Room Type"
                            value={selectedTest}
                            onChange={handleRoomChange}
                            isClearable
                            isSearchable
                            classNamePrefix="react-select"
                            className={`react-select-containers ${validation.touched.roomTypeId && validation.errors.roomTypeId ? "is-invalid" : ""}`}
                            styles={{
                              menu: provided => ({ ...provided, top: '100%', position: 'absolute', width: '100%', zIndex: 999 }),
                            }}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.roomTypeId && validation.errors.roomTypeId
                            }
                          />
                         {validation.touched.roomTypeId && validation.errors.roomTypeId ? (
                            <FormFeedback type="invalid">
                              {validation.errors.roomTypeId}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6}>
                        <div className="mb-3">
                          <Label className="form-label">Room Number</Label>
                          <Input
                            name="roomNo"
                            type="text"
                            placeholder="Insert Room Number"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.roomNo || ""}
                            invalid={
                              validation.touched.roomNo &&
                                validation.errors.roomNo
                                ? true
                                : false
                            }
                          />
                          {validation.touched.roomNo &&
                            validation.errors.roomNo ? (
                            <FormFeedback type="invalid">
                              {validation.errors.roomNo}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="mb-3">
                          <Label className="form-label">Floor Number</Label>
                          <Input
                            name="floorNo"
                            type="text"
                            placeholder="Insert Floor Number"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.floorNo || ""}
                            invalid={
                              validation.touched.floorNo &&
                                validation.errors.floorNo
                                ? true
                                : false
                            }
                          />
                          {validation.touched.floorNo &&
                            validation.errors.floorNo ? (
                            <FormFeedback type="invalid">
                              {validation.errors.floorNo}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6}>
                        <div className="mb-3">
                          <Label className="form-label">Wing</Label>
                          <Input
                            name="wing"
                            type="text"
                            placeholder="Insert wing"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.wing || ""}
                            invalid={
                              validation.touched.wing &&
                                validation.errors.wing
                                ? true
                                : false
                            }
                          />
                          {validation.touched.wing &&
                            validation.errors.wing ? (
                            <FormFeedback type="invalid">
                              {validation.errors.wing}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label className="form-label">Wifi Details</Label>
                          <Input
                            name="wifi"
                            type="text"
                            placeholder="Insert Wifi details"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.wifi || ""}
                            invalid={
                              validation.touched.wifi &&
                                validation.errors.wifi
                                ? true
                                : false
                            }
                          />
                          {validation.touched.wifi &&
                            validation.errors.wifi ? (
                            <FormFeedback type="invalid">
                              {validation.errors.wifi}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="text-end">
                          <button type="submit" className="btn btn-primary w-md me-3">
                            {isEdit ? 'Update' : 'Save'}
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary w-md "
                            onClick={toggle}
                          >
                            Cancel
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </ModalBody>
              </Modal>

            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};


export default withRouter(RoomsMaster);
