import React, { useEffect, useState, useCallback } from "react";
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Input, Button, CardHeader, Form, FormFeedback, Modal, ModalHeader, ModalBody, ModalFooter, Table } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import BlockModal from "components/Booking/block";
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from "react-redux";
import { getRoomNumbers, getCountries, getStates, getCities, getIdentity, getAvailableRooms, getPaymentTypes, getAllRoomType, getWithoutCheckinRooms, getRoomsDetils, getRoomType, convert_reservation_checkIn, GetMobilenoDetails, GetguestDetails, getReservationRecord } from "../../middleware/backent_helper";
import { useLocation } from 'react-router-dom';
import { resizeImage } from 'utils/imageUtils';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { addDays } from 'date-fns';

const ConvertReservation = () => {
  document.title = "StayPro | Check In Form";

  const [showBlockModal, setBlockModal] = useState(false);

  const [roomsDetails, setRoomsDetails] = useState('');

  const [selectedGender, setSelectedGender] = useState('');
  const [proofTypes, setProofTypes] = useState([]);

  const [selectedProofType, setselectedProofType] = useState('');
  const [selectedPayment, setselectedPayment] = useState('');
  const [paymentTypeList, setPaymentTypes] = useState([]);
  const [roomTypeList, setroomTypeList] = useState([]);

  const [roomsList, setRoomsList] = useState([]);
  const [selectedWifi, setSelectedWifi] = useState(null);
  const [selectedRoomType, setSelectedRoomType] = useState(null);
  const [selectedNationality, setSelectedNationality] = useState('Indian');
  const [countriesList, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState('');
  const [statesList, setStatesList] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [getstateKey, setstateKey] = useState([])
  const [customers, setCustomers] = useState([]);
  const [resizedImage, setResizedImage] = useState();
  const [showModal, setShowModal] = useState(false);
  const [mobileSearch, setMobileSearch] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [guestDetails, setGuestDetails] = useState(null);
  const [reservationDetails, setReservationDetails] = useState();
  const [roomNumbers, setRoomNumbers] = useState([]);



  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  //Available Room list
  const [RoomsTypeList, setRoomsTypeList] = useState([]);
  const [RoomsList, AsetRoomsList] = useState([]);

  const [hid, setHid] = useState(() => {
    const storedHotelId = localStorage.getItem('hotelId')
    return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
  });
  const [dbName, setDbName] = useState(
    localStorage.getItem('dbName')
  )

  useEffect(() => {
    getReservationRecord(dbName, reservationData.reservation_id)
      .then((res) => {
        setReservationDetails(res.data);
      

      });
  }, [])

  useEffect(() => {
    getAvailableRooms(dbName, reservationData.Room_typesId, checkInDate, checkOutDate)
      .then((res) => {
        if (Array.isArray(res.data.roomNumbers)) {
          setRoomNumbers(res.data.roomNumbers);


        } else {
          console.error('Response data is not an array:', res.data.roomNumbers);
        }
      })
      .catch((error) => {
        console.error('Error fetching room numbers:', error);
      });
  }, []);


  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const roomId = queryParams.get('roomId');
  const reservationData = location.state?.reservationData || {};

  useEffect(() => {
    getAllRoomType(hid, dbName)
      .then((res) => {

        setRoomsList(res.data.data);

      });
  }, [])


  const navigate = useNavigate();
  const dispatch = useDispatch();


  const handleBackClick = () => {

    navigate(`/booking`);
  };

  const handleCheckInDateChange = (date) => {
    setCheckInDate(date);

    // Automatically update checkOutDate to be one day after checkInDate
    const newCheckOutDate = addDays(date, 1);
    setCheckOutDate(newCheckOutDate);


    getAvailableRooms(dbName, reservationData.Room_typesId, checkInDate, checkOutDate)
      .then((res) => {
        if (Array.isArray(res.data.roomNumbers)) {
          setRoomNumbers(res.data.roomNumbers);


        } else {
          console.error('Response data is not an array:', res.data.roomNumbers);
        }
      })
      .catch((error) => {
        console.error('Error fetching room numbers:', error);
      });


  };

  const handleCheckOutDateChange = (date) => {
    setCheckOutDate(date);
  };

  const handleProofTypeChange = selectedOption => {
    setselectedProofType(selectedOption);
    validation.setFieldValue('proofType', selectedOption ? selectedOption.value : '');

  }

  const genderOptions = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' }
  ];
  const NationalityOptions = [
    { value: 'Indian', label: 'Indian' },
    { value: 'Others', label: 'Others' }
  ];

  const handleGenderChange = selectedOption => {
    setSelectedGender(selectedOption);
    validation.setFieldValue('gender', selectedOption ? selectedOption.value : '');
  };

  const handlePaymentTypeChange = selectedOption => {
    setselectedPayment(selectedOption)
    validation.setFieldValue('payment', selectedOption ? selectedOption.value : '');

  }


  useEffect(() => {
    getPaymentTypes(hid, dbName)
      .then((res) => {
        setPaymentTypes(res.data.data);
      });
  }, [])


  useEffect(() => {

    getIdentity(hid, dbName)
      .then((res) => {
        setProofTypes(res.data.data)
      })
  }, [])

  useEffect(() => {
    if (selectedCountry != null) {
      const fetchStates = async () => {
        try {
          const stateKey = getCountryKey(selectedCountry);
          getStates(stateKey).then((res) => {
            setStatesList(res.data.data);
            if (customers && customers.state) {
              const foundState = res.data.data.find(state => state.name === customers.state);
              if (foundState) {
                setSelectedState({ label: foundState.name, value: foundState });
                validation.setFieldValue('state', foundState.name);
              }
            }
          });
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
      fetchStates();
    }

  }, [selectedCountry])

  const getStateKey = (stateName) => {
    const state = statesList.find(s => s.name === stateName.label);
    return state ? state.name : null;
  };

  const getCountryKey = (CountryKey) => {
    const state = countriesList.find(s => s.name === CountryKey.label);
    return state ? state.iso2 : null;
  }

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    if (selectedOption && selectedOption.label) {
      validation.setFieldValue('country', selectedOption.label);
    } else {
      validation.setFieldValue('country', '');
    }
  };

  useEffect(() => {
    getCountries()
      .then((res) => {
        setCountries(res.data.data)

        if (customers && customers.country) {
          const foundCountry = res.data.data.find(country => country.name === customers.country);
          if (foundCountry) {
            setSelectedCountry({ label: foundCountry.name, value: foundCountry });
            validation.setFieldValue('country', foundCountry.name);
          }
        }
      })
  }, [])

  // Fetch city when a state is selected
  useEffect(() => {
    if (selectedState && selectedState.label) {
      const fetchCities = async () => {
        try {
          const stateKey = getStateKey(selectedState);
          setstateKey(stateKey)

          getCities(stateKey)
            .then((res) => {
              setCitiesList(res.data.data);
              if (customers && customers.city) {
                const foundCity = res.data.data.find(city => city.name === customers.city);
                if (foundCity) {
                  setSelectedCities({ label: foundCity.name, value: foundCity });
                  validation.setFieldValue('city', foundCity.name);
                }
              }
            })
        } catch (error) {
          console.error('Error fetching city:', error);
        }
      };
      fetchCities();
    }
  }, [selectedState]);


  const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption)
    if (selectedOption && selectedOption.label) {
      validation.setFieldValue('state', selectedOption.label);
    } else {
      validation.setFieldValue('state', '');
    }
  }

  const handleCityChange = (selectedOption) => {
    setSelectedCities(selectedOption)
    if (selectedOption && selectedOption.label) {
      validation.setFieldValue('city', selectedOption.label);
    } else {
      validation.setFieldValue('city', '');
    }
  }





  const [checkInDate, setCheckInDate] = useState(new Date(reservationData.checkInDate));

  useEffect(() => {
    const currentTime = new Date();
    setCheckInDate(new Date(checkInDate.setHours(currentTime.getHours(), currentTime.getMinutes(), currentTime.getSeconds())));

    setCheckOutDate(new Date(checkOutDate.setHours(currentTime.getHours(), currentTime.getMinutes(), currentTime.getSeconds())));

  }, []);


  const [checkOutDate, setCheckOutDate] = useState(new Date(checkInDate.getTime() + 86400000));


useEffect(() => {
    if (reservationData && reservationData.Payment_typesId) {

      const selectedPaymentType = paymentTypeList.find(
        (payment) => payment.id === reservationData.Payment_typesId
      );

      if (selectedPaymentType) {
        setselectedPayment({
          label: selectedPaymentType.paymentType, // Assuming paymentName is the display name
          value: selectedPaymentType.id
        });

        // Update the validation field value
        validation.setFieldValue('payment', selectedPaymentType.id);
      }
    }
  }, [reservationData, paymentTypeList]);


  //roomid base RoomType get
  useEffect(() => {
    getRoomType(hid, dbName, roomId).then((res) => {

      setroomTypeList(res.data.data[0]?.Rooms || []);
    });
  }, [])

  const handleNationality = selectedOption => {
    setSelectedNationality(selectedOption);
    validation.setFieldValue('nationality', selectedOption ? selectedOption.value : '');
  };



  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the selected file

    if (file) {
      if (file.size > 5242880) {
        // Set file directly if it's too large
        validation.setFieldValue('proof_photo', file);
      } else {
        resizeImage(file, 200, 80).then(resizedFile => {
          setResizedImage(resizedFile);
          validation.setFieldValue('proof_photo', resizedFile);
        }).catch(error => {
          validation.setFieldError('proof_photo', 'Error resizing image.');
        });
      }
    }
  };


  useEffect(() => {

    if (reservationDetails && reservationDetails.guest.gender) {

      const matchedGenderOption = genderOptions.find(
        (option) => option.value === reservationDetails.guest.gender
      );
      setSelectedGender(matchedGenderOption || null);
      validation.setFieldValue('gender', reservationDetails.guest.gender);
    }
  }, [reservationDetails]);

  useEffect(() => {
    if (reservationDetails && reservationDetails.guest.identityId) {

      const selectedProof = proofTypes.find(
        (proof) => proof.id === reservationDetails.guest.identityId

      );

      if (selectedProof) {
        setselectedProofType({
          label: selectedProof.identityName,
          value: selectedProof.id,
        });

        validation.setFieldValue('proofType', selectedProof.id);
      }
    }

  }, [guestDetails, proofTypes]);


  useEffect(() => {
    if (reservationDetails && reservationDetails.guest && reservationDetails.guest.country) {
      const selectedCountry = countriesList.find(
        (country) => country.name === reservationDetails.guest.country
      );

      if (selectedCountry) {
        setSelectedCountry({
          label: selectedCountry.name,
          value: selectedCountry,
        });

        // Update the validation field value
        validation.setFieldValue('country', selectedCountry);
      }
    }
  }, [reservationDetails, countriesList]);



  useEffect(() => {
    if (reservationDetails && reservationDetails.guest && reservationDetails.guest.state) {
      const selectedState = statesList.find(
        (state) => state.name === reservationDetails.guest.state
      );

      if (selectedState) {
        setSelectedState({
          label: selectedState.name,
          value: selectedState,
        });

        // Update the validation field value
        validation.setFieldValue('state', selectedState);
      }
    }
  }, [reservationDetails, statesList]);


  useEffect(() => {
    if (reservationDetails && reservationDetails.guest && reservationDetails.guest.city) {
      const selectedCity = citiesList.find(
        (city) => city.name === reservationDetails.guest.city
      );

      if (selectedCity) {
        setSelectedCities({
          label: selectedCity.name,
          value: selectedCity,
        });

        // Update the validation field value
        validation.setFieldValue('city', selectedCity);
      }
    }
  }, [reservationDetails, citiesList]);




  const validation = useFormik({
    initialValues: {
      gender: reservationDetails ? reservationDetails.guest.gender : '',
      mobile_no: reservationDetails ? reservationDetails.guest.mobileNo : '',
      guest_name: reservationDetails ? reservationDetails.guest.GuestName : '',
      age: reservationDetails ? reservationDetails.guest.age : '',
      email: reservationDetails ? reservationDetails.guest.email : '',
      address: reservationDetails ? reservationDetails.guest.address : '',
      country: reservationDetails ? reservationDetails.guest.country : '',
      state: reservationDetails ? reservationDetails.guest.state : '',
      city: reservationDetails ? reservationDetails.guest.city : '',
      pincode: reservationDetails ? reservationDetails.guest.pincode : '',

      nationality: "Indian",
      roomTypeIds: reservationDetails ? reservationDetails.roomType : '',
      payment: reservationDetails ? reservationDetails.paymentType : '',
      proofType: reservationDetails ? reservationDetails.guest.identityName : '',
      remarks: reservationDetails ? reservationDetails.remarks : '',
      advanceAmount: reservationDetails ? reservationDetails.advanceAmount : '',
      vehicleNo: reservationDetails ? reservationDetails.guest.vehicleNo : '',
      proof_no: reservationDetails ? reservationDetails.guest.identityNo : '',
      company_name: reservationDetails ? reservationDetails.guest.companyName : '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      guest_name: Yup.string().required("Please Enter Your Name"),
      gender: Yup.string().required('Gender is required'),
      mobile_no: Yup.string()
        .matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits')
        .required('Please Enter Your Mobile No'),
      email: Yup.string().matches(
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        "Please Enter Valid Email").required('Email is required'),
      age: Yup.string().matches(/^\d+$/, 'Invalid number format').required('Please Enter Your Age'),
      address: Yup.string().required('Please Enter Your Address').max(50, 'Address must not exceed 50 characters'),
      nationality: Yup.string().required('Nationality is required'),
      // roomTypeIds: Yup.string().required('RoomType is required'),
      // payment: Yup.string().required('Payment Type is required'),
      proofType: Yup.string().required('Proof Type is required'),
    }),
    onSubmit: (values) => {
      post_Booking_checkin(values);
    }
  });



  const post_Booking_checkin = async (values) => {
    try {
      const formData = new FormData();

      const roomData = getRoomData();
     
      // Add roomData to the formData
      formData.append("roomData", JSON.stringify(roomData));

      // Append form values to FormData
      formData.append('ReservationId', reservationData.reservation_id);
      formData.append('roomTypeId', reservationData.Room_typesId);

      formData.append('GuestName', values.guest_name);
      formData.append('mobileNo', values.mobile_no);
      formData.append('gender', values.gender);
      formData.append('address', values.address);
      formData.append('country', values.country);
      formData.append('state', values.state);
      formData.append('city', values.city);
      formData.append('pincode', values.pincode);
      formData.append('nationality', values.nationality);
      formData.append('age', values.age);
      formData.append('email', values.email);
      formData.append('IdentitiesId', values.proofType);
      formData.append('identityNo', typeof values.proof_no === 'string' ? values.proof_no.toUpperCase() : '');
      formData.append('vehicleNo', typeof values.vehicle_no === 'string' ? values.vehicle_no.toUpperCase() : '');
      formData.append('gstNo', typeof values.gst_no === 'string' ? values.gst_no.toUpperCase() : '');
      formData.append('companyName', values.company_name);
      formData.append('checkInDate', checkInDate);
      formData.append('checkOutDate', checkOutDate);
      // formData.append('roomId', roomsDetails.roomId);

      formData.append('no_of_male', values.no_of_male !== "" ? values.no_of_male : 0);
      formData.append('no_of_female', values.no_of_female !== "" ? values.no_of_female : 0);
      formData.append('no_of_child', values.no_of_child !== "" ? values.no_of_child : 0);
      const totalAdvanceAmount = (values.advance_Amt ? values.advance_Amt : 0) + reservationData.advanceAmount;
      formData.append('advanceAmount', totalAdvanceAmount);
      formData.append('paymentTypeId', reservationData.paymentType);
      formData.append('remarks', values.remarks ? values.remarks : null);
      formData.append('hotelId', hid);
      formData.append('dbName', dbName);
      formData.append('isActive', 'true');

      // Append the file to FormData
      if (values.proof_photo) {
        formData.append('proof_photo_json', values.proof_photo ? values.proof_photo : null);
      }


      const response = await convert_reservation_checkIn(formData);

      if (response.data.success) {


        navigate('/booking');

      } else {

        console.error('Failed to create booking.');
      }
    } catch (error) {

      console.error('An error occurred:', error);
    }
  };

  // State to track selected rooms
  const [selectedRooms, setSelectedRooms] = useState({});
  const [error, setError] = useState(null);


  const handleCheckboxChange = (roomNumber) => {
    setSelectedRooms((prevSelectedRooms) => {
      const newSelectedRooms = { ...prevSelectedRooms };
      if (newSelectedRooms[roomNumber]) {
        delete newSelectedRooms[roomNumber];
      } else {
        newSelectedRooms[roomNumber] = { maleCount: "", femaleCount: "", childCount: "", childrenAges: [] };
      }
      return newSelectedRooms;
    });

    // Check if the selected room count exceeds the available room count
    const selectedRoomCount = Object.keys(selectedRooms).length;
    if (selectedRoomCount > reservationData.roomCount) {
      // Display an error message
      setError(`You can only select up to ${reservationData.roomCount} rooms.`);
    } else {
      setError(null);
    }
  };

  useEffect(() => {
    const selectedRoomCount = Object.keys(selectedRooms).length;
    if (selectedRoomCount > reservationData.roomCount) {
      setError(`You can only select up to ${reservationData.roomCount} rooms.`);
    } else {
      setError(null);
    }
  }, [selectedRooms, reservationData.roomCount]);


  // Handler for changing child count
  const handleChildCountChange = (roomNumber, value) => {
    const childCount = value === "" ? 0 : Number(value);
    setSelectedRooms((prev) => {
      const updatedRooms = { ...prev };
      const room = updatedRooms[roomNumber];
      room.childCount = childCount;
      room.childrenAges = Array.from({ length: childCount }, (_, i) => room.childrenAges[i] || '');
      return updatedRooms;
    });
  };

  const handleAgeChange = (roomNumber, index, value) => {
    setSelectedRooms((prev) => {
      const updatedRooms = { ...prev };
      const room = updatedRooms[roomNumber];
      room.childrenAges[index] = parseInt(value, 10); // Parse the value as a number
      return updatedRooms;
    });
  };

  // Render child age inputs for a specific room
  const renderChildrenAges = (roomNumber) => {
    const room = selectedRooms[roomNumber];
    return room.childrenAges.map((age, i) => (
      <div key={i} className="mb-3">
        <label className="form-label" htmlFor={`childrenAges-${roomNumber}-${i}`}>
          Child {i + 1} Age
        </label>
        <Input
          type="text"
          id={`childrenAges-${roomNumber}-${i}`}
          name={`childrenAges[${i}]`}
          placeholder="Enter age"
          value={age}
          onChange={(e) => handleAgeChange(roomNumber, i, e.target.value)}
          min={0}
        />
      </div>
    ));
  };



  // Extract minimum tariff from the range values
  const extractMinTariff = (tariffRange) => {
    if (!tariffRange) return 0;
    const rangeParts = tariffRange.split('-').map(part => parseInt(part.replace(/[^0-9]/g, ""), 10)); // Extract numbers from the string
    return rangeParts[0]; // Return the first value, which is the minimum
  };

  const [roomTariffs, setRoomTariffs] = useState({});



  const getRoomData = () => {
    const roomData = [];

    Object.keys(selectedRooms).forEach((roomNumber) => {
      const roomInfo = selectedRooms[roomNumber];
      const room = roomsList.find(r => r.id === reservationData.Room_typesId);

      if (room) {

        // Set default values for count properties if not entered by user
        roomInfo.maleCount = roomInfo.maleCount !== "" ? roomInfo.maleCount : 0;
        roomInfo.femaleCount = roomInfo.femaleCount !== "" ? roomInfo.femaleCount : 0;
        roomInfo.childCount = roomInfo.childCount !== "" ? roomInfo.childCount : 0;
        // Check if childrenAges has any values
        const hasChildrenAges = roomInfo.childrenAges.some(age => age !== null && age !== '');

        // If not, set all childrenAges values to 0
        if (!hasChildrenAges) {
          roomInfo.childrenAges = roomInfo.childCount === 0 ? [] : Array(roomInfo.childCount).fill(0);
        }
        // Calculate the tariff for the current room
        const tariff = calculateTariff(
          room,
          Number(roomInfo.maleCount),
          Number(roomInfo.femaleCount),
          roomInfo.childrenAges
        );

        // Push room information and tariff into the roomData array
        roomData.push({
          roomNo: roomNumber,
          male: roomInfo.maleCount,
          female: roomInfo.femaleCount,
          child: roomInfo.childCount,
          childrenAges: roomInfo.childrenAges,
          tariff: tariff // Add the tariff to the room data
        });
      }
    });

    return roomData;
  };

  const calculateTariff = (room, noOfMale, noOfFemale, childrenAges) => {
    const totalAdults = noOfMale + noOfFemale;
    const totalChildren = childrenAges.length;
    const totalGuests = totalAdults + totalChildren;

    // Extract tariff values from room data
    const singleTariff = extractMinTariff(room.singleTariff);
    const normalTariff = extractMinTariff(room.normalTariff);
    const extraPersonTariff = extractMinTariff(room.extraPersonTariff);

    let totalTariff = 0;

    // Determine the base tariff based on the number of guests
    if (totalGuests === 1) {
      totalTariff = singleTariff; // Single Tariff for 1 guest
    } else if (totalGuests === 2) {
      totalTariff = normalTariff; // Normal Tariff for 2 guests
    } else if (totalGuests > 2) {
      totalTariff = normalTariff + extraPersonTariff * (totalGuests - 2); // Extra Person Tariff for additional guests
    }

    return totalTariff;
  };

  useEffect(() => {
    // Fetch room data with tariffs
    const roomData = getRoomData();

    // Create an object to hold tariffs for each room
    const tariffs = {};

    roomData.forEach(roomInfo => {
      const { roomNo, tariff } = roomInfo;
      tariffs[roomNo] = tariff; // Store tariff for each room in the object
      
    });

    // Update state with the tariffs for each room
    setRoomTariffs(tariffs);

  }, []); // Dependency array to re-run the effect on changes

  const roomTypeDatass = roomsList.find(room => room.id === reservationData.Room_typesId);

  return (
    <React.Fragment>
      <BlockModal
        show={showBlockModal}
        handleSubmit={() => {/* handle submit */ }}
        onCloseClick={() => setBlockModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Bookings" breadcrumbItem="Booking-CheckIn" />
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >


            <Row>
              <Col lg={8}>
                <Card>
                  <CardHeader><h5>Booking Details</h5></CardHeader>
                  <CardBody>
                    <div>
                      <Row>
                        <div className="mb-2">
                          <Row className="align-items-center">
                            <Col lg={4} md={4} sm={4}>
                              <div><strong>Room Type:</strong>  {roomTypeDatass?.title ? roomTypeDatass?.title : 'N/A'} </div>
                            </Col>

                            <Col lg={4} md={4} sm={4}>
                              <strong>No of Rooms:</strong>  {reservationDetails?.roomCount || 'N/A'}
                            </Col>





                          </Row>
                          <Row className="mb-2">
                            <Col lg={4} md={4} sm={4}>
                              <strong>Single:</strong>{roomTypeDatass?.singleTariff || 'N/A'}
                            </Col>

                            <Col lg={4} md={4} sm={2}>
                              <strong>Normal:</strong>{roomTypeDatass?.normalTariff || 'N/A'}
                            </Col>
                            <Col lg={4} md={4} sm={2}>
                              <strong>Extra Person:</strong> {roomTypeDatass?.extraPersonTariff || 'N/A'}
                            </Col>

                          </Row>
                        </div>

                        {roomNumbers.length === 0 && <p>No rooms available</p>}
                        {roomNumbers.map((roomNumber) => (
                          <Col key={roomNumber} lg={3}>
                            <div className="form-check">
                              <label className="form-check-label mx-2" htmlFor={`room-${roomNumber}`}>
                                {roomNumber}
                              </label>
                              <input
                                type="checkbox"
                                id={`room-${roomNumber}`}
                                checked={selectedRooms[roomNumber]}
                                onChange={() => handleCheckboxChange(roomNumber)}
                              />

                            </div>
                          </Col>
                        ))}
                      </Row>

                      {Object.keys(selectedRooms).length > 0 && (
                        <div className="mt-3">
                          <Table bordered>
                            <thead>
                              <tr>
                                <th>Room No</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>Child</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.entries(selectedRooms).map(([roomNumber, roomData]) => (
                                <tr key={roomNumber}>
                                  <td className="text-center">{roomNumber}</td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={roomData.maleCount}
                                      onChange={(e) => setSelectedRooms((prev) => ({
                                        ...prev,
                                        [roomNumber]: { ...prev[roomNumber], maleCount: Number(e.target.value) }
                                      }))}


                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={roomData.femaleCount}
                                      onChange={(e) => setSelectedRooms((prev) => ({
                                        ...prev,
                                        [roomNumber]: { ...prev[roomNumber], femaleCount: Number(e.target.value) }
                                      }))}

                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={roomData.childCount}
                                      onChange={(e) => handleChildCountChange(roomNumber, e.target.value)}

                                    />
                                    {roomData.childCount > 0 && renderChildrenAges(roomNumber)}
                                  </td>
                                </tr>
                              ))}

                            </tbody>
                            {error && (
                              <div className="error-message text-danger">
                                {error}
                              </div>
                            )}
                          </Table>
                        </div>
                      )}
                    </div>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader><h5 className="">Guest Registration Form</h5></CardHeader>
                  <CardBody>
                    <div className="mb-2">
                      <Row>
                        <Row className="align-items-center">
                          <Col lg={4}>
                            <div className="mb-3">
                              <FormGroup>
                                <Label className="form-label">Mobile No</Label>
                                <Input
                                  name="mobile_no"
                                  type="text"
                                  placeholder="MobileNo"

                                  // onChange={(e) => {
                                  //   const re = /^[0-9\b]+$/;
                                  //   if (e.target.value === '' || re.test(e.target.value)) {
                                  //     validation.handleChange(e);
                                  //   }
                                  // }}
                                  onBlur={validation.handleBlur}
                                  // value={mobileSearch || ""}
                                  value={validation.values.mobile_no || ""}
                                  invalid={
                                    validation.touched.mobile_no &&
                                      validation.errors.mobile_no
                                      ? true
                                      : false
                                  }
                                  maxLength={10}
                                />
                                {validation.touched.mobile_no &&
                                  validation.errors.mobile_no ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.mobile_no}
                                  </FormFeedback>
                                ) : null}
                                {/* Display suggestions in a dropdown */}
                                {/* Display matching suggestions */}
                                {suggestions.length > 0 && (
                                  <ul className="suggestions-dropdown">
                                    {suggestions.map((suggestion, index) => (
                                      <li key={index} onClick={() => handleSuggestionClick(suggestion.mobileNo)}>
                                        {suggestion.mobileNo}
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </FormGroup>
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <FormGroup>
                                <Label className="form-label">Email</Label>
                                <Input
                                  name="email"
                                  type="text"
                                  placeholder="Email"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.email || ""}
                                  invalid={
                                    validation.touched.email &&
                                      validation.errors.email
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.email &&
                                  validation.errors.email ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.email}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </div>
                          </Col>
                          <Col lg={4} sm={4} md={4}>
                            <div className="mb-3">
                              <FormGroup>
                                <Label className="form-label">Guest Name</Label>
                                <Input
                                  name="guest_name"
                                  type="text"
                                  placeholder="Name"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.guest_name || ""}
                                  invalid={
                                    validation.touched.guest_name &&
                                      validation.errors.guest_name
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.guest_name &&
                                  validation.errors.guest_name ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.guest_name}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={4}>
                            <div className="mb-3">
                              <FormGroup>
                                <Label className="form-label">Age</Label>
                                <Input
                                  name="age"
                                  type="text"
                                  placeholder="Age"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.age || ""}
                                  invalid={
                                    validation.touched.age &&
                                      validation.errors.age
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.age &&
                                  validation.errors.age ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.age}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <FormGroup>
                                <Label className="form-label">Gender</Label>
                                <Select
                                  name="gender"
                                  value={selectedGender}
                                  onChange={handleGenderChange}
                                  placeholder="Select Gender"
                                  classNamePrefix="react-select"
                                  className={`react-select-container ${validation.touched.gender && validation.errors.gender
                                    ? 'is-invalid'
                                    : ''
                                    }`}
                                  options={genderOptions.map((gender) => ({
                                    label: gender.label,
                                    value: gender.value,
                                  }))}

                                  styles={{
                                    menu: (provided) => ({
                                      ...provided,
                                      top: "100%",
                                      position: "absolute",
                                      width: "100%",
                                      zIndex: 999,
                                    }),
                                  }}
                                  isClearable
                                  isSearchable
                                />
                                {validation.touched.gender && validation.errors.gender ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.gender}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <FormGroup>
                                <Label className="form-label">Proof Type</Label>
                                <Select
                                  name="proofType"
                                  placeholder="Select Proof Type"
                                  classNamePrefix="react-select"
                                  className={`react-select-container ${validation.touched.proofType && validation.errors.proofType
                                    ? 'is-invalid'
                                    : ''
                                    }`}
                                  options={proofTypes.map((proof) => ({
                                    label: proof.identityName,
                                    value: proof.id,
                                  }))}
                                  value={selectedProofType}
                                  onChange={handleProofTypeChange}

                                  styles={{
                                    menu: (provided) => ({
                                      ...provided,
                                      top: "100%",
                                      position: "absolute",
                                      width: "100%",
                                      zIndex: 999,
                                    }),
                                  }}
                                  isClearable
                                  isSearchable
                                />
                                {validation.touched.proofType &&
                                  validation.errors.proofType ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.proofType}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={4}>
                            <div className="mb-3">
                              <FormGroup>
                                <Label className="form-label">Proof No.</Label>
                                <Input
                                  name="proof_no"
                                  type="text"
                                  placeholder={"Proof Number"}
                                  onChange={(e) => {
                                    e.target.value = e.target.value.toUpperCase();
                                    validation.handleChange(e);
                                  }}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.proof_no || ""}
                                  invalid={
                                    validation.touched.proof_no &&
                                      validation.errors.proof_no
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.proof_no &&
                                  validation.errors.proof_no ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.proof_no}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <FormGroup>
                                <Label className="form-label">Vehicle No.</Label>
                                <Input
                                  name="vehicleNo"
                                  type="text"
                                  placeholder="vehicle No "
                                  onChange={(e) => {
                                    e.target.value = e.target.value.toUpperCase();
                                    validation.handleChange(e);
                                  }}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.vehicleNo || ""}
                                  invalid={
                                    validation.touched.vehicleNo &&
                                      validation.errors.vehicleNo
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.vehicleNo &&
                                  validation.errors.vehicleNo ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.vehicleNo}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </div>
                          </Col>

                          <Col lg={4}>
                            <div className="mb-3">
                              <FormGroup>
                                <Label className="form-label">Company Name</Label>
                                <Input
                                  name="company_name"
                                  type="text"
                                  placeholder="Company Name"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.company_name || ""}
                                  invalid={
                                    validation.touched.company_name &&
                                      validation.errors.company_name
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.company_name &&
                                  validation.errors.company_name ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.company_name}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </div>
                          </Col>


                        </Row>
                        <Row>
                          <Col lg={4}>
                            <div className="mb-3">
                              <FormGroup>
                                <Label className="form-label">Country</Label>
                                <Select
                                  name="country"
                                  placeholder="Select Country"
                                  classNamePrefix="react-select"
                                  className={`react-select-container ${validation.touched.country && validation.errors.country
                                    ? 'is-invalid'
                                    : ''
                                    }`}
                                  options={countriesList.map((country) => ({
                                    label: country.name,
                                    value: country,
                                  }))}
                                  value={selectedCountry}
                                  onChange={handleCountryChange}
                                  isClearable
                                  isSearchable
                                  styles={{
                                    menu: (provided) => ({
                                      ...provided,
                                      top: "100%",
                                      position: "absolute",
                                      width: "100%",
                                      zIndex: 999,
                                    }),
                                  }}
                                />
                                {validation.touched.country &&
                                  validation.errors.country ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.country}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </div>
                          </Col>

                          <Col lg={4}>
                            <div className="mb-3">
                              <FormGroup>
                                <Label className="form-label">State</Label>
                                <Select
                                  name="state"
                                  placeholder="Select State"
                                  classNamePrefix="react-select"
                                  className={`react-select-container ${validation.touched.state && validation.errors.state
                                    ? 'is-invalid'
                                    : ''
                                    }`}
                                  value={selectedState}
                                  onChange={handleStateChange}
                                  options={statesList.map((state) => ({
                                    label: state.name,
                                    value: state
                                  }))}
                                  isClearable
                                  isSearchable
                                  styles={{
                                    menu: (provided) => ({
                                      ...provided,
                                      top: "100%",
                                      position: "absolute",
                                      width: "100%",
                                      zIndex: 999,
                                    }),
                                  }}
                                />
                                {validation.touched.state &&
                                  validation.errors.state ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.state}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <FormGroup>
                                <Label className="form-label">City</Label>
                                <Select
                                  name="city"
                                  placeholder="Select City"
                                  className={`react-select-containe ${validation.touched.city && validation.errors.city
                                    ? 'is-invalid'
                                    : ''
                                    }`}
                                  value={selectedCities}
                                  onChange={handleCityChange}
                                  options={citiesList.map((city) => ({
                                    label: city.name,
                                    value: city
                                  }))}
                                  isClearable
                                  isSearchable
                                  styles={{
                                    menu: (provided) => ({
                                      ...provided,
                                      top: "100%",
                                      position: "absolute",
                                      width: "100%",
                                      zIndex: 999,
                                    }),
                                  }}
                                />
                                {validation.touched.city &&
                                  validation.errors.city ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.city}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </div>
                          </Col>

                        </Row>
                        <Row>
                          <Col xs={4}>
                            <div className="mb-3">
                              <FormGroup>
                                <Label className="form-label">Nationality</Label>
                                <Select
                                  name="nationality"

                                  value={NationalityOptions.find(option => option.value === selectedNationality)}
                                  onChange={handleNationality}
                                  placeholder="Select Nationality"
                                  classNamePrefix="react-select"
                                  className={`react-select-container ${validation.touched.nationality && validation.errors.nationality
                                    ? 'is-invalid'
                                    : ''
                                    }`}
                                  options={NationalityOptions.map((nationality) => ({
                                    label: nationality.label,
                                    value: nationality.value,
                                  }))}

                                  styles={{
                                    menu: (provided) => ({
                                      ...provided,
                                      top: "100%",
                                      position: "absolute",
                                      width: "100%",
                                      zIndex: 999,
                                    }),
                                  }}
                                />
                                {validation.touched.nationality && validation.errors.nationality ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.nationality}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </div>


                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <FormGroup>
                                <Label className="form-label">Address</Label>
                                <Input
                                  name="address"
                                  type="text"
                                  placeholder="Address"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.address || ""}
                                  invalid={
                                    validation.touched.address &&
                                      validation.errors.address
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.address &&
                                  validation.errors.address ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.address}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <FormGroup>
                                <Label className="form-label">Pin Code</Label>
                                <Input
                                  name="pincode"
                                  type="text"
                                  placeholder="Pin Code"
                                  onChange={(e) => {
                                    const re = /^[0-9\b]+$/;
                                    if (e.target.value === '' || re.test(e.target.value)) {
                                      validation.handleChange(e);
                                    }
                                  }}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.pincode || ""}
                                  invalid={
                                    validation.touched.pincode &&
                                      validation.errors.pincode
                                      ? true
                                      : false
                                  }
                                  maxLength={6}
                                />
                                {validation.touched.pincode &&
                                  validation.errors.pincode ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.pincode}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12}>
                            <div className="mb-3">
                              <FormGroup>
                                <Label className="form-label">Upload Image</Label>
                                <Input
                                  name="proof_photo"
                                  type="file"
                                  accept="image/*"
                                  className={`form-control`}

                                  onChange={handleFileChange}
                                  multiple
                                />
                                {validation.touched.proof_photo &&
                                  validation.errors.proof_photo ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.proof_photo}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </div>
                          </Col>
                        </Row>
                      </Row>

                    </div>
                  </CardBody>
                </Card>

              </Col>
              <Col lg={4}>
                <Card>
                  <CardHeader><h5>Check In Info</h5></CardHeader>
                  <CardBody>
                    <div className="mb-1">
                      <Row>

                      </Row>
                      <Row>
                        <Col lg={12}>
                          <div className="mb-3">
                            <FormGroup>
                              <Label for="checkInDate" className="form-label">Check-In Date</Label>
                              <DatePicker
                                name="checkInDate"
                                selected={checkInDate}
                                onChange={handleCheckInDateChange}
                                dateFormat="dd-MM-yyyy"
                                className="form-control"
                                placeholderText="Select a date"
                                minDate={new Date()}
                              />
                              <FontAwesomeIcon icon={faCalendarAlt}
                                className="position-absolute"
                                style={{ top: '56%', right: '23px', transform: 'translateY(-50%)', color: '#6c757d' }}
                              />

                            </FormGroup>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <div className="mb-3">
                            <FormGroup>
                              <Label className="form-label">Expected Check-Out Date</Label>
                              <DatePicker
                                name="checkoutDate"
                                selected={checkOutDate}
                                onChange={handleCheckOutDateChange}
                                dateFormat="dd-MM-yyyy"
                                className="form-control"
                                placeholderText="Select a date"
                                minDate={checkInDate ? addDays(checkInDate, 1) : new Date()}
                              />
                              <FontAwesomeIcon icon={faCalendarAlt}
                                className="position-absolute"
                                style={{ top: '56%', right: '23px', transform: 'translateY(-50%)', color: '#6c757d' }}
                              />

                            </FormGroup>
                          </div>
                        </Col>
                      </Row>


                      <Row>
                        <Col xs={6}>
                          <div className="mb-3">
                            <label className="form-label" htmlFor="tariff">Paid Advance</label>
                            <Input
                              type="number"
                              name="advanceAmount"
                              placeholder="0.00"
                              min={0}
                              value={validation.values.advanceAmount || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={validation.touched.advanceAmount && validation.errors.advanceAmount}
                            />
                          </div>
                        </Col>

                        <Col xs={6}>
                          <div className="mb-3">
                            <label className="form-label" htmlFor="AdvanceAmt">Advance Amount</label>
                            <Input
                              type="number"
                              name="advance_Amt"
                              placeholder="0"
                              min={0}
                              value={validation.values.advance_Amt || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={validation.touched.advance_Amt && validation.errors.advance_Amt}
                            />
                          </div>
                        </Col>

                        <Col xs={6}>
                          <div className="mb-3" style={{ position: 'relative' }}>
                            <label htmlFor="testName" className="form-label">Payment Type </label>
                            <Select
                              name="payment"
                              placeholder="Select Payment Type"
                              className={`react-select-containe ${validation.touched.payment && validation.errors.payment
                                ? 'is-invalid'
                                : ''
                                }`}
                              value={selectedPayment}
                              onChange={handlePaymentTypeChange}
                              options={paymentTypeList.map((payment) => ({
                                label: payment.paymentType,
                                value: payment.id
                              }))}
                              isClearable
                              isSearchable
                              styles={{
                                menu: (provided) => ({
                                  ...provided,
                                  top: "100%",
                                  position: "absolute",
                                  width: "100%",
                                  zIndex: 999,
                                }),
                              }}
                            />
                            {validation.touched.payment &&
                              validation.errors.payment ? (
                              <FormFeedback type="invalid">
                                {validation.errors.payment}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xs={6}>
                          <div className="mb-3">
                            <Label className="form-label">Remarks</Label>
                            <Input
                              name="remarks"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.remarks || ""}
                              invalid={
                                validation.touched.remarks &&
                                  validation.errors.remarks
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.remarks &&
                              validation.errors.remarks ? (
                              <FormFeedback type="invalid">
                                {validation.errors.remarks}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <div className="mb-3">
                            <Label className="form-label">Complete this Reservation</Label>
                            <div className="d-flex">
                              <div className="form-check me-3">
                                <Input
                                  className="form-check-input"
                                  type="radio"
                                  name="reference_by"
                                  id="referenceYes"
                                  value="Yes"
                                  // checked={validation.values.reference_by === 'Yes'}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  invalid={validation.touched.reference_by && validation.errors.reference_by}
                                />
                                <Label className="form-check-label" htmlFor="referenceYes">
                                  Yes
                                </Label>
                              </div>
                              <div className="form-check">
                                <Input
                                  className="form-check-input"
                                  type="radio"
                                  name="reference_by"
                                  id="referenceNo"
                                  value="No"
                                  // checked={validation.values.reference_by === 'No'}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  invalid={validation.touched.reference_by && validation.errors.reference_by}
                                />
                                <Label className="form-check-label" htmlFor="referenceNo">
                                  No
                                </Label>
                              </div>
                            </div>
                            {validation.touched.reference_by && validation.errors.reference_by && (
                              <FormFeedback type="invalid">{validation.errors.reference_by}</FormFeedback>
                            )}
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={12}>
                          <div className="d-flex justify-content-center">
                            <Button color="btn btn-success" type="submit" className="mx-2">
                              Check In
                            </Button>
                            <Button color="secondary" className="mx-2" onClick={() => handleBackClick()}>
                              Back
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>

            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default ConvertReservation;
