import React from "react";
import { Navigate } from "react-router-dom";
// Auth
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Login";
import Register from "../pages/Authentication/Register";
import PlanDetails from "../pages/Authentication/plans";
import OTPPage from "../pages/Authentication/Otp";
import ForgotOTPComponent from "../pages/Authentication/ForgotOtp";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ForgetPasswordPage from "../pages/AuthenticationInner/ForgetPassword";
import ResetPasswordPage from "../pages/Authentication/recoverPassword";
// dashboard
import Dashboard from "../pages/Dashboard/index";
// profile
import Profile from "../pages/Profile/profile";
import ChangePassword from "../pages/Profile/changePassword";
// masters
import Modules from "../pages/Masters/Modules/Modules"
import RoomsMaster from "../pages/Masters/RoomsMaster/RoomsMaster"
import AssetsMaster from "../pages/Masters/AssetsMaster/AssetsMaster"
import CustomerMaster from "../pages/Masters/CustomerMaster/CustomerMaster"
import ElectricityAccountMaster from "../pages/Masters/ElectricityAccountMaster/ElectricityAccountMaster"
import GensetDetailMaster from "../pages/Masters/GensetDetailMaster/GensetDetailMaster"
import IdentityDetailsMaster from "../pages/Masters/IdentityDetailsMaster/IdentityDetailsMaster"
import PurchaseMaster from "../pages/Accounts/PurchaseMaster/PurchaseMaster"
import CategoryMaster from "../pages/Masters/CategoryMaster/CategoryMaster"
import PaymentType from "../pages/Masters/PaymentType/PaymentType"
import RoomService from "../pages/Masters/RoomService/RoomService"
import RoomTypes from "../pages/Masters/RoomTypes/RoomTypes"
import SpecialMaster from "../pages/Masters/SpecialMaster/SpecialMaster"
import TaxMaster from "../pages/Masters/TaxMaster/TaxMaster"
import OnlinePortalTypes from "../pages/Masters/OnlinePortalMaster/OnlinePortal"
import WifiPassword from "../pages/Masters/Wifi Password/WifiPassword"
import Menus from "../pages/Masters/Menus/index"
import Plan from "../pages/Masters/PlanMaster/Plan";
//HotelMaster
// import AddHotelDetail from "pages/Property Settings/settings";
// import HotelList from "../pages/Masters/HotelMaster/Hotel";
import HotelList from "../pages/Masters/HotelMaster/Hotel";
import HotelDetail from "../pages/Masters/HotelMaster/HotelDetail";
import RoomMapping from "../pages/Masters/RoomMapping/index";
//ActivityLogs
import ActivityLogs from "../pages/ActivityLogs/ActivityLogs";
// print componets
import PrintComponent from "../components/customer/FormComponent";
// settings
import User from "../pages/Settings/User"
import Admin from "../pages/Settings/Admin"

//Booking
import CheckIn from "pages/Booking/CheckIn-out/index";
import BookingPage from "pages/Booking/CheckIn-out/booking";
import BookingCheckIn from "pages/Booking/CheckIn/Check_In";
import BookingView from "pages/Booking/bookingView";
import BookingView2 from "pages/Booking/bookingView2";

//Group Booking
import GroupCheckIn from "pages/Booking/Group/checkin";
import GroupCheckOut from "pages/Booking/Group/checkout";

// reservation
import Reservation from "pages/Reservation/reservation";
import ReservationView from "pages/Reservation/reservationView";
import Create_Reservation from "pages/Reservation/index";
import ViewReservation from "pages/Reservation/viewReservation";

// Users
import UsersList from "../pages/Users/UserList/users-list";
// income & expenses
import InventorySales from "../pages/Accounts/Inventory Sales Master/inventorySales";
import AmountToOwner from "../pages/Income_Expenses/Amount To Owner/amountToOwner";
import BalanceReport from "../pages/Income_Expenses/Income & expense balance/balanceReport";
import IncomeAndExpense from "../pages/Income_Expenses/Income_Expenses/income&expenses";
import InventoryStock from "../pages/Accounts/Inventory Stock/inventoryStock";
import PettyCashOverall from "../pages/Income_Expenses/pettyCash Overall Report/pettyCash_overall";
import PettyCash from "../pages/Income_Expenses/PettyCash/pettyCash";
import AllRoomsSales from "../pages/Income_Expenses/Room Sales Report/allRooms_sales";
// Reports
import AdvanceBooking from "../pages/Reports/Advance Booking/advanceBooking";
import AmountToOwnerReport from "../pages/Reports/AmountToOwner/amountToOwner";
import CancelBooking from "../pages/Reports/Cancelled Booking/cancelBooking";
import CheckInReport from "../pages/Reports/CheckIn Report/checkIn";
import CheckOutReport from "../pages/Reports/CheckOut Report/checkOut";
import GstReport from "../pages/Reports/Gst Report/gstReport";
import GuestReport from "../pages/Reports/Guest/guest";
import GuestArrival from "../pages/Reports/Guest Arrival/guestArrival";
import HouseKeeping from "../pages/Reports/House Keeping/houseKeeping";
import IncomeAndExpenseReport from "../pages/Reports/Income&Expense Report/inAndExReport";
import IncomeAndExpenseRoom from "../pages/Reports/Income&Expense room/InAndExRoom";
import InventoryPurchase from "../pages/Reports/Inventory Purchase/InventoryPurchase";
import InventorySalesReport from "../pages/Reports/Inventory Sales Report/InventorySales";
import PoliceReport from "../pages/Reports/Manage Police Report/PoliceReport";
import MisCategoryReport from "../pages/Reports/MisCategory Report/MisCategoryReport";
import NightAudit from "../pages/Reports/Night Audit/NightAudit";
import OverAllAdmin from "../pages/Reports/Overall Admin Report/OverAllAdmin";
import RoomWiseGuest from "../pages/Reports/RoomWise Guest/RoomWiseGuest";
import HotelForm from "../components/hotel/hotel_form";
import HotelSettings from "../pages/PropertySettings/settings";
import JobList from "../pages/PropertySettings/Print Template/index";
import GenralSettings from "../pages/PropertySettings/generalSettings";
import CheckOut from "../pages/Booking/CheckOut/CheckOut";
import ShiftRoom from "pages/Booking/CheckOut/ShiftRoom";
import ManageRoomService from "pages/Booking/CheckOut/RoomService";
import ExtraAdult from "pages/Booking/CheckOut/ExtraAdult";
import AdvancePay from "pages/Booking/CheckOut/AdvancePay";
import ConvertReservation from "../pages/Reservation/convertReservation"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard />, allowedRoles: ['Super-Admin', 'Hotel'] },
  { path: "/dashboard", exact: true, component: < Navigate to="/dashboard" />, allowedRoles: ['Super-Admin', 'Hotel'] },
  // { path: "/booking", exact: true, component: <CheckIn />, allowedRoles: ['Hotel'] },
  { path: "/booking", exact: true, component: <BookingPage />, allowedRoles: ['Hotel'] },
  { path: "/booking_CheckIn", component: <BookingCheckIn />, allowedRoles: ['Hotel']},
  { path: "/booking-view", component: <BookingView />, allowedRoles: ['Hotel'] },
  { path: "/booking-view2", component: <BookingView2 />, allowedRoles: ['Hotel'] },
  { path: "/group-checkin", exact: true, component: <GroupCheckIn />, allowedRoles: ['Hotel'] },
  { path: "/group-checkout", exact: true, component: <GroupCheckOut />, allowedRoles: ['Hotel'] },
  { path: "/add-reservation", component: <Create_Reservation/>, allowedRoles: ['Hotel'] },
  { path: "/reservation", component: <Reservation/>, allowedRoles: ['Hotel'] },
  { path: "/check_out", component: <CheckOut/>, allowedRoles: ['Hotel'] },
  { path: "/shift_room", component: <ShiftRoom/>, allowedRoles: ['Hotel'] },
  { path: "/manage_room_service/:bookingId", component: <ManageRoomService/>, allowedRoles: ['Hotel'] },
  { path: "/extra_adult", component: <ExtraAdult/>, allowedRoles: ['Hotel'] },
  { path: "/advance_pay", component: <AdvancePay/>, allowedRoles: ['Hotel'] },
  { path: "/reservation-view", exact: true, component: <ReservationView/>, allowedRoles: ['Hotel'] },
  { path: "/convert-reservation", exact: true, component: <ConvertReservation/>, allowedRoles: ['Hotel'] },
  // { path: "/user_list", component: <UsersList /> },
  { path: "/rooms", component: <RoomsMaster />, allowedRoles: ['Hotel']},
  { path: "/modules", component: <Modules />, allowedRoles: ['Super-Admin'] },
  // Masters
  { path: "/assets_master", component: <AssetsMaster />, allowedRoles: ['Hotel'] },
  { path: "/customer_master", component: <CustomerMaster />, allowedRoles: ['Hotel'] },
  { path: "/print-customer", component: <PrintComponent /> },
  { path: "/electricity_account_master", component: <ElectricityAccountMaster />, allowedRoles: ['Hotel'] },
  { path: "/genset_master", component: <GensetDetailMaster />, allowedRoles: ['Hotel'] },
  { path: "/identity_master", component: <IdentityDetailsMaster />, allowedRoles: ['Hotel'] },
  { path: "/purchase_master", component: <PurchaseMaster />, allowedRoles: ['Hotel'] },
  { path: "/category_master", component: <CategoryMaster />, allowedRoles: ['Hotel'] },
  { path: "/payment_type", component: <PaymentType />, allowedRoles: ['Hotel'] },
  { path: "/room_types", component: <RoomTypes />, allowedRoles: ['Hotel'] },
  { path: "/special_master", component: <SpecialMaster />, allowedRoles: ['Hotel'] },
  { path: "/tax_master", component: <TaxMaster />, allowedRoles: ['Hotel'] },
  { path: "/online_portal_type_master", component: <OnlinePortalTypes />, allowedRoles: ['Hotel'] },
  { path: "/wifi_password", component: <WifiPassword />, allowedRoles: ['Hotel'] },
  { path: "/Plan", component: <Plan />, allowedRoles: ['Super-Admin'] },
  { path: "/room-mapping", component: <RoomMapping />, allowedRoles: ['Hotel'] },
  { path: "/room-service", component: <RoomService />, allowedRoles: ['Hotel'] },
  //Hotelmaster
  { path: "/hotel", component: <HotelList />, allowedRoles: ['Super-Admin'] },
  { path: "/hotel-detail/:id", component: <HotelDetail />, allowedRoles: ['Super-Admin'] },
  // Settings
  { path: "/edit-hotel", component: <HotelSettings />, allowedRoles: ['Super-Admin', 'Hotel'] },
  { path: "/general-settings", component: <GenralSettings />, allowedRoles: ['Hotel'] },
  //Auth
  // { path: "/settings/user", component: <User /> },
  // { path: "/settings/admin", component: <Admin />},
  { path: "/profile", component: <Profile /> },
  { path: "/reset_password", component: <ChangePassword />, allowedRoles: ['Super-Admin', 'Hotel'] },
  { path: "/menus_list", component: <Menus /> , allowedRoles: ['Super-Admin'] },
  { path: "/activity_log", component: <ActivityLogs />, allowedRoles: ['Super-Admin'] },
  // income & expenses
  { path: "/inventorySalesMaster", component: <InventorySales />, allowedRoles: ['Hotel']},
  { path: "/amountToOwner", component: <AmountToOwner />, allowedRoles: ['Hotel']},
  { path: "/balanceReport", component: <BalanceReport />, allowedRoles: ['Hotel']},
  { path: "/incomeAndExpense", component: <IncomeAndExpense />, allowedRoles: ['Hotel']},
  { path: "/inventoryStock", component: <InventoryStock />, allowedRoles: ['Hotel']},
  { path: "/pettyCashOverallReport", component: <PettyCashOverall />, allowedRoles: ['Hotel']},
  { path: "/pettyCash", component: <PettyCash />, allowedRoles: ['Hotel']},
  { path: "/allRoomsSales", component: <AllRoomsSales />, allowedRoles: ['Hotel']},
  // Reports
  { path: "/advanceBooking", component: <AdvanceBooking />, allowedRoles: ['Hotel']},
  { path: "/amountToOwnerReport", component: <AmountToOwnerReport />, allowedRoles: ['Hotel']},
  { path: "/cancelBooking", component: <CancelBooking />, allowedRoles: ['Hotel']},
  { path: "/checkInReport", component: <CheckInReport />, allowedRoles: ['Hotel']},
  { path: "/checkOutReport", component: <CheckOutReport />, allowedRoles: ['Hotel']},
  { path: "/gstReport", component: <GstReport />, allowedRoles: ['Hotel']},
  { path: "/guestReport", component: <GuestReport />, allowedRoles: ['Hotel']},
  { path: "/guestArrival", component: <GuestArrival />, allowedRoles: ['Hotel']},
  { path: "/houseKeeping", component: <HouseKeeping />, allowedRoles: ['Hotel']},
  { path: "/incomeAndExpenseReport", component: <IncomeAndExpenseReport />, allowedRoles: ['Hotel']},
  { path: "/incomeAndExpenseRoom", component: <IncomeAndExpenseRoom />, allowedRoles: ['Hotel']},
  { path: "/inventoryPurchase", component: <InventoryPurchase />, allowedRoles: ['Hotel']},
  { path: "/inventorySalesReport", component: <InventorySalesReport />, allowedRoles: ['Hotel']},
  { path: "/policeReport", component: <PoliceReport />, allowedRoles: ['Hotel']},
  { path: "/misCategoryReport", component: <MisCategoryReport />, allowedRoles: ['Hotel']},
  { path: "/nightAudit", component: <NightAudit />, allowedRoles: ['Hotel']},
  { path: "/overAllAdmin", component: <OverAllAdmin />, allowedRoles: ['Hotel']},
  { path: "/roomWiseGuest", component: <RoomWiseGuest />, allowedRoles: ['Hotel']},
  { path: "/print-design", component: <JobList />, allowedRoles: ['Hotel']},
];

const publicRoutes = [
  { path: "/register", component: <Register /> },
  { path: "/plan-detail", component: <PlanDetails /> },
  { path: "/otp", component: <OTPPage /> },
  { path: "/forgot-otp", component: <ForgotOTPComponent /> },
  { path: "/logout", component: <Logout /> },
  // { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/login", component: <Login /> },
  { path: "/", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/Recover-password", component: <ResetPasswordPage /> },
];


export {publicRoutes, authProtectedRoutes };