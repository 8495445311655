export const handlePrint = (totalData, totalLessAdvance, isRefund) => {
    const modalBody = document.querySelector('.modal-body');
    const printWindow = window.open('', '', 'width=595,height=842'); // A5 size in pixels (approx)

    // Ensure modalBody is found
    if (!modalBody) {
        console.error('Modal body not found');
        return;
    }

    // Get the required sections
    const guestDetails = modalBody.querySelector('.guest-details');
    const shiftRoomDetails = modalBody.querySelector('.shift-room-details');
    const transactionDetails = modalBody.querySelector('.transaction-details');

    if (!guestDetails || !transactionDetails) {
        console.error('One or more sections not found');
        return;
    }

    // Start building the print content
    let shiftRoomHTML = shiftRoomDetails ? `
        <div class="shift-room-details">
            ${shiftRoomDetails.outerHTML}
        </div>` : '';

    // Determine the amount type text
    const amountTypeText = isRefund ? 'Refund' : 'Received';

    printWindow.document.write(`
        <html>
            <head>
                <title>Print Modal</title>
                <style>
                    @media print {
                        body {
                            font-family: Arial, sans-serif;
                            margin: 0;
                            padding: 10px; /* Reduced padding for better fit */
                            font-size: 10px; /* Smaller font size for A5 */
                        }
                        .modal-body {
                            padding: 0;
                        }
                        .card {
                            margin-bottom: 10px;
                        }
                        .card-header {
                            background-color: #f7f7f7;
                            padding: 5px;
                            border-bottom: 1px solid #ddd;
                        }
                        .card-body {
                            padding: 10px;
                        }
                        .table {
                            width: 100%;
                            border-collapse: collapse;
                            font-size: 10px; /* Maintain smaller font for tables */
                        }
                        .table th, .table td {
                            border: 1px solid #ddd;
                            padding: 2px; /* Reduced padding for table cells */
                            text-align: left;
                        }
                        /* Hide buttons */
                        .btn {
                            display: none;
                        }
                        /* Layout for details */
                        .guest-details, .shift-room-details, .transaction-details {
                            width: 100%; /* Full width */
                            padding: 5px; /* Padding for content */
                            font-weight: normal; /* Normal text for details */
                            margin-bottom: 10px; /* Space between sections */
                        }
                        /* Title styling */
                        .section-title {
                            font-weight: bold;
                            margin-bottom: 5px; /* Space below titles */
                        }
                        /* Payment details alignment */
                        .payment-details {
                            display: flex;
                            justify-content: space-between;
                            margin-top: 10px;
                            font-weight: bold; /* Make payment details bold */
                        }
                    }
                </style>
            </head>
            <body>
                <div style="text-align: center; font-weight: bold; font-size: 14px; margin-bottom: 10px;">Guest History</div>
               
                <div class="guest-details">
                    ${guestDetails.outerHTML}
                </div>
                
                ${shiftRoomHTML} <!-- Conditionally rendered shift room details -->
               
                <div class="transaction-details">
                    ${transactionDetails.outerHTML}
                </div>
                <div class="payment-details">
                    <div>
                        <strong>Total:</strong> ${totalData}
                    </div>
                    <div>
                        <strong>Balance:</strong> ${Math.round(Math.abs(totalData - totalLessAdvance))}
                    </div>
                    <div>
                        <strong>Amount:</strong> ${amountTypeText}
                    </div>
                </div>
            </body>
        </html>
    `);
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
};
