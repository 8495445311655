import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormFeedback,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import '../../../assets/scss/custom/pages/booking.scss'
import { getAllRoomCheckinDetails, roomTypeRead, getUpdateRecord, getAllRoomStatus, getReservationNotify, getAllRoomTypes } from "../../../middleware/backent_helper";
import bedImage from './bedImage.png';

//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb";

//import Images
// import verification from "../../assets/images/verification-img.png";

// import {
//   addNewEvent as onAddNewEvent,
//   deleteEvent as onDeleteEvent,
//   getCategories as onGetCategories,
//   getEvents as onGetEvents,
//   updateEvent as onUpdateEvent,
// } from "../../store/actions";

// import DeleteModal from "./DeleteModal";

//css
import "@fullcalendar/bootstrap/main.css";

//redux
import { useSelector, useDispatch } from "react-redux";

const BookingPage = props => {

    //meta title
    document.title = "Calendar | Skote - React Admin & Dashboard Template";

    const dispatch = useDispatch();

    const [hid, setHid] = useState(() => {
        const storedHotelId = localStorage.getItem('hotelId')
        return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
    });
    const [dbName, setDbName] = useState(
        localStorage.getItem('dbName')
    );



    const [modal, setModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [modalcategory, setModalcategory] = useState(false);

    const [selectedDay, setSelectedDay] = useState(0);
    const [isEdit, setIsEdit] = useState(false);
    const [event, setEvent] = useState(null);

    useEffect(() => {
        // dispatch(onGetCategories());
        // dispatch(onGetEvents());
        new Draggable(document.getElementById("external-events"), {
            itemSelector: ".external-event",
        });
    }, [dispatch]);

    useEffect(() => {
        if (!modal && !isEmpty(event) && !!isEdit) {
            setTimeout(() => {
                setEvent({});
                setIsEdit(false);
            }, 500);
        }
    }, [modal, event]);

    /**
     * Handling the modal state
     */
    const toggle = () => {
        if (modal) {
            setModal(false);
            setEvent(null);
        } else {
            setModal(true);
        }
    };

    const toggleCategory = () => {
        setModalcategory(!modalcategory);
    };

    /**
     * Handling date click on calendar
     */
    const handleDateClick = arg => {
        const date = arg["date"];
        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();

        const currectDate = new Date();
        const currentHour = currectDate.getHours();
        const currentMin = currectDate.getMinutes();
        const currentSec = currectDate.getSeconds();
        const modifiedDate = new Date(
            year,
            month,
            day,
            currentHour,
            currentMin,
            currentSec
        );
        const modifiedData = { ...arg, date: modifiedDate };

        setSelectedDay(modifiedData);
        toggle();
    };

    /**
     * Handling click on event on calendar
     */
    const handleEventClick = arg => {
        const event = arg.event;
        setEvent({
            id: event.id,
            title: event.title,
            title_category: event.title_category,
            start: event.start,
            className: event.classNames,
            category: event.classNames[0],
            event_category: event.classNames[0],
        });
        setIsEdit(true);
        toggle();
    };

    /**
     * On delete event
     */
    const handleDeleteEvent = () => {
        if (event && event.id) {
            dispatch(onDeleteEvent(event.id));
        }
        setDeleteModal(false);
        toggle();
    };

    /**
     * On category darg event
     */
    const onDrag = event => {
        event.preventDefault();
    };


    /**
     * On calendar drop event
     */
    const onDrop = event => {
        const date = event['date'];
        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();

        const currectDate = new Date();
        const currentHour = currectDate.getHours();
        const currentMin = currectDate.getMinutes();
        const currentSec = currectDate.getSeconds();
        const modifiedDate = new Date(year, month, day, currentHour, currentMin, currentSec);

        const draggedEl = event.draggedEl;
        const draggedElclass = draggedEl.className;
        if (draggedEl.classList.contains('external-event') && draggedElclass.indexOf("fc-event-draggable") == -1) {
            const modifiedData = {
                id: Math.floor(Math.random() * 100),
                title: draggedEl.innerText,
                start: modifiedDate,
                className: draggedEl.className,
            };
            dispatch(onAddNewEvent(modifiedData));
        }
    };


    const [roomStatus, setRoomStatus] = useState(0);
    useEffect(() => {
        getAll();
    }, []);

    const getAll = () => {
        // Get Room-Types
        getAllRoomTypes(dbName)
            .then((res) => {

                setRoomsTypeList(res.data.data);
            });
        // Get Rooms
        getAllRoomCheckinDetails(hid, dbName)
            .then((res) => {
                setRoomsList(res.data.data);

                // Get cleaning status for each room with RoomStatusId == 3


                const cleaningStatusPromises = res.data.data
                    .filter(room => room.RoomStatusId === 3)
                    .map(room => getUpdateRecord(hid, dbName, room.bookingId)
                        .then(response => ({ bookingId: room.bookingId, cleaningStatus: response.data.data.Clean_status }))
                    );
                Promise.all(cleaningStatusPromises)
                    .then(cleaningStatusResults => {
                        const cleaningStatusMap = cleaningStatusResults.reduce((map, result) => {
                            map[result.bookingId] = result.cleaningStatus;
                            return map;
                        }, {});
                        setCleaningStatusMap(cleaningStatusMap);
                    })
                    .catch(error => console.error(error));

            });
        // Room Status list
        getAllRoomStatus(dbName)
            .then((res) => {
                setRoomStatus(res.data.data);
            });
    }

    const [roomsList, setRoomsList] = useState([]); // State to hold the room data

    useEffect(() => {
        // Function to fetch room data from the API
        const fetchRoomData = async () => {
            try {
                const response = await getAllRoomCheckinDetails(hid, dbName); // Replace with your API call
                setRoomsList(response.data.data); // Assuming your API returns the room data in this structure

            } catch (error) {
                console.error("Error fetching room data:", error);
            }
        };

        fetchRoomData();
    }, []);



    const [events, setEvents] = useState([]); // Events for FullCalendar

    const [cleaningStatusMap, setCleaningStatusMap] = useState({});
    const [RoomsTypeList, setRoomsTypeList] = useState([]);

    // Fetch data on component mount
    useEffect(() => {
        getAllRoomCheckinDetails(hid, dbName).then((res) => {
            setRoomsList(res.data.data); // Store the room data
            generateCalendarEvents(res.data.data); // Generate events for calendar
        });

        // Fetch room statuses
        getAllRoomStatus(dbName).then((res) => {
            setRoomStatus(res.data.data); // Store the room statuses
        });
    }, [hid, dbName]);

    // Generate FullCalendar events based on the room details
    const generateCalendarEvents = (room) => {

        const newEvent = {
            id: room.id,
            title: room.roomNo, // Room number as event title
            start: room.checkInDate, // Start date (check-in date)
            end: room.checkOutDate, // End date (check-out date)
            backgroundColor: room.colorCode,
            borderColor: room.colorCode,
            textColor: "white",
            extendedProps: {
                roomDetails: room, // Pass room details to the event
            },
        };

        setEvents([newEvent]); // Set this as the only event in the calendar
    };



    // Handle when a room card is clicked
    const handleRoomClick = (room) => {
        console.log("Room clicked:", room);
        generateCalendarEvents(room); // Generate the event for the clicked room
    };

    // Custom rendering for event content (e.g., room details)
    const renderEventContent = (eventInfo) => {
        const { event } = eventInfo;
        const { roomDetails } = event.extendedProps;
        const statusColor = roomDetails.colorCode; // Get the status color

        return (
            <div className="fc-event-card" style={{ backgroundColor: statusColor }}>
                <div className="fc-event-card-title">{event.title}</div>
                <div className="fc-event-card-details">
                    <p><strong>Room:</strong> {roomDetails.roomNo}</p>
                    <p><strong>Status:</strong> {roomDetails.RoomStatusId === 2 ? "Occupied" : "Available"}</p>
                    <p><strong>Tariff:</strong> {roomDetails.normalTariff}</p>
                    <p><strong>Check-in:</strong> {roomDetails.checkInDate}</p>
                    <p><strong>Check-out:</strong> {roomDetails.checkOutDate}</p>
                </div>
            </div>
        );
    };



    return (
        <React.Fragment>

            <div className="page-content">
                <Container fluid={true}>

                    <Row>
                        <Col className="col-12">
                            <Row>
                                <Col lg={3}>
                                    <div id="external-events" className="scrollable-container mt-2">
                                        {roomsList.map((room, index) => (
                                            <Card key={room.id} className="booking-card" onClick={() => handleRoomClick(room)} >
                                                <CardBody className="booking-card-body position-relative">
                                                    <div className="booking-card-headers">
                                                        <div className="booking-card-title">
                                                            <h3 className="booking-room-type-title">{room.title.toUpperCase()}</h3>
                                                        </div>
                                                    </div>
                                                    <h3 className="booking-room-title">{room.roomNo || `Room ${index + 1}`}</h3>
                                                    <h5 className="booking-room-tariff">{room.normalTariff}</h5>
                                                    <div className="booking-card-image-container">
                                                        <img src={bedImage} alt="Room Type Image" className="booking-card-image" />
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        ))}
                                    </div>
                                </Col>

                                <Col lg={9}>
                                    <div style={{ position: 'relative', height: '600px' }}>
                                        <FullCalendar
                                            className="fixed-calendar"
                                            plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin]}
                                            slotDuration={"00:15:00"}
                                            themeSystem="bootstrap"
                                            initialView="dayGridMonth"
                                            editable={true}
                                            selectable={true}
                                            events={events} // Only the event for the clicked room will be shown
                                            eventContent={renderEventContent} // Render custom event content
                                            eventClick={(info) => {
                                                alert(
                                                    "Room: " +
                                                    info.event.title +
                                                    " Status: " +
                                                    info.event.extendedProps.roomDetails.RoomStatusId
                                                );
                                            }}

                                        />
                                    </div>



                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

BookingPage.propTypes = {
    events: PropTypes.array,
    categories: PropTypes.array,
    className: PropTypes.string,
    onGetEvents: PropTypes.func,
    onAddNewEvent: PropTypes.func,
    onUpdateEvent: PropTypes.func,
    onDeleteEvent: PropTypes.func,
    onGetCategories: PropTypes.func,
};

export default BookingPage;
