import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../../components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import Select from 'react-select';
import * as Yup from "yup";
import { useFormik } from "formik";
import { postRoomType, getAllRoomType, putRoomType, deleteRoomType, getTax, getHotelById } from "../../../middleware/backent_helper";
import { RoomType, TotalGuest, Tax, Amenities, SingleTariff, NormalTariff, ExtraPersonTariff, ExtraChild, ExtraGuest } from "./RoomTypesCol";
import messages from '../../../utils/constants/messages';
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";
import AlertModal from "../../../components/Common/alertModal";
import ErrorAlertModal from "../../../components/Common/errorAlertModal";
//redux
import { useSelector, useDispatch } from "react-redux";

const RoomTypes = props => {
  //meta title
  document.title = "Room Types | Settings ";

  const dispatch = useDispatch();
  const [roomtypeData, setRoomtypeData] = useState();
  const [Hotel, setHotel] = useState([]);
  const [taxList, setTaxList] = useState([]);
  const [selectedTax, setSelectedTax] = useState(null);
  const [roomType, setRoomType] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [errorAlertModal, setErrorAlertModal] = useState(false);
  const [response, setResponse] = useState(false);
  const [masterName, setMasterName] = useState("Room Type");
  const [hid, setHid] = useState(() => {
    const storedHotelId = localStorage.getItem('hotelId')
    return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
  });
  const [dbName, setDbName] = useState(
    localStorage.getItem('dbName')
  )
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    const hotelId = localStorage.getItem('hotelId');
    if (hotelId) {
      getHotelById(hotelId)
        .then(response => {
          const hotelData = response.data.data;
          setHotel(hotelData)
        })
    }
  }, []);

  useEffect(() => {
    if (isEdit && roomtypeData && typeof roomtypeData.isActive !== 'undefined') {
      setIsActive(roomtypeData.isActive);
    }
  }, [isEdit, roomtypeData]);

  const toggleactive = () => {
    const updatedIsActive = !isActive;
    setIsActive(updatedIsActive);
    validation.setFieldValue('isActive', updatedIsActive);
  };

  const handleCheckboxChange = () => {
    toggleactive();
  };

  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        Cell: ({ row, rows }) => {
          return (
            <div className="table-cell text-center">
              {rows.length - row.index}
            </div>
          );
        },
      },

      {
        Header: "Room Types",
        accessor: "title",
        filterable: true,
        Cell: cellProps => {
          return (
            <div className="table-cell text-center">
              <RoomType {...cellProps} />
            </div>
          );

        },
      },

      {
        Header: "Total Guest",
        accessor: "totalGuest",
        filterable: true,
        Cell: cellProps => {
          return (
            <div className="table-cell text-center">
              <TotalGuest {...cellProps} />
            </div>
          );

        },
      },

      {
        Header: "Extra Guest",
        accessor: "extraGuest",
        filterable: true,
        Cell: cellProps => {
          return (
            <div className="table-cell text-center">
              <ExtraGuest {...cellProps} />
            </div>
          );

        },
      },

      {
        Header: "Extra Child",
        accessor: "extraChild",
        filterable: true,
        Cell: cellProps => {
          return (
            <div className="table-cell text-center">
              <ExtraChild {...cellProps} />
            </div>
          );

        },
      },

      {
        Header: "Single Tariff",
        accessor: "singleTariff",
        filterable: true,
        Cell: cellProps => {
          return (
            <div className="table-cell text-center">
              <SingleTariff {...cellProps} />
            </div>
          );

        },
      },

      {
        Header: "Normal Tariff",
        accessor: "normalTariff",
        filterable: true,
        Cell: cellProps => {
          return (
            <div className="table-cell text-center">
              <NormalTariff {...cellProps} />
            </div>
          );

        },
      },

      {
        Header: "Extra Person Tariff",
        accessor: "extraPersonTariff",
        filterable: true,
        Cell: cellProps => {
          return (
            <div className="table-cell text-center">
              <ExtraPersonTariff {...cellProps} />
            </div>
          );

        },
      },

      {
        Header: "Tax",
        accessor: "taxName",
        filterable: true,
        Cell: cellProps => {
          return (
            <div className="table-cell text-center">
              <Tax {...cellProps} />
            </div>
          );

        },
      },

      {
        Header: "Amenities",
        accessor: "amenities",
        filterable: true,
        Cell: cellProps => {
          return (
            <div className="table-cell text-center">
              <Amenities {...cellProps} />
            </div>
          );

        },
      },

      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="table-body actions">
              <div className="actions">
                <Link
                  to="#"
                  className="text-success"
                  onClick={() => {
                    const data = cellProps.row.original;
                    handleRoomTypeClick(data);
                  }}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const Data = cellProps.row.original;
                    onClickDelete(Data);
                  }}
                >
                  <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </div>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    RoomTypeList();
    GetTaxList();
  }, [])

  const RoomTypeList = () => {
    getAllRoomType(hid, dbName)
      .then((res) => {
        setRoomType(res.data.data);
      });
  };


  const GetTaxList = () => {
    getTax(hid, dbName)
      .then((res) => {
        setTaxList(res.data.data);
      });
  };

  const clearFormValues = () => {
    validation.resetForm();
  };

  const toggle = () => {
    if (modal) {
      setModal(false);
      setRoomtypeData(null);
      clearFormValues();

    } else {
      setModal(true);
    }
  };

  const handleRoomTypeClick = arg => {
    const room_type = arg;

    setSelectedTax({ value: room_type.taxId, label: room_type.taxName });

    const singtariff = room_type.singleTariff.split(' - ')[0].replace('₹ ', '');
    const singmaxTariff = room_type.singleTariff.split(' - ')[1].replace('₹ ', '');
    const Normtariff = room_type.normalTariff.split(' - ')[0].replace('₹ ', '');
    const NormmaxTariff = room_type.normalTariff.split(' - ')[1].replace('₹ ', '');
    const Extratariff = room_type.extraPersonTariff.split(' - ')[0].replace('₹ ', '');
    const ExtraMaxtariff = room_type.extraPersonTariff.split(' - ')[1].replace('₹ ', '');

    setRoomtypeData({
      id: room_type.id,
      title: room_type.title,
      totalGuest: room_type.totalGuest,
      extraGuest: room_type.extraGuest,
      extraChild: room_type.extraChild,
      singtariff,
      singmaxTariff,
      Normtariff,
      NormmaxTariff,
      Extratariff,
      ExtraMaxtariff,
      taxId: room_type.taxId,
      amenities: room_type.amenities,
      hotelId: hid,
      dbName: dbName,
    });

    setIsEdit(true);
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = data => {
    setRoomtypeData(data);

    setDeleteModal(true);
  };

  const handleTaxChange = selectedOption => {
    setSelectedTax(selectedOption);
    validation.setFieldValue('taxId', selectedOption ? selectedOption.value : '');
  };

  const handleDeleteRoomType = () => {
    deleteRoomType(roomtypeData.id, dbName)
      .then((res) => {
        getAllRoomType(hid, dbName), RoomTypeList()
        setResponse(messages.success.deleteDetails(masterName))
        setAlertModal(true)
      })
      .catch((err) => {
        if (err.response.status == 400) {
          setResponse(messages.failed.cannotbeDeleted(masterName))
        } else {
          setResponse(messages.failed.updateFailed(masterName))
        }
        setErrorAlertModal(true)
      });
    onPaginationPageChange(1);
    setRoomtypeData(null);
    clearFormValues();
    setDeleteModal(false);
  };

  const handleClick = () => {
    setIsEdit(false);
    toggle();
  };

  const validation = useFormik({
    initialValues: {
      title: roomtypeData ? roomtypeData.title : "",
      totalGuest: roomtypeData ? roomtypeData.totalGuest : "",
      extraGuest: roomtypeData ? roomtypeData.extraGuest : "",
      extraChild: roomtypeData ? roomtypeData.extraChild : "",
      singtariff: roomtypeData ? roomtypeData.singtariff : "",
      singmaxTariff: roomtypeData ? roomtypeData.singmaxTariff : "",
      Normtariff: roomtypeData ? roomtypeData.Normtariff : "",
      NormmaxTariff: roomtypeData ? roomtypeData.NormmaxTariff : "",
      Extratariff: roomtypeData ? roomtypeData.Extratariff : "",
      ExtraMaxtariff: roomtypeData ? roomtypeData.ExtraMaxtariff : "",
      taxId: roomtypeData ? roomtypeData.taxId : "",
      amenities: roomtypeData ? roomtypeData.amenities : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Your Room Type"),
      totalGuest: Yup.string().required("Please Enter Your Total Guest"),
      extraGuest: Yup.string().required("Please Enter Your Extra Guest"),
      extraChild: Yup.string().required("Please Enter Your Extra Child"),
      singtariff: Yup.string().required('Please Enter Tariff'),
      singmaxTariff: Yup.string().required('Please Enter Max Tariff'),
      Normtariff: Yup.string().required('Please Enter Tariff'),
      NormmaxTariff: Yup.string().required('Please Enter Max Tariff'),
      Extratariff: Yup.string().required('Please Enter Tariff'),
      ExtraMaxtariff: Yup.string().required('Please Enter Max Tariff'),
      taxId: Yup.string().required("Please Choose Tax"),
      amenities: Yup.string().required("Please Enter Your Amenities"),
    }),
    onSubmit: (values) => {
      let currencySymbol = '';
      if (Hotel && Hotel.currency) {
        currencySymbol = Hotel.currency.match(/\((.*?)\)/)[1];
      }
      if (isEdit) {
        const combinedSingTariff = `${currencySymbol} ${values.singtariff} - ${currencySymbol} ${values.singmaxTariff}`;
        const combinedNormTariff = `${currencySymbol} ${values.Normtariff} - ${currencySymbol} ${values.NormmaxTariff}`;
        const combinedExtraTariff = `${currencySymbol} ${values.Extratariff} - ${currencySymbol} ${values.ExtraMaxtariff}`;
        const updatedRoomtype = { ...roomtypeData, ...values, singleTariff: combinedSingTariff, normalTariff: combinedNormTariff, extraPersonTariff: combinedExtraTariff };
        putRoomType(updatedRoomtype)
          .then((res) => {
            setResponse(messages.success.updatedDetails(masterName))
            setAlertModal(true)
          })
          .catch((err) => {
            if (err.response.status === 401) {
              setResponse(messages.failed.alreadyInUse(masterName))
            } else {
              setResponse(messages.failed.updateFailed(masterName))
            }
            setErrorAlertModal(true)
          });
        toggle();
      } else {
        const combinedSingTariff = `${currencySymbol} ${values.singtariff} - ${currencySymbol} ${values.singmaxTariff}`;
        const combinedNormTariff = `${currencySymbol} ${values.Normtariff} - ${currencySymbol} ${values.NormmaxTariff}`;
        const combinedExtraTariff = `${currencySymbol} ${values.Extratariff} - ${currencySymbol} ${values.ExtraMaxtariff}`;
        dispatch(Create_New_Room_Type({ ...values, singleTariff: combinedSingTariff, normalTariff: combinedNormTariff, extraPersonTariff: combinedExtraTariff }));
      }
    }
  });

  const Create_New_Room_Type = (values) => {
    const data = JSON.stringify({
      isActive: true,
      title: values.title,
      totalGuest: values.totalGuest,
      extraGuest: values.extraGuest,
      extraChild: values.extraChild,
      singleTariff: values.singleTariff,
      normalTariff: values.normalTariff,
      extraPersonTariff: values.extraPersonTariff,
      taxId: values.taxId,
      amenities: values.amenities,
      hotelId: hid,
      dbName: dbName,
      isActive: true,
    });
    postRoomType(data).then((res) => {
      if (res.data.success) {
        setResponse(messages.success.createdMessage(masterName))
        setAlertModal(true)
      }
    }).catch(err => {
      if (err.response.status === 404) {
        setResponse(messages.failed.AlreadyExists(masterName))
      } else {
        setResponse(messages.failed.createFailed(masterName))
      }
      setErrorAlertModal(true)
    });
    toggle();
  };

  //last two digit fix function
  const formatToTwoDecimalPlaces = (value) => {
    const number = parseFloat(value);
    return isNaN(number) ? "" : number.toFixed(2);
  };

  const handleBlur = (event) => {
    validation.handleBlur(event);
    const formattedValue = formatToTwoDecimalPlaces(event.target.value);
    validation.setFieldValue(event.target.name, formattedValue);
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteRoomType}
        onCloseClick={() => { setRoomtypeData(null), setDeleteModal(false) }}
      />
      <AlertModal
        show={alertModal}
        onOkClick={() => {
          RoomTypeList();
          setAlertModal(false);
        }}
        onCloseClick={() => setAlertModal(false)}
        res={response}
      />
      <ErrorAlertModal
        show={errorAlertModal}
        onOkClick={() => { RoomTypeList(), setErrorAlertModal(false) }}
        onCloseClick={() => setErrorAlertModal(false)}
        res={response}
      />

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Settings" breadcrumbItem="Room Types" />
          <Row>
            <Col lg="12">

              <TableContainer
                columns={columns}
                data={roomType}
                isGlobalFilter={true}
                isAddRoomTypeList={true}
                handleRoomTypeClick={handleClick}
                customPageSize={10}
                className="custom-header-css"
              />

              <Modal className="modal-lg" isOpen={modal} toggle={toggle} backdrop="static" keyboard={false}>
                <ModalHeader toggle={toggle} tag="h4">
                  {!!isEdit ? "Edit Room Type" : "Add Room Type"}
                </ModalHeader>
                <ModalBody>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      <Col xs={12}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="testName" >Room Type</Label>
                          <Input
                            name="title"
                            type="text"
                            placeholder="Insert Room Type"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.title || ""}
                            invalid={
                              validation.touched.title &&
                                validation.errors.title
                                ? true
                                : false
                            }
                          />
                          {validation.touched.title &&
                            validation.errors.title ? (
                            <FormFeedback type="invalid">
                              {validation.errors.title}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={4}>
                        <div className="mb-3">
                          <Label className="form-label">Total Guest</Label>
                          <Input
                            name="totalGuest"
                            type="number"
                            placeholder="Total Guest"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.totalGuest || ""}
                            invalid={
                              validation.touched.totalGuest &&
                                validation.errors.totalGuest
                                ? true
                                : false
                            }
                            min="0"
                          />
                          {validation.touched.totalGuest &&
                            validation.errors.totalGuest ? (
                            <FormFeedback type="invalid">
                              {validation.errors.totalGuest}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col xs={4}>
                        <div className="mb-3">
                          <Label className="form-label">Extra Guest</Label>
                          <Input
                            name="extraGuest"
                            type="number"
                            placeholder="Extra Guest"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.extraGuest || ""}
                            invalid={
                              validation.touched.extraGuest &&
                                validation.errors.extraGuest
                                ? true
                                : false
                            }
                            min="0"
                          />
                          {validation.touched.extraGuest &&
                            validation.errors.extraGuest ? (
                            <FormFeedback type="invalid">
                              {validation.errors.extraGuest}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col xs={4}>
                        <div className="mb-3">
                          <Label className="form-label">Extra Child</Label>
                          <Input
                            name="extraChild"
                            type="number"
                            placeholder="Extra Child"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.extraChild || ""}
                            invalid={
                              validation.touched.extraChild &&
                                validation.errors.extraChild
                                ? true
                                : false
                            }
                            min="0"
                          />
                          {validation.touched.extraChild &&
                            validation.errors.extraChild ? (
                            <FormFeedback type="invalid">
                              {validation.errors.extraChild}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <label className="form-label" htmlFor="tariff" style={{ fontSize: '14px', fontWeight: 'bold', textAlign: 'center' }}>Single Tariff</label>
                      <Col xs={6}>

                        <div className="mb-3">

                          <label className="form-label" htmlFor="tariff">Tariff</label>
                          <Input
                            type="text"
                            name="singtariff"
                            pattern="[0-9]+(\.[0-9]{1,2})?"
                            placeholder="Insert Tariff"
                            min={0}
                            value={validation.values.singtariff || ""}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={validation.touched.singtariff && validation.errors.singtariff}
                          />
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="maxTariff">Maximum Tariff</label>
                          <Input
                            type="text"
                            pattern="[0-9]+(\.[0-9]{1,2})?"
                            name="singmaxTariff"
                            placeholder="Insert Maximum Tariff"
                            min={0}
                            value={validation.values.singmaxTariff || ""}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={validation.touched.singmaxTariff && validation.errors.singmaxTariff}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <label className="form-label " htmlFor="tariff" style={{ fontSize: '14px', fontWeight: 'bold', textAlign: 'center' }}>Normal Tariff</label>
                      <Col xs={6}>

                        <div className="mb-3">

                          <label className="form-label" htmlFor="tariff">Tariff</label>
                          <Input
                            type="text"
                            pattern="[0-9]+(\.[0-9]{1,2})?"
                            name="Normtariff"
                            placeholder="Insert Tariff"
                            min={0}
                            value={validation.values.Normtariff || ""}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={validation.touched.Normtariff && validation.errors.Normtariff}
                          />
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="maxTariff">Maximum Tariff</label>
                          <Input
                            type="text"
                            name="NormmaxTariff"
                            pattern="[0-9]+(\.[0-9]{1,2})?"
                            placeholder="Insert Maximum Tariff"
                            min={0}
                            value={validation.values.NormmaxTariff || ""}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={validation.touched.NormmaxTariff && validation.errors.NormmaxTariff}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <label className="form-label" htmlFor="tariff" style={{ fontSize: '14px', fontWeight: 'bold', textAlign: 'center' }}>Extra Person Tariff</label>
                      <Col xs={6}>

                        <div className="mb-3">

                          <label className="form-label" htmlFor="tariff">Tariff</label>
                          <Input
                            type="text"
                            name="Extratariff"
                            pattern="[0-9]+(\.[0-9]{1,2})?"
                            placeholder="Insert Tariff"
                            min={0}
                            value={validation.values.Extratariff || ""}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={validation.touched.Extratariff && validation.errors.Extratariff}
                          />
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="maxTariff">Maximum Tariff</label>
                          <Input
                            type="text"
                            pattern="[0-9]+(\.[0-9]{1,2})?"
                            name="ExtraMaxtariff"
                            placeholder="Insert Maximum Tariff"
                            min={0}
                            value={validation.values.ExtraMaxtariff || ""}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={validation.touched.ExtraMaxtariff && validation.errors.ExtraMaxtariff}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={6}>
                        <div className="mb-3" style={{ position: 'relative' }}>
                          <label htmlFor="testName" className="form-label">Tax</label>
                          <Select
                            id="taxId"
                            name="taxId"
                            options={taxList.map(tax => ({ value: tax.id, label: tax.taxName }))}
                            placeholder="Select a Tax"
                            value={selectedTax}
                            onChange={handleTaxChange}
                            isClearable
                            isSearchable
                            classNamePrefix="react-select"
                            className={`react-select-containers ${validation.touched.taxId && validation.errors.taxId ? "is-invalid" : ""}`}
                            styles={{
                              menu: provided => ({ ...provided, top: '100%', position: 'absolute', width: '100%', zIndex: 999 }),
                            }}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.taxId && validation.errors.taxId
                            }
                          />
                          {validation.touched.taxId && validation.errors.taxId ? (
                            <FormFeedback type="invalid">
                              {validation.errors.taxId}
                            </FormFeedback>
                          ) : null}
                          
                        </div>
                      </Col>



                      <Col xs={6}>
                        <div className="mb-3">
                          <Label className="form-label">Amenities</Label>
                          <Input
                            name="amenities"
                            type="text"
                            placeholder="Insert Amenities"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.amenities || ""}
                            invalid={
                              validation.touched.amenities &&
                                validation.errors.amenities
                                ? true
                                : false
                            }
                          />
                          {validation.touched.amenities &&
                            validation.errors.amenities ? (
                            <FormFeedback type="invalid">
                              {validation.errors.amenities}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                    </Row>


                    <Row>
                      <Col>

                        <div className="text-end">

                          <button
                            type="submit"
                            className="btn btn-primary save-user w-md me-3"
                          >
                            {!!isEdit ? "Update" : "Save"}
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary w-md "
                            onClick={toggle}
                          >
                            Cancel
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </ModalBody>
              </Modal>

            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment >
  );
};


export default withRouter(RoomTypes);
