import React, { useState, useEffect, useMemo } from "react"
import {
    Modal,
    ModalBody,
    Form,
    Row,
    Col,
    Label, Card, CardBody, CardHeader, Table,
    FormGroup,

} from "reactstrap"
import DatePicker from "react-datepicker";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getRoomService, get_record } from '../../middleware/backent_helper'
import Select from 'react-select';



const MaintenanceHistory = ({ show, onCloseClick }) => {

    const [hid, setHid] = useState(() => {
        const storedHotelId = localStorage.getItem('hotelId')
        return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
    });
    const [dbName, setDbName] = useState(
        localStorage.getItem('dbName')
    )

    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [servicePersonList, setServicePersonList] = useState([]);
    const [selectedTest, setSelectedTest] = useState(null);
    const [employeeList, setEmployeeList] = useState([]);
    const [showTable, setShowTable] = useState(false);
    const [noEmpty, setNoEmpty] = useState(false);

    useEffect(() => {
        getRoomService(hid, dbName)
            .then((res) => {
                setServicePersonList(res.data.data);
            });


    }, [])

    const handleServicePerson = selectedOption => {
        if (selectedOption.value === '001') {
            setSelectedTest({ value: '001', label: 'All Employee' });
            validation.setFieldValue('servicePersonId', '001');
        } else {
            setSelectedTest(selectedOption);
            validation.setFieldValue('servicePersonId', selectedOption.value);
        }
    };

    useEffect(() => {
        if (show) {

            setFromDate(null);
            setToDate(null);
            setSelectedTest(null);
            setEmployeeList([]);
            validation.resetForm();
        }
    }, [show]);

    const validation = useFormik({
        initialValues: {
            servicePersonId: "",

        },
        enableReinitialize: true,
        validationSchema: Yup.object({

            servicePersonId: Yup.string().required("Please Choose service person"),
        }),
        onSubmit: (values) => {
            Get_record(values)
            validation.resetForm();
            setSelectedTest(null)


        }
    });

    const Get_record = (values) => {

        const servicePerson = values.servicePersonId
        const FromDate = fromDate
        const ToDate = toDate

        get_record(dbName, servicePerson, FromDate, ToDate).then((res) => {
            setEmployeeList(res.data.data);


        })
};

return (
        <React.Fragment>

            <Modal size="lg" isOpen={show} toggle={onCloseClick} centered={true}>
                <ModalBody className="px-4 py-5">
                    <button type="button" onClick={onCloseClick} className="btn-close position-absolute end-0 top-0 m-3"></button>
                    <h5 className="mb-4">House Keeping History</h5>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >

                        <Row>

                            <Col xs={2}>
                                <div className="mb-3">
                                    <Label className="form-label">From Date</Label>
                                    <DatePicker
                                        name="fromDate"
                                        selected={fromDate}
                                        onChange={(date) => setFromDate(date)}
                                        className="form-control"
                                        dateFormat="d-MM-yyyy"
                                        placeholderText="Select date"
                                        timeFormat="HH:mm"
                                        timeIntervals={1}
                                    />
                                </div>
                            </Col>
                            <Col xs={2}>
                                <div className="mb-3">
                                    <Label className="form-label">To Date</Label>
                                    <DatePicker
                                        name="toDate"
                                        selected={toDate}
                                        onChange={(date) => setToDate(date)}
                                        className="form-control"
                                        dateFormat="d-MM-yyyy"
                                        placeholderText="Select date"
                                        timeFormat="HH:mm"
                                        timeIntervals={1}
                                    />
                                </div>
                            </Col>

                            <Col xs={5}>
                                <div className="mb-3" style={{ position: 'relative' }}>
                                    <label htmlFor="testName" className="form-label">Employee </label>
                                    <Select
                                        id="servicePersonId"
                                        name="servicePersonId"
                                        options={[
                                            { value: '001', label: 'All Employee' }, // Add this option
                                            ...servicePersonList.map(type => ({ value: type.id, label: type.name })),
                                        ]}
                                        placeholder="Select a service person "
                                        value={selectedTest}
                                        formatOptionLabel={(option) => option.label}
                                        onChange={handleServicePerson}
                                        isClearable
                                        isSearchable
                                        classNamePrefix="custom-select"
                                        styles={{
                                            menu: provided => ({ ...provided, top: '100%', position: 'absolute', width: '100%', zIndex: 999 }),
                                        }}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                            validation.touched.servicePersonId && validation.errors.servicePersonId
                                        }
                                    />
                                    {validation.touched.servicePersonId && validation.errors.servicePersonId && (
                                        <div className="text-danger">{validation.errors.servicePersonId}</div>
                                    )}
                                </div>
                            </Col>


                            <Col>

                                <div className="text-left">
                                    <button
                                        type="submit"
                                        className="btn btn-primary w-md me-3 mt-4"
                                    >
                                        Search
                                    </button>
                                </div>
                            </Col>

                        </Row>
                    </Form>
                </ModalBody>


                <Card>

                    <CardBody>
                        <Table bordered responsive>
                            <thead>
                                <tr>
                                    <th className="text-center">Room No</th>
                                    <th className="text-center">Date</th>
                                    <th className="text-center">Time</th>
                                    <th className="text-center">Employee</th>
                                    <th className="text-center">Status</th>
                                    <th className="text-center">Remarks</th>
                                </tr>
                            </thead>
                            <tbody>
                                {employeeList.map((item, index) => (
                                    <tr key={index}>
                                        <td className="text-center">{item.Room_No}</td>
                                        <td className="text-center">{item.Date}</td>
                                        <td className="text-center">{item.Time}</td>
                                        <td className="text-center">{item.Employee}</td>
                                        <td className="text-center">{item.Status}</td>
                                        <td className="text-center">{item.Remarks}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>


                    </CardBody>
                </Card>

            </Modal>
        </React.Fragment>
    );
}


export default MaintenanceHistory;