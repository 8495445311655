import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, CardBody, CardTitle, Container, Row, Col, Label, Input, Table, FormFeedback, Form } from 'reactstrap';
import Select from 'react-select';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import AlertModal from "../../../components/Common/alertModal";
import ErrorAlertModal from "../../../components/Common/errorAlertModal";
import messages from '../../../utils/constants/messages';
import { getPaymentTypes, getCheckoutDetails, getRoomService, checkOutGuest, getAdvanceById, getExtraGuestById, get_shiftRoom_history, getCheckoutServiceDetails } from "../../../middleware/backent_helper";
import "../CheckOut/checkout.css";
import { useFormik } from 'formik';
import * as Yup from 'yup';
//redux
import { useDispatch } from "react-redux";

const Create_Group_CheckOut = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { bookingId } = location.state || {};
  const [hid, setHid] = useState(() => {
    const storedHotelId = localStorage.getItem('hotelId')
    return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
  });
  const [dbName, setDbName] = useState(
    localStorage.getItem('dbName')
  )

  const [selectedtimeFormat, setSelectedtimeFormat] = useState("24 Hour Checkout");
  const [paymentTypeList, setPaymentTypes] = useState([]);
  const [selectedPayment, setselectedPayment] = useState(null);
  const [roomService, setRoomServices] = useState([]);
  const [selectedRoomService, setselectedroomService] = useState(null);
  const [bookingDetails, setBookingDetails] = useState(null);
  const [advanceDetails, setAdvanceDetails] = useState([]);
  const [ExtraGuestDetails, setExtraGuestDetails] = useState([]);
  const [discount, setDiscount] = useState('');
  const [totalCharges, setTotalCharges] = useState(0);
  const [guestCharges, setGuestCharges] = useState(0);
  const [gstCharges, setGstCharges] = useState(0);
  const [discountedCharges, setDiscountedCharges] = useState(0);
  const [error, setError] = useState('');
  const [alertModal, setAlertModal] = useState(false);
  const [errorAlertModal, setErrorAlertModal] = useState(false);
  const [response, setResponse] = useState(false);
  const [balanceLable, setBalanceLable] = useState("Received");
  const [balance, setBalance] = useState(0);
  const [tariff, setTariff] = useState(0);
  const [guestTariff, setGuestTariff] = useState(0);
  const [pax, setPax] = useState(0);
  const [graceTime, setGraceTime] = useState(0);
  const [description, setDescription] = useState('');
  const [ShiftDetails, setShiftDetails] = useState([]);
  const [oldRoomTotal, setoldRoomTotal] = useState(0);
  const [oldRoomAdvance, setoldRoomadvance] = useState(0);
  const [roomServiceCharge, setRoomServiceCharge] = useState(0);

  const timeFormatMethod = [
    { value: '12 Hour Checkout', label: '12-hour' },
    { value: '24 Hour Checkout', label: '24-hour' }
  ];

  useEffect(() => {
    getAll();
  }, []);


  const getAll = () => {

    getCheckoutDetails(hid, dbName, bookingId)
      .then((res) => {
        setBookingDetails(res.data.data[0]);
      })

    getPaymentTypes(hid, dbName)
      .then((res) => {
        setPaymentTypes(res.data.data);
      });

    getRoomService(hid, dbName)
      .then((res) => {
        setRoomServices(res.data.data);
      });

    getAdvanceById(hid, dbName, bookingId)
      .then((res) => {
        setAdvanceDetails(res.data.data);
      });

    getExtraGuestById(hid, dbName, bookingId)
      .then((res) => {
        setExtraGuestDetails(res.data.data);
      });

    getCheckoutServiceDetails(hid, dbName, bookingId)
      .then((res) => {
        setRoomServiceCharge(res.data.data);
      });

    get_shiftRoom_history(hid, dbName, bookingId)
      .then((res) => {
        setShiftDetails(res.data.data);
      });
     
  }

  useEffect(() => {
    if (ShiftDetails && Object.keys(ShiftDetails).length > 0) {
      Shift_Room_Guest_Tariff();
    }
  }, [ShiftDetails]);

  const Shift_Room_Guest_Tariff = () => {
    const data = ShiftDetails;

    // Set the old room advance
    setoldRoomadvance(data.Advance);
    // Calculate the total room charge
    const oldRoomCharge = data.Total + data.ExtraPerson + data.ServiceCharge;
    // Set the total room charge
    setoldRoomTotal(oldRoomCharge);
  };

  const handleTimeFormat = selectedOption => {
    setSelectedtimeFormat(selectedOption);
  };

  const handlePaymentTypeChange = (selectedOption) => {
    setselectedPayment(selectedOption)
    if (selectedOption && selectedOption.value) {
      formik.setFieldValue('payment', selectedOption.value)
    } else {
      formik.setFieldValue('payment', '')
    }
  };

  const handleRoomSerivce = (selectedOption) => {
    setselectedroomService(selectedOption)
    if (selectedOption && selectedOption.value) {
      formik.setFieldValue('checkoutBy', selectedOption.value)
    } else {
      formik.setFieldValue('checkoutBy', '')
    }
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedtimeFormat(selectedOption)
    if (selectedOption && selectedOption.label) {
      formik.setFieldValue('checkoutMethod', selectedOption.label)
    } else {
      formik.setFieldValue('checkoutMethod', '')
    }
  };

  const parseDateString = (dateStr) => {
    // Convert the format 'YYYY-MM-DD HH:MM:SS' to 'YYYY-MM-DDTHH:MM:SS'
    const isoDateStr = dateStr.replace(' ', 'T') + 'Z'; // Add 'Z' to indicate UTC
    return new Date(isoDateStr);
  };

  const calculateTimeDifference = (checkIn, checkOut, graceTime,) => {
    if (!checkIn || !checkOut) {
      // Return default values if dates are invalid
      return { days: 0 };
    }

    // Parse the dates
    const date1 = parseDateString(checkIn);
    const date2 = parseDateString(checkOut);
    const graceTimeInMilliseconds = graceTime * 60 * 60 * 1000;
    const timeDifference = date2 - date1;
    const adjustedTimeDifference = timeDifference - graceTimeInMilliseconds;

    // Convert time difference from milliseconds to days and hours
    const millisecondsPerDay = 1000 * 60 * 60 * 24;
    const millisecondsPerHour = 1000 * 60 * 60;
    // const daysDifference = Math.ceil(timeDifference / millisecondsPerDay);
    const daysDifference = Math.ceil(adjustedTimeDifference / millisecondsPerDay);

    return {
      days: daysDifference,
      // hours: hoursDifference,
    };
  };

  const Extra_Guest_calculateTimeDifference = (checkIn, checkOut, graceTime) => {
    if (!checkIn || !checkOut) {
      // Return default values if dates are invalid
      return { days: 0 };
    }

    // Parse the dates
    const date1 = parseDateString(checkIn);
    const date2 = parseDateString(checkOut);
    const graceTimeInMilliseconds = graceTime * 60 * 60 * 1000;
    const timeDifference = date2 - date1;
    const adjustedTimeDifference = timeDifference - graceTimeInMilliseconds;

    // Convert time difference from milliseconds to days
    const millisecondsPerDay = 1000 * 60 * 60 * 24;
    const daysDifference = Math.ceil(adjustedTimeDifference / millisecondsPerDay);

    return {
      days: daysDifference,
    };


  };

  useEffect(() => {
    if (ExtraGuestDetails.length > 0) {
      Extra_Guest_Tariff();
    }
  }, [ExtraGuestDetails]);

  const Extra_Guest_Tariff = () => {
    if (ExtraGuestDetails.length) {
      const now = new Date();
      const currentDate = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')} ${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`;

      let total_guest = 0; // To store the total extra guest tariff

      // Loop through each guest detail in ExtraGuestDetails
      ExtraGuestDetails.forEach((guest) => {
        const guest_Tariff = guest.tariff;
        const checkIn = guest.checkInDate; // Assuming each guest has a check-in date
        const { days } = Extra_Guest_calculateTimeDifference(checkIn, currentDate, graceTime); // Calculate time difference for each guest
        const Extra_Guest = days * guest_Tariff; // Calculate tariff for each guest
        total_guest += Extra_Guest;
      });

      setGuestCharges(total_guest);
    }
  };


  const DaysBetween = () => {
    const now = new Date();
    const currentDate = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')} ${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`;
    if (bookingDetails?.checkInDate && bookingDetails?.checkOutDate) {
      const graceTime = bookingDetails.graceTime || 0;
      const { days } = calculateTimeDifference(
        bookingDetails.checkInDate,
        currentDate,
        graceTime,
      );
      return (
        <span className="value">
          {days}
        </span>
      );
    }
    return '';
  };


  const calculateCharges = (graceTime, checkInDateStr, checkOutDateStr) => {
    const checkInDate = parseDateString(checkInDateStr);
    const checkOutDate = parseDateString(checkOutDateStr);

    // Calculate the difference in milliseconds
    const timeDifference = checkOutDate - checkInDate;
    const graceTimeInMilliseconds = graceTime * 60 * 60 * 1000;
    const adjustedTimeDifference = timeDifference - graceTimeInMilliseconds;


    // Convert milliseconds to days
    const millisecondsInADay = 24 * 60 * 60 * 1000;
    const days = Math.ceil(adjustedTimeDifference / millisecondsInADay);

    const currentTariff = handleTariffFunction(days);


    // If days are less than 1, charge the base tariff (550 in this case)
    const totalCharges = days < 1 ? currentTariff : days * currentTariff;

    return { totalCharges };
  };

  useEffect(() => {
    const now = new Date();
    const currentDate = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')} ${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`;

    if (bookingDetails?.checkInDate) {
      const { totalCharges } = calculateCharges(
        bookingDetails.graceTime,
        bookingDetails.checkInDate,
        currentDate
      );
      setTotalCharges(totalCharges);
      setDiscountedCharges(totalCharges);
      setGraceTime(bookingDetails.graceTime);
    }
  }, [bookingDetails]);

  useEffect(() => {

    const bal = discountedCharges;
    const total_advance = Advance_total;
    const total_balance = bal - total_advance
    setBalance(Math.abs(Math.round(total_balance)));

    if (bal > total_advance) {

      setBalanceLable("Received")
      setDescription("Received Balance for ");
    }
    else {
      setBalanceLable("Refund")
      setDescription("Refund Amount for ")
    }

  });


  const handleInputDiscount = (e) => {
    const value = e.target.value;
    setError('');
    if (/^\d*\.?\d*$/.test(value)) {
      setDiscount(value);
      formik.setFieldValue('discount', value);
      // No need to call calculateWithDiscount() here
    }
  };

  // Function to calculate GST
  const calculateGSTAmount = (charges, taxPercentage) => {
    if (!taxPercentage) return 0;
    return (charges * taxPercentage) / 100;
  };

  // Function to calculate discounted charges
  const calculateDiscountedCharges = (baseCharges, discount) => {
    if (discount === '' || parseFloat(discount) === 0) {
      return baseCharges;
    }

    const discountPercentage = parseFloat(discount);
    if (discountPercentage >= 0 && discountPercentage <= 100) {
      const discountAmount = (baseCharges * discountPercentage) / 100;
      return baseCharges - discountAmount;
    } else {
      setError('Please enter a valid discount percentage between 0 and 100.');
      return baseCharges;
    }
  };

  // Effect to update GST and discounted charges when totalCharges or bookingDetails change
  useEffect(() => {
    if (bookingDetails?.taxPercentage) {
      const gstTotal = calculateGSTAmount(totalCharges, bookingDetails.taxPercentage);
      setGstCharges(gstTotal);
      const totalWithGst = totalCharges + gstTotal + guestTariff + guestCharges + oldRoomTotal + roomServiceCharge.TotalCash;
      setDiscountedCharges(calculateDiscountedCharges(totalWithGst, discount));
      formik.setFieldValue('grossTotal', calculateDiscountedCharges(totalWithGst, discount));
    }
  }, [totalCharges, guestCharges, bookingDetails?.taxPercentage, oldRoomTotal, roomServiceCharge.TotalCash]);

  // Effect to recalculate discounted charges when discount or gstCharges change
  useEffect(() => {
    const totalWithGst = totalCharges + gstCharges + guestTariff + guestCharges + oldRoomTotal + roomServiceCharge.TotalCash;
    setDiscountedCharges(calculateDiscountedCharges(totalWithGst, discount));
    formik.setFieldValue('grossTotal', calculateDiscountedCharges(totalWithGst, discount));
  }, [discount, gstCharges, guestCharges, oldRoomTotal, roomServiceCharge.TotalCash]);


  const formik = useFormik({
    initialValues: {
      checkoutMethod: '',
      taxNumber: '',
      billingName: '',
      billingAddress: '',
      discount: '',
      remarks: '',
      payment: '',
      checkoutBy: '',
      grossTotal: '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      payment: Yup.string().required('Payment Type is required'),
      checkoutBy: Yup.string().required('Checkout By is required'),
    }),
    onSubmit: (values) => {
      console.log(values.checkoutMethod),
      dispatch(createCheckOutData(values));
    }
  });

  const createCheckOutData = (values) => {
    const data = JSON.stringify({
      checkoutMethod: values.checkoutMethod,
      taxNumber: values.taxNumber,
      billingName: values.billingName,
      billingAddress: values.billingAddress,
      discount: values.discount,
      remarks: values.remarks,
      paymentId: values.payment,
      checkoutBy: values.checkoutBy,
      netTotal: values.grossTotal,
      advanceTotal: total,
      balanceTotal: balance,
      netTotal: values.grossTotal,
      guestId: bookingDetails.GuestId,
      mobileNo: bookingDetails.mobileNo,
      PaymentTypeId: values.payment,
      hotelId: hid,
      extraAdult: guestTariff,
      extraGuest: guestCharges,
      paymentMethod: balanceLable,
      description: description + bookingDetails.roomNo
    });
    checkOutGuest(hid, dbName, data, bookingId).then((res) => {
      if (res.data.success) {
        setResponse(messages.success.CheckOutSuccess())
        setAlertModal(true)
      }
    }).catch(err => {
      if (err.response.status === 404) {
        AlreadyExists
        setResponse(messages.failed.CheckOutDataExists())
      } else {
        setResponse(messages.failed.CheckOutFailed())
      }
      setErrorAlertModal(true)
    });
  }

  const clearFields = () => {
    formik.resetForm();
    setDiscount(0);
    setselectedPayment(null);
    setselectedroomService(null);
    setSelectedtimeFormat(null);
    navigate(-1);
  };

  const total = advanceDetails?.reduce(
    (sum, detail) => sum + parseFloat(detail.AdvanceAmount || 0),
    0
  );

  const Advance_total = parseFloat(oldRoomAdvance) + total;


  const extractMinTariff = (tariffRange) => {
    if (!tariffRange) return 0;
    const rangeParts = tariffRange.split('-').map(part => parseInt(part.replace(/[^0-9]/g, ""), 10)); // Extract numbers from the string
    return rangeParts[0]; // Return the first value, which is the minimum
  };

  // Tariff calculation function
  const handleTariffFunction = (days) => {
    let totalGuests = 0;

    totalGuests = (bookingDetails?.male || 0)
      + (bookingDetails?.female || 0)
      + (Array.isArray(bookingDetails?.child_ages)
        ? bookingDetails?.child_ages.filter(age => age >= 14).length
        : 0);

    // Extract minimum tariff from the range values
    const singleTariff = extractMinTariff(bookingDetails?.singleTariff);
    const normalTariff = extractMinTariff(bookingDetails?.normalTariff);
    const extraPersonTariff = extractMinTariff(bookingDetails?.extraPersonTariff);

    let calculatedTariff = 0;
    if (totalGuests === 1) {
      calculatedTariff = singleTariff;
    } else if (totalGuests === 2) {
      calculatedTariff = normalTariff;
    } else if (totalGuests > 2) {
      const extra_pax = totalGuests - 2;
      calculatedTariff = normalTariff;
      const guestTariff = extraPersonTariff * days * extra_pax;
      setGuestTariff(guestTariff);
    }

    // Update the tariff state
    setTariff(calculatedTariff);
    setPax(totalGuests);
    // Return the calculated tariff
    return calculatedTariff;
  };


  return (
    <React.Fragment>
      <AlertModal
        show={alertModal}
        onOkClick={() => {
          getAll(),
            clearFields(),
            setAlertModal(false);
        }}
        onCloseClick={() => setAlertModal(false)}
        res={response}
      />
      <ErrorAlertModal
        show={errorAlertModal}
        onOkClick={() => { getAll(), clearFields(), setErrorAlertModal(false) }}
        onCloseClick={() => setErrorAlertModal(false)}
        res={response}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Check Out" />
          <Form onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
          >

            <Card>
              <CardBody lg={6}>
                <div className="mb-3">
                  <CardTitle>Checkout Method</CardTitle>
                </div>
                <Row>
                  <Col xs={6}>
                    <div className="mb-3" style={{ position: 'relative' }}>
                      <Select
                        id="checkoutMethod"
                        name="checkoutMethod"
                        options={timeFormatMethod.map(type => ({ value: type.value, label: type.label }))}
                        placeholder="Select a Room Type"
                        value={timeFormatMethod.find(option => option.value === selectedtimeFormat)}
                        onChange={handleSelectChange}
                        onBlur={formik.handleBlur}
                        classNamePrefix="custom-select"
                        styles={{
                          menu: provided => ({ ...provided, top: '100%', position: 'absolute', width: '100%', zIndex: 999 }),
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            {/* Checkout Details */}
            <Card className="checkout-card">
              <CardBody>
                <div className="mb-3">
                  <CardTitle className='checkout-page'>Checkout Details</CardTitle>
                </div>
                <Row>
                  <Col lg={3}>
                    <Label className="checkout-label">Booking No:</Label>
                  </Col>
                  <Col lg={3}>
                    <span className="value"><strong className='text-muted'>{bookingDetails?.bookingId}</strong></span>
                  </Col>
                  <Col lg={3}>
                    <Label className="checkout-label">Room:</Label>
                  </Col>
                  <Col lg={3}>
                    <span className="value">{bookingDetails?.roomNo} <strong className='text-muted'>({bookingDetails?.roomTitle})</strong></span>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Label className="checkout-label">Checkin Date / Time:</Label>
                  </Col>
                  <Col lg={3}>
                    <span className="value">{bookingDetails?.checkInDate ? bookingDetails?.checkInDate.slice(0, 16) : ''}</span>
                  </Col>
                  <Col lg={3}>
                    <Label className="checkout-label">Tariff:</Label>
                  </Col>
                  <Col lg={3}>
                    <span className="value">
                      {(bookingDetails?.male + bookingDetails?.female) === 1
                        ? extractMinTariff(bookingDetails?.singleTariff)
                        : extractMinTariff(bookingDetails?.normalTariff)}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Label className="checkout-label">Checkout Date / Time:</Label>
                  </Col>
                  <Col lg={3}>
                    <span className="value">
                      {`${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-${String(new Date().getDate()).padStart(2, '0')} ${String(new Date().getHours()).padStart(2, '0')}:${String(new Date().getMinutes()).padStart(2, '0')}`}
                    </span>
                  </Col>
                  <Col lg={3}>
                    <Label className="checkout-label">Total Person:</Label>
                  </Col>
                  <Col lg={3}>
                    <span className="value">{pax}</span>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Label className="checkout-label">Total Days:</Label>
                  </Col>
                  <Col lg={3}>
                    {DaysBetween()}
                  </Col>
                  <Col lg={3}>
                    <Label className="checkout-label">Guest Tariff:</Label>
                  </Col>
                  <Col lg={3}>
                    <span className="value">{extractMinTariff(bookingDetails?.extraPersonTariff)}</span>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Label className="checkout-label">Remarks:</Label>
                  </Col>
                  <Col lg={3}>
                    <span className="value">{bookingDetails?.remarks === "null" ? '-' : bookingDetails?.remarks}</span>

                  </Col>
                  <Col lg={3}>
                    <Label className="checkout-label">Reference By:</Label>
                  </Col>
                  <Col lg={3}>
                    <span className="value">{bookingDetails?.referedBy || '-'}</span>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className="guest-details-card">
              <CardBody>
                <div className="mb-3">
                  <CardTitle>Guest Details</CardTitle>
                </div>
                <Row>
                  <Col lg={3}>
                    <Label className="checkout-label">Customer Name	</Label>
                  </Col>
                  <Col lg={3}>
                    <span className="value"><strong className='text-muted'>{bookingDetails?.GuestName}</strong></span>
                  </Col>
                  <Col lg={3}>
                    <Label className="checkout-label">Contact No</Label>
                  </Col>
                  <Col lg={3}>
                    <span className="value">{bookingDetails?.mobileNo}</span>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Label className="checkout-label">GST Number</Label>
                  </Col>
                  <Col lg={3}>
                    <Input
                      name="taxNumber"
                      type="text"
                      placeholder="GST Number"
                      style={{ width: '75%' }}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.taxNumber}
                    />
                  </Col>
                  <Col lg={3}>
                    <Label className="checkout-label">Address</Label>
                  </Col>
                  <Col lg={3}>
                    <span className="value">{bookingDetails?.address}</span>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Label className="checkout-label">Billing Name</Label>
                  </Col>
                  <Col lg={3}>
                    <Input
                      name="billingName"
                      type="text"
                      placeholder="Billing Name"
                      style={{ width: '75%' }}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.billingName}
                    />
                  </Col>
                  <Col lg={3}>
                    <Label className="checkout-label">Billing Address</Label>
                  </Col>
                  <Col lg={3}>
                    <Input
                      name="billingAddress"
                      type="text"
                      placeholder="Billing Address"
                      style={{ width: '75%' }}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.billingAddress}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className="service-details-card">
              <CardBody>
                <div className="mb-3">
                  <CardTitle>Services Details</CardTitle>
                </div>
                <Row>
                  <Col lg={9}>
                    <Label className="checkout-label">Room Charges (Tariff)</Label>
                  </Col>
                  <Col lg={2}>
                    <span className="value">{totalCharges.toFixed(2)}</span>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Label className="checkout-label">Room No:</Label>
                  </Col>
                  <Col lg={3}>
                    <span className="value">{bookingDetails?.roomNo} ({bookingDetails?.roomTitle})</span>
                    <br></br>
                    <span className="value">
                      {bookingDetails?.checkInDate ? bookingDetails?.checkInDate.slice(0, 16) : ''} {"  "}
                      -
                      {"  "}  {`${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-${String(new Date().getDate()).padStart(2, '0')} ${String(new Date().getHours()).padStart(2, '0')}:${String(new Date().getMinutes()).padStart(2, '0')}`} = {DaysBetween()}
                    </span>
                    <span className="value">Tariff {bookingDetails?.hotelCurrency.match(/\(([^)]+)\)/)[1]} {(bookingDetails?.male + bookingDetails?.female) === 1
                      ? extractMinTariff(bookingDetails?.singleTariff)
                      : extractMinTariff(bookingDetails?.normalTariff)}/- Per Day</span>
                  </Col>

                </Row>
                <Row style={pax > 2 ? {} : { opacity: 0.0, pointerEvents: 'none' }}>
                  <Col lg={9}>
                    <span className="value">Extra Adult:  Tariff   {bookingDetails?.hotelCurrency.match(/\(([^)]+)\)/)[1]} {extractMinTariff(bookingDetails?.extraPersonTariff)}/- Per Day</span>
                  </Col>
                  <Col lg={3}>
                    <span className="value">{guestTariff.toFixed(2)}</span>
                  </Col>
                </Row>

                <Row style={ExtraGuestDetails.length > 0 ? {} : { opacity: 0.0, pointerEvents: 'none' }}>
                  <Col lg={3}>
                    <span className="checkout-label">Extra Person Charges (Tariff) </span>
                  </Col>

                  <Col lg={6}>
                    {ExtraGuestDetails.length > 0 && ExtraGuestDetails.map((detail, index) => {
                      // Calculate days for each detail
                      const todate = `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-${String(new Date().getDate()).padStart(2, '0')} ${String(new Date().getHours()).padStart(2, '0')}:${String(new Date().getMinutes()).padStart(2, '0')}`
                      const { days } = Extra_Guest_calculateTimeDifference(detail.checkInDate, todate, graceTime);

                      return (
                        <div key={index}>
                          <span> {detail?.checkInDate ? detail?.checkInDate.slice(0, 16) : ''} {"  "} - {todate} = {days} </span>
                          <br />
                          <span className="value">
                            Extra Adult Tariff {bookingDetails?.hotelCurrency.match(/\(([^)]+)\)/)[1]} {extractMinTariff(bookingDetails?.extraPersonTariff)}/- Per Day
                          </span>
                        </div>
                      );
                    })}
                  </Col>
                  <Col lg={3}>
                    <span className="value">{guestCharges.toFixed(2)}</span>
                  </Col>
                </Row>
                <Row >
                  <Col lg={9}>
                    <span className="checkout-label">Room Services Charges  </span>
                  </Col>
                  <Col lg={2}>
                    <span className="value">
                      {roomServiceCharge.TotalCash !== undefined && roomServiceCharge.TotalCash !== null
                        ? roomServiceCharge.TotalCash.toFixed(2)
                        : "0.00"}
                    </span>
                  </Col>
                </Row>
                <Row style={bookingDetails?.isChangedRoom == 1 ? {} : { opacity: 0.0, pointerEvents: 'none' }}>
                  <Col lg={9}>
                    <span className="checkout-label">Shift Room Charges (Tariff) </span>
                  </Col>
                  <Col lg={2}>
                    <span className="value">{oldRoomTotal.toFixed(2)}</span>
                  </Col>
                </Row>

                <Row style={bookingDetails?.isChangedRoom == 1 ? {} : { opacity: 0.0, pointerEvents: 'none' }}>
                  <Col lg={3}>
                    <Label className="value">
                      Room No.: {ShiftDetails?.Room_No ? ShiftDetails.Room_No : 0}
                    </Label>
                    <span className="value">
                      From: {ShiftDetails?.FromDate ? ShiftDetails?.FromDate.slice(0, 16) : ''}  {''}
                      To: {bookingDetails?.checkInDate ? bookingDetails?.checkInDate.slice(0, 16) : ''}
                    </span>
                    <span className="value"> Advance Paid: {ShiftDetails?.Advance ? ShiftDetails.Advance : 0.00}</span>
                  </Col>
                </Row>

                <Row>
                  <Col lg={9}>
                    <Label className="checkout-label">Discount Amount (please enter "Numbers" to take effect of Discount value)</Label>
                  </Col>
                  <Col lg={3}>
                    <Input
                      name="discount"
                      type="text"
                      placeholder="Discount (%)"
                      value={discount}
                      onChange={handleInputDiscount}
                      onBlur={formik.handleBlur}
                      style={{ width: '25%', borderColor: error ? 'red' : '' }}
                      invalid={!!error}
                    />
                    {error && <FormFeedback style={{ color: 'red' }}>{error}</FormFeedback>}
                  </Col>
                </Row>
                <Row>
                  <Col lg={9}>
                    <Label className="form-lable">GST @{bookingDetails?.taxPercentage ? bookingDetails?.taxPercentage : ''}% (IGST: {bookingDetails?.hotelCurrency.match(/\(([^)]+)\)/)[1]}: {(bookingDetails?.male + bookingDetails?.female) === 1
                      ? extractMinTariff(bookingDetails?.singleTariff)
                      : extractMinTariff(bookingDetails?.normalTariff)})</Label>
                  </Col>
                  <Col lg={3}>
                    <span className="value">{gstCharges.toFixed(2)}</span>
                  </Col>
                </Row>
                <Row>
                  <Col lg={9}>
                    <Label className="form-lable">Gross Amount</Label>
                  </Col>
                  <Col lg={3}>
                    <span className="value">{discountedCharges.toFixed(2)}</span>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className="advance-details">
              <CardBody>
                <div className="card-title mb-3">
                  <CardTitle>Advance Details</CardTitle>
                </div>
                <Row>
                  <Col lg={12}>
                    <div className="table-responsive">
                      <Table className="table-striped table-bordered mb-0">
                        <thead className="text-center">
                          <tr>
                            <th>S.No</th>
                            <th>DateTime</th>
                            <th>Type</th>
                            <th>Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {advanceDetails && advanceDetails.length > 0 ? (
                            <>
                              {advanceDetails.map((detail, index) => (
                                <tr key={index}>
                                  <td className="text-center">{index + 1}</td>
                                  <td className="text-center">{detail.ArrivalDateTime}</td>
                                  <td className="text-center">{detail.PaymentType}</td>
                                  <td className="text-center">{detail.AdvanceAmount}</td>
                                </tr>
                              ))}
                              <tr>
                                <td className="text-center" colSpan="3">
                                  <strong>Total</strong>
                                </td>
                                <td className="text-center">
                                  <strong>
                                    {total.toFixed(2)}
                                  </strong>
                                </td>
                              </tr>
                            </>
                          ) : (
                            <tr>
                              <td className="text-center" colSpan="4">
                                No Data
                              </td>
                            </tr>
                          )}
                        </tbody>

                      </Table>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className="total-details">
              <CardBody>
                <Row>
                  <Col lg={9}>
                    <Label className="checkout-label">Round Off:</Label>
                  </Col>
                  <Col lg={3}>
                    <span className="value"> 0.00</span>
                  </Col>
                </Row>
                <Row>
                  <Col lg={9}>
                    <Label className="checkout-label">Net Amount:</Label>
                  </Col>
                  <Col lg={3}>
                    <span className="value">{discountedCharges.toFixed(2)}</span>
                  </Col>
                </Row>
                <Row>
                  <Col lg={9}>
                    <Label className="checkout-label">Advance Amount:</Label>
                  </Col>
                  <Col lg={3}>
                    <span className="value">{Advance_total.toFixed(2)}</span>
                  </Col>
                </Row>
                <Row>
                  <Col lg={9}>
                    <Label className="checkout-label">Balance Amount({balanceLable}):</Label>
                  </Col>
                  <Col lg={3}>
                    <span className="value">{balance.toFixed(2)}</span>
                  </Col>
                </Row>
                <Row>
                  <Col lg={9}>
                    <Label className="checkout-label">Payment Type</Label>
                  </Col>
                  <Col lg={3}>
                    <Select
                      id="payment"
                      name="payment"
                      placeholder="Select Payment Type"
                      value={selectedPayment}
                      onChange={handlePaymentTypeChange}
                      options={paymentTypeList.map((payment) => ({
                        label: payment.paymentType,
                        value: payment.id
                      }))}
                      isClearable
                      isSearchable
                      classNamePrefix="react-select"
                      className={`react-select-containers ${formik.touched.payment && formik.errors.payment ? "is-invalid" : ""}`}
                      styles={{
                        menu: (provided) => ({
                          ...provided,
                          top: "100%",
                          position: "absolute",
                          width: "100%",
                          zIndex: 999,
                        }),
                      }}
                    />
                    {formik.touched.payment && formik.errors.payment ? (
                      <FormFeedback type="invalid">
                        {formik.errors.payment}
                      </FormFeedback>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col lg={9}>
                    <Label className="checkout-label">Checkout By</Label>
                  </Col>
                  <Col lg={3}>
                    <Select
                      id="checkoutBy"
                      name="checkoutBy"
                      placeholder="Select Service member"
                      value={selectedRoomService}
                      onChange={handleRoomSerivce}
                      options={roomService.map((service) => ({
                        label: service.name,
                        value: service.id
                      }))}
                      isClearable
                      isSearchable
                      classNamePrefix="react-select"
                      className={`react-select-containers ${formik.touched.checkoutBy && formik.errors.checkoutBy ? "is-invalid" : ""}`}
                      styles={{
                        menu: (provided) => ({
                          ...provided,
                          top: "100%",
                          position: "absolute",
                          width: "100%",
                          zIndex: 999,
                        }),
                      }}
                    />
                    {formik.touched.checkoutBy && formik.errors.checkoutBy ? (
                      <FormFeedback type="invalid">
                        {formik.errors.checkoutBy}
                      </FormFeedback>
                    ) : null}

                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col lg={9}>
                    <Label className="checkout-label">Remark</Label>
                  </Col>
                  <Col lg={3}>
                    <Input
                      name="remarks"
                      type="text"
                      placeholder="Remarks"
                      value={formik.values.remarks || "-"}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div className="text-end">
                      <button type="submit" className="btn btn-primary w-md me-3">
                        Check out
                      </button>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Create_Group_CheckOut;