import React from 'react';
import { Badge } from 'reactstrap';
import { Link } from 'react-router-dom';

const Type = (cell) => {
    return cell.value ? cell.value : "";
};

const Content_title = (cell) => {
    return cell.value ? cell.value : "";
};

const Position_top = (cell) => {
    return cell.value ? cell.value : "";
};

const Position_right = (cell) => {
    return cell.value ? cell.value : "";
};

const Font_size = (cell) => {
    return cell.value ? cell.value : "";
};

const Alignment_horizontal = (cell) => {
    return cell.value ? cell.value : "";
};



const Alignment_vertical = (cell) => {
    return cell.value ? cell.value : "";
};

const Font_style = (cell) => {
    return cell.value ? cell.value : "";
};

const Font_family = (cell) => {
    return cell.value ? cell.value : "";
};

const Font_color = (cell) => {
    return cell.value ? cell.value : "";
};
const Sign_upload = (cell) => {
    return cell.value ? cell.value : "";
};



export { Type, Content_title, Position_top, Position_right, Font_size, Alignment_horizontal, Alignment_vertical, Font_style, Font_family, Font_color,Sign_upload };