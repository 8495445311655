const RoomNumber = (cell) => {
    return cell.value ? cell.value : '';
};
const RoomType = (cell) => {
    return cell.value ? cell.value : '';
};
const FloorNumber = (cell) => {
    return cell.value ? cell.value : '';
};
const Wing = (cell) => {
    return cell.value ? cell.value : '';
};
const Wifi = (cell) => {
    return cell.value ? cell.value : '';
};

const RoomStatusname = (cell) => {
    return cell.value ? cell.value : '';
};
const ColorCode = (cell) => {
    return cell.value ? cell.value : '';
};

export {
    RoomNumber,
    RoomType,
    FloorNumber,
    Wing,
    Wifi,
    RoomStatusname,
    ColorCode
};